import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bankAccountType',
})
export class BankAccountTypePipe implements PipeTransform {
    transform(value: string) {
        if (!value || value === undefined || value === null) return value;

        return 'bankAccountTypes.' + value?.toLowerCase();
    }
}
