import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Address } from '../../models/address.model';
import { ButtonPosition, ButtonType } from '../../models/button-type.type';
import { Configuration } from '../../models/configuration.model';
import { Restrictions } from '../../models/contract-type-restrictions.model';
import { GENDERS } from '../../models/gender.model';
import { Person } from '../../models/person.model';
import { Permission } from '../../models/roles.model';
import { ProductTitle } from '../../permissions/models/product-titles';
import { checkSetValidBirthdateByNationalIdentificationNumber, getDateUtc, isValidJMBG } from '../../utils/date';

@Component({
    selector: 'shared-person-editor',
    templateUrl: './person-editor.component.html',
})
export class PersonEditorComponent implements OnInit {
    @Input() showAddress = true;
    @Input() showPassport: boolean;
    @Input() showContacts = true;
    @Input() isBirthDateRequired = true;
    @Input() person: Person = { address: {} as Address };
    @Input() isAdding = false;
    @Input() isEditing = false;
    @Input() restrictPartner: string;
    @Input() restrictPermission: Permission;
    @Input() restrictProduct: ProductTitle;
    @Input({ required: true }) restrictions: Restrictions;
    @Input() showButtons = true;
    @Input() isAdable = true;
    @Input() buttonPosition: ButtonPosition = 'BOTTOM';
    @Input() buttonType: ButtonType = 'TEXT';
    @Input({ required: true }) partnerId: string;
    @Output() personChanged = new EventEmitter<Person>();
    personEditCopy: Person | undefined;
    salutations = GENDERS;

    configuration = inject(Configuration);

    currentDate = new Date();
    JMBGIsValid = true;

    ngOnInit(): void {
        this.isEditing = this.isAdding;

        this.person = {
            ...this.person,
            address:
                this.person.address && this.person.address != null
                    ? this.person.address
                    : { addressLine1: '', city: '', country: '', postalCode: '', addressLine2: '' },
            dateOfBirth: this.person.dateOfBirth ? new Date(this.person.dateOfBirth) : undefined,
        };
    }

    saveEdit() {
        if (this.#checkBirthDateValidation() && this.person.firstName && this.person.lastName && this.JMBGIsValid) {
            this.isEditing = false;
            this.personEditCopy = undefined;

            if (this.person.dateOfBirth) {
                this.person.dateOfBirth = getDateUtc(this.person.dateOfBirth);
            }

            this.personChanged.emit(this.person);
        }
    }

    checkSetValidBirthdate(nationalIdentificationNumber: string) {
        if (this.configuration.partnerIds.a1_srb?.toLowerCase() === this.partnerId.toLowerCase()) {
            if (isValidJMBG(nationalIdentificationNumber)) {
                this.JMBGIsValid = true;
                this.person.nationalIdentificationNumber = nationalIdentificationNumber;
                const calculatedBirthdate =
                    checkSetValidBirthdateByNationalIdentificationNumber(nationalIdentificationNumber);
                if (calculatedBirthdate !== null) {
                    this.person.dateOfBirth = calculatedBirthdate;
                }
            } else {
                this.JMBGIsValid = false;
            }
        } else {
            this.JMBGIsValid = true;
            this.person.nationalIdentificationNumber = nationalIdentificationNumber;
        }
    }

    #checkBirthDateValidation(): boolean {
        return this.isBirthDateRequired ? this.person.dateOfBirth != null : true;
    }

    cancelEdit() {
        this.isEditing = false;
        this.person = this.personEditCopy ?? {};
        this.personEditCopy = undefined;
    }

    edit() {
        this.isEditing = true;
        this.personEditCopy = { ...this.person };
    }

    resetView() {
        this.person = { address: {} as Address };
        this.isEditing = true;
    }

    setEditing() {
        this.isEditing = true;
    }
}
