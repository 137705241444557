<p-button
    label="Approve"
    (click)="toggleButton('APPROVE')"
    [ngClass]="approveSelected ? 'btn-approve-selected' : ''"
    styleClass="p-button-sm p-button-text p-button-success"></p-button>
<p-button
    label="Deny"
    (click)="toggleButton('DENY')"
    [ngClass]="denySelected ? 'btn-deny-selected' : ''"
    styleClass="p-button-sm p-button-text p-button-danger"></p-button>
