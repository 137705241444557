<button
    pRipple
    pButton
    type="button"
    *ngIf="isEditing"
    [disabled]="isEditButtonDisabled"
    icon="pi pi-check"
    class="p-button-text p-button-success text-xl"
    pTooltip="{{ 'save' | tooltip | translate }}"
    tooltipPosition="left"
    (click)="saveEdit()"></button>
<button
    pRipple
    pButton
    type="button"
    icon="pi pi-times"
    *ngIf="isEditing"
    class="p-button-text p-button-danger text-xl"
    pTooltip="{{ 'cancel' | tooltip | translate }}"
    tooltipPosition="right"
    (click)="cancelEdit()"></button>
<button
    pRipple
    pButton
    type="button"
    icon="pi pi-pencil"
    *ngIf="!isEditing"
    class="p-button-text text-xl"
    pTooltip="{{ 'edit' | tooltip | translate }}"
    tooltipPosition="right"
    (click)="edit()"></button>
