import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
    transform(value: string): string {
        if (value != '' && value != undefined) return `countryNames.${value}`;
        else return '';
    }
}
