import { Injectable } from '@angular/core';
import { Observable, catchError, shareReplay, tap } from 'rxjs';
import { Configuration } from '../models/configuration.model';
import { Note } from '../models/note.model';
import { State } from '../models/state.model';
import { HttpClientAdapter } from './http-client-adapter.service';

interface NoteState {
    notes: Note[];
}

@Injectable()
export class NoteService extends State<NoteState> {
    notes$: Observable<Note[]> = this.select((state) => state.notes).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    url: string;

    constructor(
        private http: HttpClientAdapter,
        config: Configuration,
    ) {
        super({
            notes: [],
        });

        this.url = `${config.baseUrl}Notes/`;
    }

    getNotesByRefId(refId: string, refName: string): Observable<Note[]> {
        return this.http
            .get<Note[]>(this.url + 'GetAllByRefId', {
                params: {
                    refId: refId,
                    refName: refName,
                },
            })
            .pipe(
                tap((notes) => {
                    this.sortNotes(notes);
                }),
            );
    }

    private sortNotes(notes: Note[]) {
        this.setState({
            notes: this.sortNotesByDate(notes),
        });
    }

    addNote(note: Note): Observable<string> {
        return this.http.post<string>(this.url + 'Create', note).pipe(
            tap(() => {
                this.setState({
                    notes: this.sortNotesByDate([...this.state.notes, note]),
                });
            }),
            catchError((error) => {
                throw error;
            }),
        );
    }

    editNote(newNote: Note): Observable<boolean> {
        return this.http.put<boolean>(this.url + 'Update', newNote);
    }

    deleteNote(noteId: string): Observable<void> {
        return this.http.delete<void>(this.url + 'Delete', {
            params: {
                noteId: noteId,
            },
        });
    }

    private sortNotesByDate(notes: Note[]) {
        return notes.sort(this.sortDate_DESC);
    }

    private sortDate_DESC(a: Note, b: Note): number {
        if (b.timestamp != null && a.timestamp != null) {
            return new Date(b?.timestamp)?.getTime() - new Date(a?.timestamp)?.getTime();
        } else {
            return 0;
        }
    }
}
