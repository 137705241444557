import { Injectable } from '@angular/core';
import { Guid, State } from '@luis/common/shared';
import { Observable, shareReplay } from 'rxjs';
import { BreadcrumbItem } from './models/breadcrumb-item.model';
import { NavbarBreadcrumbState } from './models/breadcrumb-state.model';

const initialState: NavbarBreadcrumbState = {
    breadcrumbs: [],
};

@Injectable({
    providedIn: 'root',
})
export class NavbarBreadcrumbService extends State<NavbarBreadcrumbState> {
    breadcrumbs$: Observable<BreadcrumbItem[]> = this.select((state) => state.breadcrumbs).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor() {
        super(initialState);
    }

    // todo: refactor such that it is understandable
    setBreadcrumbs(breadcrumb: BreadcrumbItem) {
        if (Guid.isValid(breadcrumb.name)) {
            breadcrumb = {
                ...breadcrumb,
                name: 'details',
                componentName: 'details',
            };
        }

        breadcrumb = this.cleanupBreadCrumb(breadcrumb);
        let breadcrumbs = this.getBreadcrumbs();
        if (breadcrumbs.length > 0) {
            const existingBreadcrumb = breadcrumbs.find((x) => x.hierarchy == breadcrumb.hierarchy);
            if (existingBreadcrumb) {
                const iterationArr = this.getBreadcrumbs();
                const newBreadCrumbs: BreadcrumbItem[] = [];
                for (const item of iterationArr) {
                    if (item.hierarchy < breadcrumb.hierarchy) {
                        newBreadCrumbs.push(item);
                    } else if (item.hierarchy == breadcrumb.hierarchy) {
                        newBreadCrumbs.push(breadcrumb);
                    }
                }
                breadcrumbs = newBreadCrumbs;
            } else {
                breadcrumb.position = breadcrumbs.length + 1;
                breadcrumb.hierarchy = breadcrumbs.length + 1;
                breadcrumbs.push(breadcrumb);
            }
        } else {
            breadcrumb.position = 1;
            breadcrumb.hierarchy = 1;
            breadcrumbs = [breadcrumb];
        }

        this.setState({ breadcrumbs: breadcrumbs });
    }

    private cleanupBreadCrumb(breadcrumb: BreadcrumbItem): BreadcrumbItem {
        return {
            ...breadcrumb,
            displayName: breadcrumb.displayName.split('#')[0],
        };
    }

    getBreadcrumbs(): BreadcrumbItem[] {
        return this.state.breadcrumbs;
    }

    getBreadcrumbByPosition(position: number) {
        return this.getBreadcrumbs().find((q) => q.position == position);
    }

    resetBreadcrumbs() {
        this.setState({ breadcrumbs: [] });
    }
}
