import { Component, computed, inject, input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function showErrors(control: AbstractControl): boolean {
    return control.invalid && control.touched;
}

@Component({
    selector: 'nuis-form-error',
    standalone: true,
    imports: [],
    template: `
        <div class="flex gap-2 text-sm text-red-500">
            @for (error of errorMessages(); track error) {
                <div>{{ error }}</div>
            }
        </div>
    `,
})
export class FormErrorComponent {
    private readonly translate = inject(TranslateService);

    public label = input.required<string | null>();
    public control = input.required<AbstractControl>();

    protected errorMessages = computed<string[]>(() => {
        const label = this.label() ?? '';
        const control = this.control();

        return Object.entries(control.errors ?? {}).map(([key, value]) => {
            switch (key) {
                case 'required':
                    return this.translate.instant('validation.required', { label: label });
                case 'minlength':
                    return this.translate.instant('validation.minLength', { ...value, label: label });
                case 'maxLength':
                    return this.translate.instant('validation.minLength', { ...value, label: label });
                default:
                    return this.translate.instant('validation.unknown');
            }
        });
    });
}
