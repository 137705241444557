export enum CancellationReason {
    BaseContractCanceled = 'BaseContractCanceled',
    ContractTakeOver = 'ContractTakeOver',
    NoNeed = 'NoNeed',
    PremiumTooHigh = 'PremiumTooHigh',
    WrongShopInformation = 'WrongShopInformation',
    Complaint = 'Complaint',
    DeniedClaim = 'DeniedClaim',
    TooManyClaims = 'TooManyClaims',
}

export type CancellationCriteria = 'PolicyNumber';
