import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { dependOnAssets } from '@luis/assets';
import { AuthService, HttpLoaderFactory, RolesService, SharedModule, StateInfo } from '@luis/common/shared';
import { NavigationModule } from '@luis/luis/navigation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';

@NgModule({
    declarations: [AppComponent, PageNotFoundComponent, DashboardComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule.forRoot(environment),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
            extend: true,
        }),
        NavigationModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true,
        }),
    ],
    providers: [],
})
export class AppModule implements DoBootstrap {
    constructor(
        private readonly auth: AuthService,
        private readonly rolesService: RolesService,
    ) {
        dependOnAssets();
    }

    public ngDoBootstrap(appRef: ApplicationRef): void {
        appRef.bootstrap(AppComponent);

        this.authenticate();
    }

    private authenticate() {
        const state: StateInfo = {
            debug: {
                showToken: true,
                showUserInfo: true,
                showClient: true,
            },
        };
        this.auth.init(state);
        this.rolesService.init();
    }
}
