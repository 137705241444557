import { Route } from '@angular/router';

export const contractDetailsRoute: Route = {
    loadComponent: () => import('./contract-details.component'),
    children: [
        // TODO: Redirect to overview if we have that ported
        { path: '', redirectTo: 'transactions', pathMatch: 'full' },
        {
            path: 'claims',
            loadComponent: () => import('./claims-tab/claims-tab.component'),
        },
        {
            path: 'notifications',
            loadComponent: () => import('./notifications-tab/notifications-tab.component'),
        },
        {
            path: 'transactions',
            loadComponent: () => import('./transactions-tab/transactions-tab.component'),
        },
    ],
};
