<div class="h-full overflow-auto">
    <div class="m-8 flex flex-row justify-content-around">
        <span class="font-medium text-3xl text-900">
            {{ 'welcomeBack' | translate }}, <span class="font-bold">{{ userName }}</span
            >!
        </span>
    </div>

    <div class="grid p-0 m-0">
        <div class="col-3"></div>
        <div class="col-6">
            <div class="surface-card shadow-1 p-3 border-1 border-50 border-round">
                <div class="flex justify-content-between mb-3">
                    <div class="text-900">
                        <span class="block font-medium mb-3">
                            {{ 'activeContracts' | translate }}
                        </span>
                        <span class="text-900 font-medium text-2xl">{{ activeContracts }}</span>
                        <span class="ml-2 font-medium" *ngIf="lastActiveContracts">
                            <span class="text-green-700" *ngIf="lastActiveContracts > 0"
                                >+{{ lastActiveContracts }}</span
                            >
                            <span class="text-red-700" *ngIf="lastActiveContracts < 0">{{ lastActiveContracts }}</span>
                        </span>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center bg-green-100 border-round"
                        style="width: 2.5rem; height: 2.5rem">
                        <i class="pi pi-table text-green-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3"></div>

        <div class="col-3"></div>
        <div class="col-6">
            <div class="surface-card shadow-1 p-3 border-1 border-50 border-round">
                <div class="flex justify-content-between mb-3">
                    <div class="text-900">
                        <span class="block font-medium mb-3">
                            {{ 'openClaims' | translate }}
                        </span>
                        <span class="text-900 font-medium text-2xl">{{ openClaims }}</span>
                        <span class="ml-2 text-green-700 font-medium" *ngIf="lastOpenClaims">
                            <span class="text-red-700" *ngIf="lastOpenClaims > 0">+{{ lastOpenClaims }}</span>
                            <span class="text-green-700" *ngIf="lastOpenClaims < 0">{{ lastOpenClaims }}</span>
                        </span>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center bg-purple-100 border-round"
                        style="width: 2.5rem; height: 2.5rem">
                        <i class="pi pi-shield text-purple-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3"></div>

        <div class="col-3"></div>
        <div class="col-6">
            <div class="surface-card shadow-1 p-3 border-1 border-50 border-round">
                <div class="flex justify-content-between mb-3">
                    <div class="text-900">
                        <span class="block font-medium mb-3">
                            {{ 'policyholders' | translate }}
                        </span>
                        <span class="text-900 font-medium text-2xl">{{ policyHolders }}</span>
                        <span class="ml-2 font-medium" *ngIf="lastPolicyHolders">
                            <span class="text-green-700" *ngIf="lastPolicyHolders > 0">+{{ lastPolicyHolders }}</span>
                            <span class="text-red-700" *ngIf="lastPolicyHolders < 0">{{ lastPolicyHolders }}</span>
                        </span>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center bg-bluegray-100 border-round"
                        style="width: 2.5rem; height: 2.5rem">
                        <i class="pi pi-user text-bluegray-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3"></div>
    </div>
</div>
