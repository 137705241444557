import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmationService, FilterMatchMode, MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { PRIMENG_MODULES } from './modules';

@NgModule({
    imports: [...PRIMENG_MODULES, CommonModule],
    exports: [...PRIMENG_MODULES],
    providers: [MessageService, ConfirmationService, DialogService],
})
export class PrimengModule {
    constructor(private config: PrimeNGConfig) {
        this.setFilterOptions();
    }

    private setFilterOptions() {
        this.config.filterMatchModeOptions = {
            text: [
                FilterMatchMode.STARTS_WITH,
                FilterMatchMode.CONTAINS,
                FilterMatchMode.ENDS_WITH,
                FilterMatchMode.EQUALS,
            ],
            numeric: [
                FilterMatchMode.EQUALS,
                FilterMatchMode.LESS_THAN,
                FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                FilterMatchMode.GREATER_THAN,
                FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
            ],
            date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER],
        };
    }
}
