import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from '@luis/common/primeng';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { NgxIbanModule } from 'ngx-iban';
import { AuthorizationHttpInterceptor } from './authentication/authorization-http-interceptor';
import { KeycloakInitializer } from './authentication/keycloak-initializer';
import { AddressComponent } from './components/address/address.component';
import { AdultSvgIconComponent } from './components/adult-svg-icon/adult-svg-icon.component';
import { AuditsComponent } from './components/audits/audits.component';
import { BankAccountDetailsComponent } from './components/bank-account-details/bank-account-details.component';
import { CancellationDetailsComponent } from './components/cancellation-details/cancellation-details.component';
import { ChildSvgIconComponent } from './components/child-svg-icon/child-svg-icon.component';
import { ContractTimelineComponent } from './components/contract-timeline/contract-timeline.component';
import { DeclarationsOfConsentComponent } from './components/declarations-of-consent/declarations-of-consent.component';
import { EditButtonComponent } from './components/edit-button/edit-button.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { InsuredPeopleComponent } from './components/insured-people/insured-people.component';
import { MarketingFlagComponent } from './components/marketing-flag/marketing-flag.component';
import { NavigationActionbarComponent } from './components/navigation-actionbar/navigation-actionbar.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { NotesComponent } from './components/notes/notes.component';
import { OrganizationMainContactComponent } from './components/organization-main-contact/organization-main-contact.component';
import { SharedPersonAgeIconComponent } from './components/person-age-icon/person-age-icon.component';
import { PersonEditorComponent } from './components/person-editor/person-editor.component';
import { ProductIconComponent } from './components/product-icon/product-icon.component';
import { ContractProductComponent } from './components/product/product.component';
import { StatusOverviewComponent } from './components/status-overview/status-overview.component';
import { SwitchCheckboxComponent } from './components/switch-checkbox/switch-checkbox.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { Configuration } from './models/configuration.model';
import { HasPermissionDirective } from './permissions/directives/has-permission.directive';
import { AuditFieldPipe } from './pipes/audit-field.pipe';
import { BankAccountTypePipe } from './pipes/bankaccounttype.pipe';
import { CloneDeepPipe } from './pipes/clone-deep.pipe';
import { ContractTypeShortPipe } from './pipes/contract-type-short.pipe';
import { ContractTypeSubTypePipe } from './pipes/contract-type-sub-type.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { CreatorPipe } from './pipes/creator.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { InPipe } from './pipes/in.pipe';
import { ListJoinPipe } from './pipes/list.join.pipe';
import { MimeTypeColorPipe } from './pipes/mime-type-color.pipe';
import { MimeTypeIconPipe } from './pipes/mime-type-icon.pipe';
import { PartnerCurrencyPipe } from './pipes/partner-currency.pipe';
import { PartnerIdKeyPipe } from './pipes/partner-id-key.pipe';
import { PartnerIdPipe } from './pipes/partner-id.pipe';
import { TooltipPipe } from './pipes/tooltip.pipe';
import { TravelContractTypePipe } from './pipes/travel-contract-type.pipe';
import { CommonStateService } from './services/common-state.service';
import { CountryService } from './services/country.service';
import { LoggingHttpInterceptor } from './utils/logging-http-interceptor';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        KeycloakAngularModule,
        FormsModule,
        HttpClientModule,
        PrimengModule,
        NgxIbanModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: false,
            extend: true,
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: KeycloakInitializer,
            multi: true,
            deps: [KeycloakService, Configuration, CommonStateService],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggingHttpInterceptor,
            multi: true,
        },
        PartnerIdKeyPipe,
        CountryService,
        CountryNamePipe,
    ],
    declarations: [
        PartnerIdPipe,
        ContractTypeShortPipe,
        TooltipPipe,
        PersonEditorComponent,
        GenderPipe,
        NotesComponent,
        CreatorPipe,
        InsuredPeopleComponent,
        TableCellComponent,
        AuditsComponent,
        AuditFieldPipe,
        SwitchCheckboxComponent,
        EmptyPipe,
        InPipe,
        EditButtonComponent,
        CloneDeepPipe,
        HasPermissionDirective,
        PartnerIdKeyPipe,
        NoAccessComponent,
        MimeTypeColorPipe,
        MimeTypeIconPipe,
        ListJoinPipe,
        PartnerCurrencyPipe,
        InputCurrencyComponent,
        CountryNamePipe,
        NavigationActionbarComponent,
        BankAccountDetailsComponent,
        BankAccountTypePipe,
        AddressComponent,
        StatusOverviewComponent,
        CancellationDetailsComponent,
        ContractTimelineComponent,
        ContractProductComponent,
        ProductIconComponent,
        TravelContractTypePipe,
        MarketingFlagComponent,
        DeclarationsOfConsentComponent,
        ContractTypeSubTypePipe,
        SharedPersonAgeIconComponent,
        ChildSvgIconComponent,
        AdultSvgIconComponent,
        OrganizationMainContactComponent,
    ],
    exports: [
        PrimengModule,
        TranslateModule,
        FormsModule,
        PartnerIdPipe,
        ContractTypeShortPipe,
        TooltipPipe,
        PersonEditorComponent,
        TableCellComponent,
        NotesComponent,
        InsuredPeopleComponent,
        AuditsComponent,
        GenderPipe,
        InPipe,
        SwitchCheckboxComponent,
        EditButtonComponent,
        CloneDeepPipe,
        HasPermissionDirective,
        PartnerIdKeyPipe,
        NoAccessComponent,
        MimeTypeColorPipe,
        MimeTypeIconPipe,
        ListJoinPipe,
        PartnerCurrencyPipe,
        InputCurrencyComponent,
        CountryNamePipe,
        NavigationActionbarComponent,
        BankAccountDetailsComponent,
        BankAccountTypePipe,
        AddressComponent,
        StatusOverviewComponent,
        CancellationDetailsComponent,
        ContractTimelineComponent,
        ContractProductComponent,
        ProductIconComponent,
        TravelContractTypePipe,
        MarketingFlagComponent,
        DeclarationsOfConsentComponent,
        SharedPersonAgeIconComponent,
        OrganizationMainContactComponent,
    ],
})
export class SharedModule {
    private static config: Configuration;

    public static forRoot(config: Configuration): ModuleWithProviders<SharedModule> {
        this.config = config;
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: Configuration,
                    useValue: config ?? this.config,
                },
            ],
        };
    }
}
