import { Component, Input } from '@angular/core';

@Component({
    selector: 'shared-contract-product',
    templateUrl: './product.component.html',
})
export class ContractProductComponent {
    @Input() partnerId: string;
    @Input() contractTypeName: string;
}
