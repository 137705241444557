import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, Reference } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto, QueryRequestDto, QueryResultDto } from '@nuis/common';
import { firstValueFrom } from 'rxjs';
import { mapNotificationDto, NotificationDto, NotificationFilterDto, NotificationQueryResultDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class NotificationApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public async getNotifications(
        options: QueryRequestDto<NotificationFilterDto>,
    ): Promise<QueryResultDto<NotificationDto>> {
        const response = await firstValueFrom(
            this.http.post<NotificationQueryResultDto<NotificationDto & { timestamp: string; emailId: string | null }>>(
                `${this.config.baseUrl}Notifications/QueryList`,
                options,
            ),
        );

        return {
            totalRecords: response.totalRecords,
            records: response.notifications.map(mapNotificationDto),
        };
    }

    public async getNotificationsByReference(reference: Reference) {
        const response = await firstValueFrom(
            this.http.get<(NotificationDto & { timestamp: string; emailId: string | null })[]>(
                `${this.config.baseUrl}Notifications/GetNotifications`,
                { params: { ...reference } },
            ),
        );

        return response.map(mapNotificationDto);
    }

    public async getMessagesTypes(): Promise<OverviewDto[]> {
        const messageTypes: OverviewDto[] = [
            { id: 'email', name: this.translate.instant('messageTypes.email') },
            { id: 'sms', name: this.translate.instant('messageTypes.sms') },
        ];

        return messageTypes;
    }

    public async getNotificationTypes(): Promise<OverviewDto[]> {
        const notificationTypes: OverviewDto[] = [
            { id: 'welcome', name: this.translate.instant('notificationTypes.welcome') },
            { id: 'start', name: this.translate.instant('notificationTypes.start') },
            { id: 'end', name: this.translate.instant('notificationTypes.end') },
            { id: 'startsuspended', name: this.translate.instant('notificationTypes.startsuspended') },
            { id: 'proposalLink', name: this.translate.instant('notificationTypes.proposalLink') },
            { id: 'deactivationSuccess', name: this.translate.instant('notificationTypes.deactivationSuccess') },
            { id: 'startTravelWarning', name: this.translate.instant('notificationTypes.startTravelWarning') },
            { id: 'cancellationbycustomer', name: this.translate.instant('notificationTypes.cancellationbycustomer') },
            { id: 'cancellationbyinsurer', name: this.translate.instant('notificationTypes.cancellationbyinsurer') },
            { id: 'futureterminationdate', name: this.translate.instant('notificationTypes.futureterminationdate') },
            {
                id: 'cancellationDueToOldAge',
                name: this.translate.instant('notificationTypes.cancellationDueToOldAge'),
            },
            {
                id: 'additionalInsuredPersonPreRemoval',
                name: this.translate.instant('notificationTypes.additionalInsuredPersonPreRemoval'),
            },
            {
                id: 'additionalInsuredPersonRemoval',
                name: this.translate.instant('notificationTypes.additionalInsuredPersonRemoval'),
            },
            { id: 'coverChangeSuccess', name: this.translate.instant('notificationTypes.coverChangeSuccess') },
            {
                id: 'newCountryTravelWarning',
                name: this.translate.instant('notificationTypes.newCountryTravelWarning'),
            },
            { id: 'updatemsisdn', name: this.translate.instant('notificationTypes.updatemsisdn') },
            {
                id: 'policyendorsementgeneral',
                name: this.translate.instant('notificationTypes.policyendorsementgeneral'),
            },
            { id: 'partialTravelWarning', name: this.translate.instant('notificationTypes.partialTravelWarning') },
            {
                id: 'exceededtraveltermination',
                name: this.translate.instant('notificationTypes.exceededtraveltermination'),
            },
            { id: 'contractSuspended', name: this.translate.instant('notificationTypes.contractSuspended') },
            {
                id: 'deactivationFailedExpired',
                name: this.translate.instant('notificationTypes.deactivationFailedExpired'),
            },
            {
                id: 'contractSuspendedNoTravel',
                name: this.translate.instant('notificationTypes.contractSuspendedNoTravel'),
            },
            {
                id: 'proposalLegalInformation',
                name: this.translate.instant('notificationTypes.proposalLegalInformation'),
            },
        ];

        return notificationTypes;
    }

    public async getNotificationStatuses(): Promise<OverviewDto[]> {
        const notificationStatuses: OverviewDto[] = [
            { id: 'Sent', name: this.translate.instant('notification.status.sent'), color: 'gray' },
            { id: 'SentByPartner', name: this.translate.instant('notification.status.sentByPartner'), color: 'gray' },
            { id: 'Open', name: this.translate.instant('notification.status.open'), color: 'orange' },
            { id: 'Failed', name: this.translate.instant('notification.status.failed'), color: 'red' },
        ];

        return notificationStatuses;
    }
}
