<ul class="list-none p-0 m-0">
    <li class="surface-card p-3 mb-3 shadow-2 border-round">
        <div class="flex flex-row justify-content-between align-items-center">
            <span class="text-xl font-medium text-900 ml-1">{{ noteViewHeader | translate }}</span>
            <span>
                @if (showNewNote) {
                    <button
                        pRipple
                        pButton
                        type="button"
                        icon="pi pi-send"
                        class="p-button-text p-button-success text-xl"
                        [pTooltip]="'tooltip.save' | translate"
                        tooltipPosition="left"
                        (click)="addNote()"></button>
                    <button
                        pRipple
                        pButton
                        type="button"
                        icon="pi pi-times"
                        class="p-button-text p-button-danger text-xl"
                        [pTooltip]="'tooltip.cancel' | translate"
                        (click)="showNote()"></button>
                } @else {
                    <button
                        pRipple
                        pButton
                        type="button"
                        icon="pi pi-plus"
                        class="p-button-text p-button-success text-xl"
                        [pTooltip]="'tooltip.add' | translate"
                        tooltipPosition="left"
                        (click)="showNote()"></button>
                }
            </span>
        </div>

        @if (showNewNote) {
            <div class="mb-2">
                @for (note of quickNotes; track note.title) {
                    <a (click)="fillInputs(note)">
                        <p-chip
                            [label]="note.chipTitle | translate"
                            [styleClass]="
                                'p-chip-medium m-2 bg-' + note.color + '-200 text-' + note.color + '-900'
                            "></p-chip>
                    </a>
                }
            </div>
            <div class="grid formgrid p-fluid p-1 border-round">
                <div class="col-12 mb-3">
                    <label for="title" class="font-medium text-900">{{ 'notes.title' | translate }}</label>
                    <input
                        type="text"
                        id="title"
                        pInputText
                        [(ngModel)]="subject"
                        (keyup)="this.subjectEvent.emit(subject)" />
                </div>

                <div class="col-12 mb-2">
                    <label for="body" class="font-medium text-900">{{ 'notes.body' | translate }}</label>
                    <textarea
                        id="body"
                        type="text"
                        pInputTextarea
                        rows="2"
                        [(ngModel)]="body"
                        (keyup)="this.bodyEvent.emit(body)"></textarea>
                </div>
            </div>
        }

        <div *ngIf="showSavedNotes">
            <div
                *ngFor="let note of notes"
                class="cursor-pointer p-2 pb-0 border-round text-900 hover:surface-200 transition-duration-150 transition-colors flex flex-row">
                <span>
                    <div
                        [class]="
                            'bg-' +
                            this.note.color +
                            '-100 mr-2 border-circle relative h-3rem w-3rem text-sm flex flex-row align-items-center justify-content-around'
                        ">
                        <span [class]="'text-' + this.note.color + '-700 font-medium text-xl'">{{
                            this.note.initials
                        }}</span>
                    </div>
                </span>
                <span class="flex flex-column w-full">
                    <span class="flex flex-row justify-content-between w-full">
                        <span>
                            <span *ngIf="showText(note)" class="font-medium">
                                {{ note.subject }}
                            </span>
                            <div *ngIf="isEditing && !showText(note)">
                                <input id="notesSubject" pInputText [(ngModel)]="newSubject" />
                            </div>
                            <span *ngIf="!isEditing">
                                {{ 'notes.by' | translate }}
                                <span class="font-medium">{{ note.creator | creator }} </span>
                            </span>
                        </span>

                        <span class="font-medium text-700" *ngIf="!isEditing">{{
                            note.timestamp | date: 'dd.MM.yyyy, HH:mm'
                        }}</span>
                    </span>

                    <span class="font-italic p-0 text-700 flex flex-row justify-content-between w-full">
                        <span class="note-body">
                            <span *ngIf="showText(note)" style="white-space: break-spaces">
                                {{ note.body }}
                            </span>
                            <span *ngIf="isEditing && !showText(note)">
                                <textarea
                                    id="editNotesBody"
                                    pInputTextarea
                                    [(ngModel)]="newText"
                                    [autoResize]="true"
                                    class="text-area"></textarea>
                            </span>
                        </span>
                        <span class="flex flex-row">
                            @if (!isEditing && showText(note)) {
                                <button
                                    *ngIf="!isEditing && showText(note)"
                                    pButton
                                    type="button"
                                    icon="pi pi-pencil"
                                    class="p-button-text text-xl p-0"
                                    [pTooltip]="'tooltip.edit' | translate"
                                    tooltipPosition="left"
                                    (click)="editNote(note)"></button>
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-trash"
                                    class="p-button-text p-button-danger text-xl"
                                    [pTooltip]="'tooltip.delete' | translate"
                                    tooltipPosition="left"
                                    (click)="deleteNote(note)"></button>
                            }

                            @if (isEditing && !showText(note)) {
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-check"
                                    class="p-button-text p-button-success text-xl"
                                    [pTooltip]="'tooltip.save' | translate"
                                    tooltipPosition="left"
                                    (click)="saveEdit(note)"></button>
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-times"
                                    class="p-button-text p-button-danger text-xl"
                                    [pTooltip]="'tooltip.cancel' | translate"
                                    tooltipPosition="left"
                                    (click)="cancelEdit()"></button>
                            }
                        </span>
                    </span>
                </span>
            </div>
        </div>

        <div *ngIf="notes.length === 0 && !showNewNote" class="text-700 mt-2">
            <i class="pi pi-info-circle text-xl mr-2"></i>
            {{ 'noNotesFound' | translate }}
        </div>
    </li>
</ul>
