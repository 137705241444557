import { Pipe, PipeTransform } from '@angular/core';
import { toCamelCase } from '@luis/common/shared';
import { PaymentState } from '../models/transaction.model';

@Pipe({
    name: 'paymentState',
})
export class PaymentStatePipe implements PipeTransform {
    transform(value: PaymentState): string {
        return `paymentStates.${toCamelCase(value)}`;
    }
}
