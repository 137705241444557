import { KeycloakService } from 'keycloak-angular';
import { KeycloakFlow, KeycloakOnLoad } from 'keycloak-js';
import { Configuration } from '../models/configuration.model';

export function KeycloakInitializer(keycloak: KeycloakService, configuration: Configuration) {
    const config = configuration.keycloak;

    return () => {
        if (config.enabled) {
            return keycloak.init({
                config: {
                    url: config.client.url,
                    realm: config.client.realm,
                    clientId: config.client.clientId,
                },
                initOptions: {
                    onLoad: config.client.keycloakOnLoad as KeycloakOnLoad,
                    checkLoginIframe: config.client.checkLoginIframe,
                    flow: config.client.flow as KeycloakFlow,
                },
                loadUserProfileAtStartUp: false,
                enableBearerInterceptor: config.client.enableBearerInterceptor,
                bearerPrefix: 'Bearer',
                bearerExcludedUrls: ['/assets', '/clients/public'],
            });
        } else {
            console.warn('Disabled authorization');
            return true;
        }
    };
}
