const CONTRACT_BASE_STATUSES = ['OnRisk', 'Suspended', 'Quote'];

export const CONTRACT_CANCELLED_STATUSES = ['CanceledByCustomer', 'CanceledByOperator', 'CanceledByInsurer'];

export const CONTRACT_STATUSES = [...CONTRACT_BASE_STATUSES, ...CONTRACT_CANCELLED_STATUSES];

export type ContractStatus = (typeof CONTRACT_STATUSES)[number];

export class ContractStatusUIMapping {
    translation: string;
    value: string;
}
