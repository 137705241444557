import { MenuItem } from 'primeng/api';

export const ORGANIZATION_TABS: MenuItem[] = [
    { label: 'tab.overview', routerLink: 'overview' },
    { label: 'tab.linkedContracts', routerLink: 'linked-contracts' },
    { label: 'tab.documents', routerLink: 'documents' },
    { label: 'tab.notifications', routerLink: 'notifications' },
    { label: 'tab.additional', routerLink: 'additional' },
    //{ label: 'tab.audits', routerLink: 'audits' },
];
