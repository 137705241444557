import { DateTime } from 'luxon';
import { MessageType } from '../enums';

export interface NotificationDto {
    id: string;
    messageType: MessageType;
    notificationType: string;
    sentOn: DateTime;
    status: string;
    sender: string;
    subject: string | null;
    recipients: string[];
}

export const mapNotificationDto = (
    n: NotificationDto & { timestamp: string; emailId: string | null },
): NotificationDto => ({
    ...n,
    sentOn: DateTime.fromISO(n.timestamp),
    messageType: n.emailId != null ? 'email' : 'sms',
    subject: n.subject ?? null,
});
