import { Pipe, PipeTransform } from '@angular/core';
import { ContractType } from '../models/contract-type.model';

@Pipe({
    name: 'travelContractType',
})
export class TravelContractTypePipe implements PipeTransform {
    transform(value: string, contractTypes?: ContractType[]): string {
        if (!contractTypes || !value) {
            return value != null && value != undefined
                ? 'contracttype_names.' + value?.replace('Travel_Insurance_', '').toLowerCase()
                : value;
        } else {
            const name = contractTypes?.find((q) => q.name === value)?.name;
            if (name) {
                return 'contracttype_names.' + name.replace('Travel_Insurance_', '').toLowerCase();
            }
            return value;
        }
    }
}
