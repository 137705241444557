import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EditButtonEvent } from '../../models/edit-button-event.model';

@Component({
    selector: 'shared-edit-button',
    templateUrl: './edit-button.component.html',
})
export class EditButtonComponent {
    @Input() isEditing = false;
    @Output() isEditingChange = new EventEmitter<boolean>();
    @Output() event = new EventEmitter<EditButtonEvent>();
    @Input() isEditButtonDisabled = false;

    edit() {
        this.isEditing = true;
        this.event.emit('edit');
        this.isEditingChange.emit(this.isEditing);
    }

    saveEdit() {
        this.isEditing = false;
        this.event.emit('saveEdit');
        this.isEditingChange.emit(this.isEditing);
    }

    cancelEdit() {
        this.isEditing = false;
        this.event.emit('cancelEdit');
        this.isEditingChange.emit(this.isEditing);
    }
}
