<div class="">
    <div class="mb-2 flex flex-row justify-content-between align-items-center">
        <span class="text-xl font-medium text-900 mb-2">{{ 'bankAccountInfo' | translate }}</span>
        <shared-edit-button
            (event)="onEditEvent($event)"
            [isEditButtonDisabled]="!isBankDataValid()"
            [(isEditing)]="isEditing"></shared-edit-button>
    </div>
    <div class="grid formgrid p-fluid">
        <div class="field col-6 md:col-6 text-600 align-self-start">
            <label for="bankType" class="font-medium text-900">{{ 'bankAccountType' | translate }}</label>
            <p-dropdown
                id="bankType"
                [(ngModel)]="selectedBankType"
                [disabled]="isEditing === false"
                [options]="bankTypes"
                (ngModelChange)="bankAccountDetails.type = selectedBankType">
                <ng-template let-item pTemplate="item">
                    {{ item | bankAccountType | translate }}
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{ selectedItem | bankAccountType | translate }}
                </ng-template>
            </p-dropdown>
        </div>
        <div class="field col-6 md:col-6"></div>
        <div
            class="field col-6 md:col-6 text-600 align-self-start"
            [ngClass]="{ 'mb-0': isBankAccountholderValid || isInputIbanValid }">
            <label for="bankAccountholder" class="font-medium text-900">{{ 'bankAccountholder' | translate }}</label>
            <input
                type="text"
                id="bankAccountholder"
                pInputText
                [disabled]="isEditing === false"
                [ngClass]="{ 'border-red-700': !isBankAccountholderValid }"
                (ngModelChange)="onSetBankAccountholder($event)"
                [ngModel]="bankAccountholder" />
        </div>

        <div
            class="field col-6 md:col-6 text-600 align-self-start"
            [ngClass]="{ 'mb-0': isBankAccountholderValid || isInputIbanValid }">
            <label for="iban" class="font-medium text-900">{{ 'iban' | translate }}</label>
            <input
                type="text"
                id="iban"
                #iban="ngModel"
                pInputText
                [disabled]="isEditing === false"
                [ngClass]="{ 'border-red-700': !isInputIbanValid }"
                [ngModel]="inputIban"
                (ngModelChange)="onSetIban($event)"
                ngxIban />
        </div>
        <div class="text-red-700 col-12 ms-5" *ngIf="!isBankDataValid() && isEditing === true">
            <small for="iban" class="p-invalid text-red-700">{{ 'claimcreate.payout.error' | translate }}</small>
        </div>
    </div>
</div>
