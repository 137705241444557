import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastSettings } from '../models/toast.model';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private isOpen = new Subject<void>();
    readonly isOpen$ = this.isOpen.asObservable();

    private showToast = new Subject<ToastSettings>();
    readonly showToast$ = this.showToast.asObservable();

    private showLoading = new Subject<boolean>();
    readonly showLoading$ = this.showLoading.asObservable();

    doAction(): void {
        this.isOpen.next();
    }

    showToastMessage(severity: string, detail?: string, summary?: string) {
        this.showToast.next({
            summary: summary,
            severity: severity,
            detail: detail,
        });
    }

    showLoadingSpinner(showSpinner: boolean) {
        this.showLoading.next(showSpinner);
    }
}
