<p-button
    label="Pending"
    (click)="toggleButton('PENDING')"
    [ngClass]="pendingSelected ? 'btn-pending-selected' : ''"
    styleClass="p-button-sm p-button-text p-button-secondary"></p-button>
<p-button
    label="Approve"
    (click)="toggleButton('APPROVE')"
    [ngClass]="hasDifferentButtonStates ? 'btn-approve-both-selected' : approveSelected ? 'btn-approve-selected' : ''"
    styleClass="p-button-sm p-button-text p-button-success"></p-button>
<p-button
    label="Deny"
    (click)="toggleButton('DENY')"
    [ngClass]="hasDifferentButtonStates ? 'btn-deny-both-selected' : denySelected ? 'btn-deny-selected' : ''"
    styleClass="p-button-sm p-button-text p-button-danger"></p-button>
