import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@luis/common/shared';
import { TransactionReversalApprovementTableService } from '@luis/core/transaction';
import { DashboardStatistics } from './dashboard-statistics.model';

@Component({
    selector: 'luis-dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    private readonly userService = inject(UserService);
    private readonly route = inject(ActivatedRoute);
    private readonly travelTransactionReversalApprovementTableService = inject(
        TransactionReversalApprovementTableService,
    );
    private readonly destroyRef = inject(DestroyRef);

    userName: string;
    activeContracts: number;
    openClaims: number;
    policyHolders: number;
    lastActiveContracts?: number;
    lastOpenClaims?: number;
    openReversals: number;
    lastPolicyHolders?: number;
    lastOpenReversals?: number;
    showOpenReversals = false;

    constructor() {
        const statistics: DashboardStatistics = this.route.snapshot.data['statistics'] as DashboardStatistics;
        this.activeContracts = statistics.activeContracts;
        this.openClaims = statistics.openClaims;
        this.policyHolders = statistics.policyholders;
    }

    public ngOnInit() {
        this.userService.user$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((user) => (this.userName = [user.firstname, user.lastname].join(' ')));

        this.travelTransactionReversalApprovementTableService.reversalCount$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((reversalCount) => {
                this.openReversals = reversalCount;
            });

        this.compareSnapshot();
        this.createSnapshot();
    }

    createSnapshot() {
        localStorage.setItem('activeContracts', this.activeContracts.toString());
        localStorage.setItem('openClaims', this.openClaims.toString());
        localStorage.setItem('policyHolders', this.policyHolders.toString());
    }

    compareSnapshot() {
        this.lastActiveContracts = localStorage.getItem('activeContracts')
            ? this.activeContracts - parseInt(localStorage.getItem('activeContracts'))
            : null;
        this.lastOpenClaims = localStorage.getItem('openClaims')
            ? this.openClaims - parseInt(localStorage.getItem('openClaims'))
            : null;
        this.lastPolicyHolders = localStorage.getItem('policyHolders')
            ? this.policyHolders - parseInt(localStorage.getItem('policyHolders'))
            : null;
    }
}
