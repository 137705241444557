<div
    class="w-100 py-2 shadow-4 flex align-items-center relative lg:static surface-section"
    style="height: 55px; z-index: inherit">
    <div
        class="w-100 align-items-center flex-initial hidden lg:flex absolute lg:static left-0 top-100 h-5vh"
        style="z-index: inherit">
        <div
            *ngIf="showProductsMenu"
            class="align-items-center flex-grow-1 hidden lg:flex absolute lg:static w-full left-0 top-100 px-6 lg:px-0 shadow-2 lg:shadow-none h-5vh"
            style="margin-left: 1rem; height: 5vh; z-index: inherit">
            <ul class="list-none p-0 flex lg:align-items-center text-900 select-none flex-column lg:flex-row h-5vh">
                <li
                    class="relative border-top-1 surface-border lg:border-top-none"
                    style="min-height: 5vh; height: 5vh; max-height: 5vh; justify-items: end">
                    <div style="height: 5vh" class="flex align-items-center">
                        <div *ngIf="showProductsMenu" class="flex flex-row align-items-center">
                            <div
                                pRipple
                                class="p-2 border-round hover:surface-200 font-medium transition-colors transition-duration-150 cursor-pointer"
                                pStyleClass="#mega-menu-navbar"
                                enterFromClass="hidden"
                                leaveToClass="hidden"
                                [hideOnOutsideClick]="true">
                                <i class="pi pi-bars mr-3 align-items-center"></i>
                                <span>{{ 'products' | translate }}</span>
                                <i class="pi pi-chevron-down ml-auto lg:ml-3"></i>
                            </div>
                        </div>
                    </div>
                    <div
                        id="mega-menu-navbar"
                        [ngClass]="!isProductsMenuVisible ? '' : 'hidden'"
                        class="border-round shadow-0 lg:shadow-4 lg:absolute surface-section hidden my-2 lg:my-0 w-full lg:w-40rem"
                        style="z-index: 99; width: 40rem !important">
                        <div class="grid">
                            <div class="col-12 lg:col-6">
                                <div
                                    pRipple
                                    class="flex p-3 align-items-center surface-50 transition-colors transition-duration-150 border-round">
                                    <span class="text-xl">LUIS</span>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6">
                                <div
                                    pRipple
                                    class="flex p-3 align-items-center surface-50 transition-colors transition-duration-150 border-round">
                                    <span class="text-xl">CRM</span>
                                </div>
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    *hasPermission="null; product: travelInsurance"
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="setSidebar('travel-insurance')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-compass text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.travel' | translate }}</span>
                                        <div class="text-600 text-sm mt-1">A1</div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('Handset')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-phone text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.handset' | translate }}</span>
                                        <div class="text-600 text-sm mt-1">AUT BGR SRB MKD BGR HRK</div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <!-- empty field -->
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('Travel')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-compass text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.travel' | translate }}</span>
                                        <div class="text-600 text-sm mt-1">Drei-AUT</div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <!-- empty field -->
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('TravelBGR')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-compass text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.travel' | translate }}</span>
                                        <div class="text-600 text-sm mt-1">A1-BGR</div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <!-- empty field -->
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('Cyber')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-shield text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.cyber' | translate }}</span>
                                        <div class="text-600 text-sm mt-1">Drei & A1</div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <!-- Empty column -->
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('Weddie')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-cloud text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.weddie' | translate }}</span>
                                        <div class="text-600 text-sm mt-1"></div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <!-- Empty column -->
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('WeddieStorno')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-ban text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.weddiestorno' | translate }}</span>
                                        <div class="text-600 text-sm mt-1"></div>
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 lg:col-6">
                                <!-- Empty column -->
                            </div>
                            <div class="col-12 lg:col-6">
                                <a
                                    pRipple
                                    class="flex p-3 align-items-center hover:surface-200 transition-colors transition-duration-150 border-round"
                                    (click)="openWebsite('Home')">
                                    <div class="p-2 border-round bg-primary-600 text-0 mr-3">
                                        <i class="pi pi-home text-2xl"></i>
                                    </div>
                                    <div>
                                        <span>{{ 'product.home' | translate }}</span>
                                        <div class="text-600 text-sm mt-1"></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div [style]="showProductsMenu ? 'margin-left: 1rem;' : ''">
            <nav-navbar-breadcrumb></nav-navbar-breadcrumb>
        </div>
        <ul
            id="navbar"
            class="list-none p-0 m-0 lg:align-items-center select-none absolute right-0 z-4"
            style="width: fit-content !important">
            <li
                class="border-top-1 surface-border lg:border-top-none"
                style="min-height: 5vh; height: 5vh; max-height: 5vh; justify-items: end">
                <div style="height: 5vh" class="flex align-items-center">
                    <div>
                        <a
                            pRipple
                            class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-900 hover:surface-200 font-medium border-round cursor-pointer transition-colors transition-duration-150"
                            pStyleClass="#slideover-7"
                            enterFromClass="hidden"
                            enterActiveClass="fadeinright"
                            leaveToClass="hidden"
                            leaveActiveClass="fadeoutright"
                            [hideOnOutsideClick]="true">
                            <div
                                [class]="
                                    'bg-' +
                                    user.profilePicture.color +
                                    '-100 border-circle relative h-2rem w-2rem text-sm flex flex-row align-items-center justify-content-around'
                                ">
                                <span [class]="'text-' + user.profilePicture.color + '-700 font-medium'">{{
                                    user.profilePicture.initials
                                }}</span>
                            </div>
                            <div class="ml-2 flex align-items-center justify-content-center flex-shrink-0">
                                <span class="font-medium">{{ user.firstname }} {{ user.lastname }}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<!-- settings menu -->
<div id="slideover-7" class="hidden surface-ground absolute right-0 shadow-2 w-20rem" style="z-index: 100">
    <div class="flex flex-column p-4" style="height: calc(100vh - 55px)">
        <div class="flex align-items-center mb-4">
            <span class="text-900 text-xl font-medium">{{ 'sidebar.settings.header' | translate }}</span>
            <button
                pRipple
                pButton
                pRipple
                icon="pi pi-times"
                class="p-button-rounded p-button-text p-button-plain"
                pStyleClass="#slideover-7"
                leaveToClass="hidden"
                leaveActiveClass="fadeoutright"></button>
        </div>
        <div class="flex flex-column overflow-y-auto">
            <div class="flex-grow-1">
                <div class="mb-4">
                    <div class="mb-3">
                        <span class="text-900 font-medium mb-1">{{ 'sidebar.settings.language' | translate }}</span>
                    </div>
                    <p-dropdown
                        inputId="country"
                        [options]="languages"
                        optionLabel="name"
                        [showClear]="false"
                        optionDisabled="inactive"
                        autoWidth="false"
                        [style]="{ width: '100%' }"
                        [(ngModel)]="selectedLanguage"
                        (onChange)="setLanguage()">
                        <ng-template let-country pTemplate="item">
                            <div class="flex align-items-center">
                                <span [class]="country?.flag + ' mr-2'"></span>
                                <span>{{ country?.name }}</span>
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="selectedItem">
                            <div class="flex align-items-center">
                                <span [class]="country?.flag + ' mr-2'"></span>
                                <span>{{ country?.name }}</span>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <p-divider></p-divider>
                <div class="grid max-w-full w-full">
                    <div class="col-12">
                        <span class="text-900 font-medium">{{ 'sidebar.settings.theme' | translate }}</span>
                    </div>
                    <div class="col-12 lg:col-6">
                        <div
                            class="shadow-2 border-round border-3 cursor-pointer bg-white h-full"
                            [ngClass]="{
                                'border-transparent': selectedTheme !== 0,
                                'border-700': selectedTheme === 0,
                            }">
                            <div
                                class="p-3 flex flex-column align-items-center w-full justify-content-around h-full text-black"
                                (click)="setTheme(0)">
                                <i class="pi pi-sun text-4xl"></i>
                                <span class="font-medium mt-1 text-black">{{ 'lightMode' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 lg:col-6">
                        <div
                            class="shadow-2 border-round border-3 cursor-pointer bg-gray-900 h-full"
                            [ngClass]="{
                                'border-transparent': selectedTheme !== 1,
                                'border-700': selectedTheme === 1,
                            }">
                            <div
                                class="p-3 flex flex-column align-items-center w-full justify-content-around h-full text-white"
                                (click)="setTheme(1)">
                                <i class="pi pi-moon text-4xl"></i>
                                <span class="font-medium mt-1">{{ 'darkMode' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <p-divider></p-divider>
                <div>
                    <div class="mb-3">
                        <span class="text-900 font-medium mb-1">{{ 'sidebar.settings.navigation' | translate }}</span>
                    </div>
                    <p-dropdown
                        inputId="sidebar"
                        [options]="sidebarTypes"
                        optionLabel="name"
                        [showClear]="false"
                        [(ngModel)]="selectedType"
                        (onChange)="setSidebarType('travel-insurance')"
                        optionDisabled="inactive"
                        autoWidth="false"
                        [style]="{ width: '100%' }">
                        <ng-template let-sidebar pTemplate="item">
                            <div class="flex align-items-center">
                                {{ sidebar.name }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="flex-none flex w-full max-w-full justify-content-center">
                <div class="max-w-full">
                    <button
                        pRipple
                        pButton
                        type="button"
                        label="Logout"
                        (click)="logOut()"
                        icon="pi pi-power-off"
                        iconPos="left"
                        class="p-button-text p-button-text w-full max-w-full"
                        style="text-align: left"></button>
                </div>
            </div>
            <p-divider></p-divider>
            <div class="flex-none flex w-full max-w-full justify-content-center">
                <div class="max-w-full">
                    <button
                        pRipple
                        pButton
                        type="button"
                        label="Deactivate Permissions"
                        (click)="deactivatePermissions()"
                        icon="pi pi-ban"
                        iconPos="left"
                        class="p-button-text p-button-text w-full max-w-full"
                        style="text-align: left"></button>
                </div>
            </div>
        </div>
    </div>
</div>
