import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { State } from '../models/state.model';
import { generateProfilePicture } from '../models/profile-picture.model';
import { User } from '../models/user.model';

export interface UserState {
    user: User;
    userPrincipalId: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService extends State<UserState> {
    user$: Observable<User> = this.select((state) => state.user).pipe(shareReplay({ refCount: true, bufferSize: 1 }));
    userPrincipalId$: Observable<string> = this.select((state) => state.user.email).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor() {
        super({
            user: {
                firstname: 'Max',
                lastname: 'Mustermann',
                email: 'max.mustermann@com',
                profilePicture: generateProfilePicture('max.mustermann@com'),
            },
            userPrincipalId: 'max.mustermann@com',
        });
    }

    getUserPrincipal(): string {
        return this.state.userPrincipalId;
    }

    setUser(user: User) {
        this.setState({ user: user, userPrincipalId: user.email });
    }
}
