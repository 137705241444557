import { Pipe, PipeTransform } from '@angular/core';
import { Configuration } from '../models/configuration.model';
import { insert } from '../utils/string-functions';

@Pipe({
    name: 'partnerIdKey',
})
export class PartnerIdKeyPipe implements PipeTransform {
    constructor(private config: Configuration) {}

    transform(value: string, underscore = false): string {
        for (const key in this.config.partnerIds) {
            if (this.config.partnerIds[key as keyof typeof this.config.partnerIds].toLocaleLowerCase() == value)
                return underscore ? key : key.replace('_', '');
        }

        return '';
    }

    transformBack(value: string): string {
        if (value === 'admin') {
            return 'admin';
        }
        if (value === 'h3a') {
            return this.config.partnerIds['h3a_aut'];
        }
        return this.config.partnerIds[insert(value, 2, '_') as keyof typeof this.config.partnerIds] ?? '';
    }
}
