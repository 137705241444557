<div class="h-full overflow-auto">
    <div class="w-12 sm:w-8 px-4 py-8 md:px-6 lg:px-8 z-1">
        <div class="border-left-2 text-900">
            <span class="font-bold text-2xl text-900 inline-block px-3">404</span>
        </div>
        <div class="mt-6 mb-5 font-bold text-6xl text-900">
            {{ 'pageNotFound.title' | translate }}
        </div>
        <p class="text-700 text-3xl mt-0 mb-6">
            {{ 'pageNotFound.message' | translate }}
        </p>
    </div>
</div>
