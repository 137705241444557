import { inject, Injectable } from '@angular/core';
import { Configuration, Guid, HttpClientAdapter } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { InvoiceDto, MoneyDto, OverviewDto } from '../../dtos';
import { chunkFile, megabyte } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class InvoiceApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public async getInvoices(claimId: string): Promise<InvoiceDto[]> {
        console.log(claimId); // TODO: Remove hardcoded data
        return [
            {
                id: '1',
                invoiceNumber: 'INV-001',
                invoiceOn: DateTime.now(),
                state: 'pending',
                type: 'medicalBill',
                invoiced: { amount: 1500, currency: 'TRL' },
                invoicedConverted: { amount: 850, currency: 'EUR' },
                claimed: { amount: 1500, currency: 'TRL' },
                claimedConverted: { amount: 850, currency: 'EUR' },
                paymentState: 'fullyAccepted',
                paid: { amount: 500, currency: 'USD' },
                accepted: { amount: 500, currency: 'USD' },
                file: {
                    name: 'invoice.pdf',
                    size: 1024,
                },
            },
            {
                id: '2',
                invoiceNumber: 'INV-002',
                invoiceOn: DateTime.now().minus({ days: 1 }),
                state: 'paid',
                type: 'medicalBill',
                invoiced: { amount: 2000, currency: 'USD' },
                invoicedConverted: { amount: 1700, currency: 'EUR' },
                claimed: { amount: 2000, currency: 'USD' },
                claimedConverted: { amount: 1700, currency: 'EUR' },
                paymentState: 'fullyAccepted',
                paid: { amount: 2000, currency: 'USD' },
                accepted: { amount: 2000, currency: 'USD' },
                file: {
                    name: 'invoice.pdf',
                    size: 50024,
                },
            },
            {
                id: '3',
                invoiceNumber: 'INV-003',
                invoiceOn: DateTime.now().minus({ days: 2 }),
                state: 'rejected',
                type: 'medicalBill',
                invoiced: { amount: 1500, currency: 'USD' },
                invoicedConverted: { amount: 1275, currency: 'EUR' },
                claimed: { amount: 1500, currency: 'USD' },
                claimedConverted: { amount: 1275, currency: 'EUR' },
                paymentState: 'partiallyAccepted',
                paid: null,
                accepted: null,
                file: {
                    name: 'invoice.pdf',
                    size: 81123024,
                },
            },
        ];
    }

    public async getInvoiceStates(): Promise<OverviewDto[]> {
        // TODO:
        const invoiceStates: OverviewDto[] = [
            { id: 'pending', name: 'Pending', color: 'gray' },
            { id: 'paid', name: 'Paid', color: 'green' },
            { id: 'rejected', name: 'Rejected', color: 'red' },
        ];

        return invoiceStates;
    }

    public async getInvoiceTypes(): Promise<OverviewDto[]> {
        const invoiceTypes: OverviewDto[] = [
            { id: 'medicalBill', name: 'Medication and Pharmaceuticals' },
            { id: 'test2', name: 'Test 2' },
        ];

        return invoiceTypes;
    }

    public async getInvoiceCurrencies(): Promise<OverviewDto[]> {
        const invoiceCurrency: OverviewDto[] = [
            { id: 'USD', name: 'US Dollar' },
            { id: 'EUR', name: 'Euro' },
        ];

        return invoiceCurrency;
    }

    public async getInvoicePaymentTypes(): Promise<OverviewDto[]> {
        const invoicePaymentTypes: OverviewDto[] = [
            { id: 'partiallyAccepted', name: 'Partially Accepted', icon: 'chart-pie' },
            { id: 'accepted', name: 'Accepted', icon: 'check-circle' },
        ];

        return invoicePaymentTypes;
    }

    // TODO: Will be different
    public async uploadInvoiceChunk(options: {
        correlationId: string;
        chunkIndex: number;
        chunk: Blob;
    }): Promise<void> {
        const formData = new FormData();
        formData.append('file', options.chunk);
        formData.append('correlationId', options.correlationId);
        formData.append('chunkIndex', options.chunkIndex.toString());

        return firstValueFrom(this.http.post(`${this.config.baseUrl}Invoice/UploadChunk`, formData));
    }

    // TODO: Will be different
    public async createInvoice(
        file: File,
        options: {
            invoiceOn: DateTime;
            invoiceNumber: string;
            type: string;
            invoiced: MoneyDto;
            claimed: MoneyDto;
            supplier: string;
            initiator: string;
            note: string | null;
            claimId: string;
        },
    ): Promise<void> {
        const correlationId = Guid.generate();
        const chunks = chunkFile(file, megabyte(1));

        const chunkPromises = [];
        for (let i = 0; i < chunks.length; i++) {
            chunkPromises.push(
                this.uploadInvoiceChunk({
                    correlationId: correlationId,
                    chunkIndex: i,
                    chunk: chunks[i],
                }),
            );
        }

        await Promise.all(chunkPromises);

        console.log(options);
    }
}
