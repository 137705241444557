import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { OverviewDto, TransactionDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class TransactionApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public async getTransactions(contractId: string): Promise<TransactionDto[]> {
        const transactions = await firstValueFrom(
            this.http.get<TransactionDto[]>(`${this.config.baseUrl}Transactions/ListByContractId`, {
                params: { contractId: contractId },
            }),
        );

        return transactions.map((transaction) => {
            return {
                ...transaction,
                createdOn:
                    transaction.createdOn != null ? DateTime.fromISO(transaction.createdOn as never as string) : null,
                start: transaction.start != null ? DateTime.fromISO(transaction.start as never as string) : null,
                end: transaction.end != null ? DateTime.fromISO(transaction.end as never as string) : null,
            };
        });
    }

    public async getReversalStatuses(): Promise<OverviewDto[]> {
        const reversalStatuses: OverviewDto[] = [
            { id: 'Requested', name: this.translate.instant('reversal.status.requested') },
            { id: 'Approved', name: this.translate.instant('reversal.status.approved') },
            { id: 'Denied', name: this.translate.instant('reversal.status.denied') },
            { id: 'Finished', name: this.translate.instant('reversal.status.finished') },
        ];

        return reversalStatuses;
    }

    public async getTransactionStates(): Promise<OverviewDto[]> {
        const transactionStates: OverviewDto[] = [
            { id: 'Open', name: this.translate.instant('paymentStates.open'), color: 'gray' },
            { id: 'Paid', name: this.translate.instant('paymentStates.paid'), color: 'green' },
            { id: 'Pending', name: this.translate.instant('paymentStates.pending'), color: 'yellow' },
            { id: 'Failed', name: this.translate.instant('paymentStates.failed'), color: 'red' },
            { id: 'Locked', name: this.translate.instant('paymentStates.locked'), color: 'orange' },
        ];

        return transactionStates;
    }
}
