<ul class="list-none p-0 m-0">
    <li class="surface-card p-3 shadow-2 border-round">
        <div class="p-2 flex flex-row justify-content-between" *ngIf="status">
            <span class="text-xl font-medium text-900">{{ 'status' | translate }}</span>
            <p-chip [label]="status" [styleClass]="'p-chip-medium status-' + (status | lowercase)"></p-chip>
        </div>
        <div class="grid" *ngIf="statistics">
            <div class="col-12 lg:col-6 xl:col-6" *ngIf="statistics?.openClaims || statistics?.closedClaims">
                <div class="surface-card shadow-1 p-3 border-1 border-50 border-round">
                    <div class="flex justify-content-between mb-3">
                        <div class="text-900">
                            <span class="block font-medium mb-3">
                                <a class="custom-link">
                                    {{ 'claims' | translate }}
                                    <i class="pi pi-link"></i>
                                </a>
                            </span>
                            <span class="text-900 font-medium text-xl">{{ statistics.openClaims }}</span>
                            {{ 'open' | translate }}
                        </div>
                        <div
                            class="flex align-items-center justify-content-center bg-purple-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-shield text-purple-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-600 font-medium">
                        {{ statistics.closedClaims }}
                    </span>
                    <span class="text-600"> {{ 'closed' | translate }}</span>
                </div>
            </div>

            <div class="col-12 lg:col-6 xl:col-6" *ngIf="statistics?.openComplaints || statistics?.closedComplaints">
                <div class="surface-card shadow-1 p-3 border-1 border-50 border-round">
                    <div class="flex justify-content-between mb-3">
                        <div class="text-900">
                            <span class="block font-medium mb-3">
                                {{ 'complaints' | translate }}
                            </span>
                            <span class="text-900 font-medium text-xl">{{ statistics.openComplaints }}</span>
                            {{ 'open' | translate }}
                        </div>
                        <div
                            class="flex align-items-center justify-content-center bg-orange-100 border-round"
                            style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-ban text-orange-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-600 font-medium">{{ statistics.closedComplaints }}</span>
                    <span class="text-600"> {{ 'closed' | translate }}</span>
                </div>
            </div>

            <div
                class="col-6 lg:col-6 xl:col-6"
                *ngIf="statistics?.linkedContracts !== null && statistics?.linkedContracts >= 0">
                <div class="surface-card shadow-1 p-3 border-1 border-50 border-round text-center">
                    <div class="flex justify-content-between mb-3">
                        <div class="text-900">
                            <span class="block font-medium mb-3">
                                {{ 'linkedcontracts' | translate }}
                            </span>
                        </div>
                    </div>
                    <span class="text-600 font-medium text-6xl">{{ statistics.linkedContracts }}</span>
                </div>
            </div>
        </div>
    </li>
</ul>
