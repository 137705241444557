import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, shareReplay } from 'rxjs';
import { State } from '../models/state.model';
import { Language } from '../models/languages.model';

export interface LanguageState {
    language: Language;
}

@Injectable({
    providedIn: 'root',
})
export class LanguageService extends State<LanguageState> {
    language$: Observable<Language> = this.select((state) => state.language).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(private translate: TranslateService) {
        super({
            language: 'de',
        });
        this.restoreLanguage();
    }

    private restoreLanguage() {
        const lastLanguage = localStorage.getItem('language');

        if (lastLanguage) {
            const lang = lastLanguage;
            if (lang) {
                this.setLanguage(lang);
            }
        }
    }

    private saveLanguage() {
        localStorage.setItem('language', this.state.language);
    }

    getActiveLanguage(): Language {
        return this.state.language;
    }

    setLanguage(language: Language) {
        if (language === this.state.language) return;

        this.setState({
            language: language,
        });
        this.translate.use(language);
        this.saveLanguage();
    }
}
