export interface SidebarLanguage {
    name: string;
    code: string;
    flag: string;
}

export const SIDEBAR_LANGUAGES: SidebarLanguage[] = [
    { name: 'Deutsch', code: 'de', flag: 'fi fi-de' },
    { name: 'English', code: 'en', flag: 'fi fi-gb' },
];
