import { Guid as guid } from 'guid-typescript';

const regexExp = /^(\w|\d){8}-(\w|\d){4}-(\w|\d){4}-(\w|\d){4}-(\w|\d){12}$/gi;

export class Guid {
    public static isValid(guid: string): boolean {
        return regexExp.test(guid);
    }

    public static generate(): string {
        return guid.create().toString();
    }
}
