<div
    class="py-1 flex align-items-center justify-content-between relative lg:static"
    style="min-height: 5vh; height: 5vh; max-height: 5vh">
    <ul class="list-none pl-3 pt-1 pb-1 m-0 flex align-items-center font-medium">
        <li>
            <a class="cursor-pointer" routerLink="">
                <i
                    class="pi pi-home text-700 hover:surface-200 transition-duration-150 transition-colors p-1 border-round"></i>
            </a>
        </li>
    </ul>
    <ul
        class="list-none p-3 pl-1 m-0 flex align-items-center font-medium overflow-x-auto w-full"
        *ngIf="breadcrumbs && breadcrumbs.length > 0">
        <li *ngFor="let crumb of breadcrumbs">
            <div class="flex flex-row">
                <span class="text-gray-700 p-1 mr-1 ml-1">/</span>
                <a
                    class="cursor-pointer text-700 white-space-nowrap hover:surface-200 transition-duration-150 transition-colors p-1 border-round"
                    (click)="changeComponent(crumb)"
                    >{{ crumb.displayName | translate }}</a
                >
            </div>
        </li>
    </ul>
</div>
