<p-table
    #table
    [value]="transactions"
    scrollDirection="both"
    [resizableColumns]="true"
    [paginator]="false"
    (rowsChange)="setRows($event)"
    [rows]="rowsPerPage"
    [rowsPerPageOptions]="allowedRowsPerPage"
    responsiveLayout="scroll"
    [totalRecords]="totalRecords"
    styleClass="p-datatable-sm "
    [showCurrentPageReport]="false"
    paginatorPosition="bottom"
    sortField="start"
    [sortOrder]="-1"
    [loading]="isLoading">
    <ng-template pTemplate="header">
        <tr id="table-head">
            <th id="blank"></th>
            <th id="checkbox">
                <p-checkbox
                    id="checkbox"
                    [binary]="true"
                    [disabled]="totalRecords === 0"
                    inputId="binary"
                    [(ngModel)]="allTransactionsSelected"
                    (onChange)="checkAll($event)"
                    pTooltip="{{ 'reversal.tooltip.checkAll' | translate }}"
                    *ngIf="checkHasPermission('read_transaction', partnerId) | async"
                    tooltipPosition="top"></p-checkbox>
            </th>
            <th id="transactionType">{{ 'transactionType' | translate }}</th>
            <th id="billingText">{{ 'billingText' | translate }}</th>
            <th id="start" pSortableColumn="start">
                {{ 'start' | translate }} <p-sortIcon field="start"></p-sortIcon>
            </th>
            <th id="end" pSortableColumn="end">{{ 'end' | translate }} <p-sortIcon field="end"></p-sortIcon></th>
            <th id="createdOn" pSortableColumn="createdOn">
                {{ 'createdOn' | translate }}
                <p-sortIcon field="createdOn"></p-sortIcon>
            </th>
            <th id="amount">{{ 'amount' | translate }}</th>
            <th id="currency">{{ 'currency' | translate }}</th>
            <th id="paymentState">{{ 'paymentState' | translate }}</th>
            <th id="reversalStatus">{{ 'reversalStatus' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction let-i="rowIndex">
        <tr
            class="mb-2 cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors">
            <td class="font-medium">{{ transaction.sortIndex }}.</td>
            <td>
                <p-checkbox
                    id="checkbox"
                    [binary]="true"
                    inputId="binary"
                    [(ngModel)]="transaction.isChecked"
                    *ngIf="checkHasPermission('read_transaction', partnerId) | async"
                    (onChange)="this.onTransactionSelected($event, transaction)"
                    [disabled]="this.transaction | disableTransactionCheckbox"
                    pTooltip="{{
                        transaction.reversalStatus !== 'Requested'
                            ? ('reversal.tooltip.checkboxDisabled' | translate)
                            : ('reversal.tooltip.checkboxDisabledRequested' | translate)
                    }}"
                    [tooltipDisabled]="!(this.transaction | disableTransactionCheckbox)"
                    tooltipPosition="top"></p-checkbox>
            </td>

            <td>
                <shared-table-cell [value]="transaction.transactionType">
                    {{ transaction.transactionType | transactionType | translate }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.billingText">
                    {{ transaction.billingText }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.start">
                    {{ transaction.start | date: 'dd.MM.yyyy' }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.end">
                    {{ transaction.end | date: 'dd.MM.yyyy' }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.createdOn">
                    {{ transaction.createdOn | date: 'dd.MM.yyyy HH:mm' }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.amount">
                    {{ transaction.amount | number }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.currency">
                    {{ transaction.currency }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.paymentState">
                    <p-chip
                        [styleClass]="transaction.paymentState | paymentStateStyle"
                        [pTooltip]="transaction.errorMessage"
                        [tooltipDisabled]="false"
                        tooltipPosition="top">
                        {{ transaction.paymentState | paymentState | translate }}
                    </p-chip>
                </shared-table-cell>
            </td>
            <td>
                <shared-table-cell [value]="transaction.reversalStatus">
                    {{ transaction.reversalStatus | reversalStatus | translate }}
                </shared-table-cell>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr class="text-700">
            <td colspan="20">
                <i class="pi pi-info-circle text-xl mr-2"></i>
                {{ 'noResultsFound' | translate }}
            </td>
        </tr>
    </ng-template>
    <!-- add default empty to center spinner -->
    <ng-template pTemplate="footer" let-columns *ngIf="transactions">
        <tr class="text-700">
            <td colspan="20"></td>
        </tr>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
        <div *ngIf="true">
            {{
                'pagination'
                    | translate
                        : {
                              first: state.first,
                              last: state.totalRecords ? state.first + state.rows : 0,
                              totalRecords: state.totalRecords ?? 0,
                          }
            }}
        </div>
    </ng-template>
</p-table>
