<div
    [ngClass]="{
        currency_container_dark: isDark === true,
        currency_container: isDark === false,
    }">
    <div class="font-medium text-900 mb-2">
        <label for="input_currency">{{ label }}</label>
    </div>
    <div
        [ngClass]="{
            input_currency_container_dark: isDark === true,
            input_currency_container: isDark === false,
            input_invalid: isValid === false,
        }">
        <div class="w-12">
            <p-inputNumber
                id="input-number"
                [(ngModel)]="value"
                mode="decimal"
                [(ngModel)]="value"
                [required]="required"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                (ngModelChange)="emitValue()"
                locale="de-DE"
                inputStyleClass="text-right"
                [disabled]="disabled"
                [ngClass]="{
                    text_black: disabled !== true,
                    input_currency_dark: isDark === true,
                    input_currency: isDark === false,
                }" />
        </div>
        <div class="flex-initial">
            <button
                [ngClass]="{
                    currencycode_button_dark: isDark === true,
                    currencycode_button: isDark === false,
                }">
                {{ currencyCode }}
            </button>
        </div>
    </div>
    <small id="input-invalid" *ngIf="isValid === false" for="input-number" class="p-invalid text-red-700">{{
        errorMessage
    }}</small>
</div>
