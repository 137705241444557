import { Route } from '@angular/router';

export const travelRoutes: Route = {
    loadComponent: () => import('./travel.component'),
    children: [
        {
            path: '',
            redirectTo: 'claims',
            pathMatch: 'full',
        },
        {
            path: 'claims',
            loadComponent: () => import('./claims/claims.component'),
        },
    ],
};
