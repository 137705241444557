import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@luis/common/shared';
import { PaymentStatePipe } from './core-transaction/pipes/payment-state.pipe';
import { PaymentStateStylePipe } from './core-transaction/pipes/payment-state-style.pipe';
import { TransactionTypePipe } from './core-transaction/pipes/transaction-type.pipe';
import { TransactionActionbarComponent } from './core-transaction/components/transaction-actionbar/transaction-actionbar.component';
import { TransactionCheckboxClickablePipe } from './core-transaction/pipes/transaction-checkbox.pipe';
import { ReversalStatusPipe } from './core-transaction/pipes/transaction-reversalstatus.pipe';
import { ReversalReasonPipe } from './core-transaction/pipes/transaction-reversalreason.pipe';
import { TransactionReversalApprovementComponent } from './transaction-reversal-approvement/components/transaction-reversal-approvement/transaction-reversal-approvement.component';
import { TransactionReversalApprovementTableComponent } from './transaction-reversal-approvement/components/transaction-reversal-approvement-table/transaction-reversal-approvement-table.component';
import { TransactionReversalApprovementButtonComponent } from './transaction-reversal-approvement/components/transaction-reversal-approvement-button/transaction-reversal-approvement-button.component';
import { TransactionReversalApprovementButtonSmallComponent } from './transaction-reversal-approvement/components/transaction-reversal-approvement-button-small/transaction-reversal-approvement-button-small.component';
import { TransactionRefRelatedDataPipe } from './transaction-reversal-approvement/pipes/transaction-reference-data.pipe';
import { TransactionFullTableComponent } from './core-transaction/components/full-table/full-table.component';
import { TransactionSmallTableComponent } from './core-transaction/components/small-table/small-table.component';

@NgModule({
    declarations: [
        TransactionFullTableComponent,
        TransactionSmallTableComponent,
        PaymentStatePipe,
        PaymentStateStylePipe,
        TransactionTypePipe,
        TransactionActionbarComponent,
        TransactionCheckboxClickablePipe,
        ReversalStatusPipe,
        ReversalReasonPipe,
        TransactionReversalApprovementComponent,
        TransactionReversalApprovementTableComponent,
        TransactionReversalApprovementButtonComponent,
        TransactionReversalApprovementButtonSmallComponent,
        TransactionRefRelatedDataPipe,
    ],
    imports: [CommonModule, SharedModule],
    exports: [
        TransactionFullTableComponent,
        TransactionSmallTableComponent,
        TransactionActionbarComponent,
        TransactionReversalApprovementComponent,
        ReversalStatusPipe,
        ReversalReasonPipe,
        TransactionRefRelatedDataPipe,
    ],
    providers: [TransactionRefRelatedDataPipe],
})
export class CoreTransactionModule {}
