import { ProductCategory, ProductSubMenuTitle, ProductTitle } from '@luis/common/shared';
import { SidebarItem } from './sidebar-item.model';

export const PRODUCT_MENU: ProductMenu = {
    'travel-insurance': [
        {
            product: ProductTitle.TRAVEL_INSURANCE,
            id: 'menu_travel',
            name: 'travel-insurance',
            icon: 'pi-compass',
            productCategory: ProductCategory.TRAVEL_INSURANCE,
            displayTranslationKey: 'travel-insurance',
            routingKey: 'travel-insurance',
            subMenu: [
                {
                    id: ProductSubMenuTitle.CONTRACT,
                    name: 'contracts',
                    icon: 'pi-table',
                    productCategory: ProductCategory.TRAVEL_INSURANCE,
                    displayTranslationKey: 'contracts',
                    permission: 'read_contract',
                    routingKey: 'contracts',
                    subMenuTitle: ProductSubMenuTitle.CONTRACT,
                },
                {
                    id: ProductSubMenuTitle.CLAIM,
                    name: 'claims',
                    icon: 'pi-shield',
                    productCategory: ProductCategory.TRAVEL_INSURANCE,
                    displayTranslationKey: 'claims',
                    permission: 'read_claim',
                    routingKey: 'claims',
                    subMenuTitle: ProductSubMenuTitle.CLAIM,
                },
                {
                    id: ProductSubMenuTitle.TRAVEL,
                    name: 'travels',
                    icon: 'pi-compass',
                    productCategory: ProductCategory.TRAVEL_INSURANCE,
                    displayTranslationKey: 'travels',
                    permission: 'read_contract',
                    routingKey: 'travels',
                    subMenuTitle: ProductSubMenuTitle.TRAVEL,
                },
                {
                    id: ProductSubMenuTitle.ORGANIZATIONS,
                    name: 'organizations',
                    icon: 'pi-building',
                    productCategory: ProductCategory.TRAVEL_INSURANCE,
                    displayTranslationKey: 'organizations',
                    permission: 'read_contract',
                    routingKey: 'organizations',
                    subMenuTitle: ProductSubMenuTitle.ORGANIZATIONS,
                },
                {
                    id: ProductSubMenuTitle.TRAVEL_WARNINGS,
                    name: 'travel-warnings',
                    icon: 'pi-map',
                    productCategory: ProductCategory.TRAVEL_INSURANCE,
                    displayTranslationKey: 'travel-warnings',
                    permission: 'read_contract',
                    routingKey: 'travel-warnings',
                    subMenuTitle: ProductSubMenuTitle.TRAVEL_WARNINGS,
                },
                {
                    id: ProductSubMenuTitle.CONSISTENCY_CHECKS,
                    name: 'consistency-checks',
                    icon: 'pi-exclamation-circle',
                    productCategory: ProductCategory.TRAVEL_INSURANCE,
                    displayTranslationKey: 'consistency-checks',
                    permission: 'read_contract',
                    routingKey: 'consistency-checks',
                    subMenuTitle: ProductSubMenuTitle.CONSISTENCY_CHECKS,
                },
            ],
        },
    ],
};

export interface ProductMenu {
    [key: string]: SidebarItem[];
}
