import { Pipe, PipeTransform } from '@angular/core';
import { Configuration } from '../models/configuration.model';

@Pipe({
    name: 'partnerId',
})
export class PartnerIdPipe implements PipeTransform {
    constructor(private config: Configuration) {}

    transform(value: string): string {
        if (!value) return value;
        switch (value.replace('{', '').replace('}', '').toLocaleUpperCase()) {
            case this.config.partnerIds.a1_aut:
                return 'partnerIds.a1_aut';
            case this.config.partnerIds.a1_hrv:
                return 'partnerIds.a1_hrv';
            case this.config.partnerIds.a1_srb:
                return 'partnerIds.a1_srb';
            case this.config.partnerIds.a1_svn:
                return 'partnerIds.a1_svn';
            case this.config.partnerIds.a1_bgr:
                return 'partnerIds.a1_bgr';
            case this.config.partnerIds.h3a_aut:
                return 'partnerIds.h3a_aut';
            default:
                return value;
        }
    }
}
