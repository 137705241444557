<div class="flex flex-column gap-4 p-3 surface-ground overflow-auto">
    <nuis-input-text class="w-6" [label]="'viewEditor.label' | translate" [control]="labelControl" />

    <div class="flex gap-3">
        <div class="w-6 flex flex-column gap-3">
            <div class="text-center text-xl font-medium">{{ 'viewEditor.hiddenFields' | translate }}</div>

            <div
                class="container flex flex-column gap-2 p-2 border-1 border-dashed border-gray-300 border-round"
                cdkDropList
                #hiddenList="cdkDropList"
                [cdkDropListData]="availableColumns"
                [cdkDropListConnectedTo]="[visibleList]"
                (cdkDropListDropped)="drop($event)">
                @for (column of availableColumns; track $index) {
                    <div
                        class="drag-element flex gap-4 surface-0 border-round border-1 border-gray-300 p-3 cursor-move hover:shadow-2"
                        cdkDrag>
                        <div>{{ $index + 1 }}.</div>
                        <div>{{ column.label }}</div>
                    </div>
                }
            </div>
        </div>

        <div class="w-6 flex flex-column gap-3">
            <div class="text-center text-2xl font-medium">{{ 'viewEditor.visibleFields' | translate }}</div>

            <div
                class="container flex flex-column gap-2 p-2 border-1 border-dashed border-gray-300 border-round"
                cdkDropList
                #visibleList="cdkDropList"
                [cdkDropListData]="usedColumns"
                [cdkDropListConnectedTo]="[hiddenList]"
                (cdkDropListDropped)="drop($event)">
                @for (column of usedColumns; track $index) {
                    <div
                        class="drag-element flex gap-4 surface-0 border-round border-1 border-gray-300 p-3 cursor-move hover:shadow-2"
                        cdkDrag>
                        <div>{{ $index + 1 }}.</div>
                        <div>{{ column.label }}</div>
                    </div>
                }
            </div>
        </div>
    </div>
</div>

<div class="flex gap-3 justify-content-end p-3">
    <p-button [label]="'actions.cancel' | translate" [text]="true" [disabled]="isSaving()" (onClick)="cancel()" />
    <p-button [label]="'actions.save' | translate" [loading]="isSaving()" (onClick)="save()" />
</div>
