import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Restrictions } from '../../models/contract-type-restrictions.model';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'shared-person-age-icon',
    templateUrl: './person-age-icon.component.html',
})
export class SharedPersonAgeIconComponent {
    private readonly themeService = inject(ThemeService);
    private readonly destroyRef = inject(DestroyRef);

    @Input({ required: true }) restrictions: Restrictions;
    @Input({ required: true }) dateOfBirth: Date;

    isDark = false;
    lineColor: string;
    bgColor: string;

    constructor() {
        this.themeService.isDark$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isDark) => {
            this.isDark = isDark;

            if (isDark) {
                this.lineColor = '#ffffff';
                this.bgColor = '#212529';
            } else {
                this.bgColor = '#ffffff';
                this.lineColor = '#212529';
            }
        });
    }

    protected checkIsAdult(): boolean {
        if (this.dateOfBirth) {
            const personYear = new Date(this.dateOfBirth)?.getFullYear();
            const currentYear = new Date().getFullYear();
            const age = currentYear - personYear;

            if (age > this.restrictions?.ageLimitChild) return true;
            else return false;
        }
        return null;
    }
}
