import { Injectable } from '@angular/core';
import { TRANSACTION_ACTIONTITLE } from '@luis/core/contract';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TransactionActionbarService {
    actionbarTitle = new Subject<TRANSACTION_ACTIONTITLE>();
    actionbarTitle$ = this.actionbarTitle.asObservable();

    triggerClose = new Subject<void>();
    triggerClose$ = this.triggerClose.asObservable();

    triggerTitle(title: TRANSACTION_ACTIONTITLE) {
        this.actionbarTitle.next(title);
    }

    triggerCloseDialog() {
        this.triggerClose.next();
    }
}
