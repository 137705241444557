import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NoteReloadService {
    private reloadNotes = new Subject<void>();
    readonly reloadNotes$ = this.reloadNotes.asObservable();

    private resetNoteView = new Subject<void>();
    readonly resetNoteView$ = this.resetNoteView.asObservable();

    reset(): void {
        this.resetNoteView.next();
    }

    reload(): void {
        this.reloadNotes.next();
    }
}
