import { Configuration, HttpClientAdapter, ProductCategory } from '@luis/common/shared';
import { ReferenceTransactionReversalApprovementButtonService } from './transaction-reversal-approvement-button.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TransactionReversalApprovementService {
    baseUrl: string;

    constructor(
        private http: HttpClientAdapter,
        private transactionReversalButtonService: ReferenceTransactionReversalApprovementButtonService,
        config: Configuration,
    ) {
        this.baseUrl = config.baseUrl + 'Transactions';
    }

    sendApprovals(productCategory: ProductCategory, requestBody: object[]) {
        return this.http
            .post(this.baseUrl + '/ApproveReversalRequests?productCategory=' + productCategory, {
                transactions: requestBody,
            })
            .pipe();
    }

    sendDenied() {
        const deniedRequestIds: string[] = [];

        const deniedButtons = this.transactionReversalButtonService.getByButtonState('DENY');
        deniedButtons.forEach((buttonState) => deniedRequestIds.push(buttonState.transactionId));

        return this.http
            .post(this.baseUrl + '/RejectReversalRequests', {
                TransactionIds: deniedRequestIds,
            })
            .pipe();
    }
}
