import { FilterMetadata } from 'primeng/api';
import { Table } from 'primeng/table';

export type Filter = FilterMetadata | FilterMetadata[];

export interface PrimeNgFilter {
    [s: string]: Filter;
}

export function EMPTY_DATE_FILTER(): FilterMetadata[] {
    return [
        {
            value: null,
            operator: 'and',
            matchMode: 'dateIs',
        },
    ];
}

export function EMPTY_TEXT_FILTER(): FilterMetadata[] {
    return [
        {
            value: null,
            operator: 'and',
            matchMode: 'startsWith',
        },
    ];
}

export function EMPTY_ARRAY_FILTER(): FilterMetadata[] {
    return [
        {
            value: null,
            operator: 'and',
            matchMode: 'in',
        },
    ];
}

export function EMPTY_SELECT_FILTER(): Filter {
    return [
        {
            value: null,
            operator: 'and',
            matchMode: 'equals',
        },
    ];
}

export function EMPTY_BINARY_FILTER(): Filter {
    return [
        {
            value: null,
            operator: 'and',
            matchMode: 'contains',
        },
    ];
}

export function setFilter(filters: PrimeNgFilter, ref: Table, name: string): PrimeNgFilter {
    if (ref && ref.filters[name]) {
        filters = {
            ...filters,
            [name]: ref.filters[name],
        };
    }

    return structuredClone(filters);
}
