export interface Sorting {
    sortField: string;
    sortOrder: SortOrder;
}

export enum SortOrder {
    ASC = 1,
    DESC = -1,
}

export const defaultSorting: Sorting = {
    sortField: null,
    sortOrder: null,
};

export const initialSorting: Sorting = {
    sortField: undefined,
    sortOrder: undefined,
};
