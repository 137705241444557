<p-table
    [value]="referenceTransactions"
    responsiveLayout="scroll"
    [resizableColumns]="true"
    scrollDirection="both"
    [lazy]="true"
    [loading]="isLoading"
    (onLazyLoad)="lazyLoadRecords($event)"
    [totalRecords]="totalRecords"
    sortField="startDate"
    [sortOrder]="-1"
    dataKey="policyNumber"
    [showCurrentPageReport]="false"
    paginatorPosition="bottom"
    styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
        <tr id="table-head">
            <th id="empty"></th>
            <th id="policyNumber">{{ 'policyNumber' | translate }}</th>
            <th id="policyHolderName">{{ 'policyHolderName' | translate }}</th>
            <th id="partnerId">{{ 'partnerId' | translate }}</th>
            <th id="product">{{ 'product' | translate }}</th>
            <th id="empty"></th>
            <th id="empty"></th>
        </tr>
    </ng-template>
    <ng-template
        pTemplate="body"
        id="referenceTransaction.refId"
        let-referenceTransaction
        let-i="rowIndex"
        let-expanded="expanded">
        <tr [pSelectableRow]="referenceTransaction">
            <td>
                <button
                    type="button"
                    pButton
                    pRipple
                    [pRowToggler]="referenceTransaction"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>
                <shared-table-cell [value]="referenceTransaction.policyNumber">
                    <a (click)="openContract($event, referenceTransaction?.contractId)" class="custom-link">
                        {{ referenceTransaction.policyNumber }}
                        <i class="pi pi-link"></i>
                    </a>
                </shared-table-cell>
            </td>
            <td>
                <shared-table-cell [value]="referenceTransaction.policyHolderName">
                    {{ referenceTransaction.policyHolderName | translate }}
                </shared-table-cell>
            </td>
            <td>
                <shared-table-cell [value]="referenceTransaction.partnerId">
                    {{ referenceTransaction.partnerId | partnerId | translate }}
                </shared-table-cell>
            </td>
            <td>
                <div
                    *ngIf="referenceTransaction.category === 'TravelInsurance'"
                    class="text-xl font-bold mb-1"
                    [pTooltip]="'product.travel' | translate"
                    tooltipPosition="top">
                    ✈️
                </div>
            </td>
            <td>
                <shared-table-cell [value]="referenceTransaction.selectionState">
                    <core-transaction-reversal-approvement-button
                        [row]="i"
                        [contractTransactionRecord]="referenceTransaction"
                        [transactionButtonStateChange]="transactionButtonStateChange"
                        (unselectTransactions)="triggerSetTransactionButtons($event)"
                        (approveTransactions)="triggerSetTransactionButtons($event)"
                        (denyTransactions)="triggerSetTransactionButtons($event)">
                    </core-transaction-reversal-approvement-button>
                </shared-table-cell>
            </td>
            <td>
                <a
                    *ngIf="referenceTransaction.refRelatedData"
                    (click)="openContract($event, referenceTransaction?.contractId)"
                    class="cursor-pointer">
                    <svg
                        *ngIf="!isDark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        fill="#212529"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h5v-2H4V7h16v12h-5v2h5c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z" />
                        <path d="M13 21v-5h3l-4-5-4 5h3v5z" />
                    </svg>
                    <svg
                        *ngIf="isDark"
                        width="16px"
                        height="16px"
                        viewBox="0 0 24 24"
                        fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h5v-2H4V7h16v12h-5v2h5c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z" />
                        <path d="M13 21v-5h3l-4-5-4 5h3v5z" />
                    </svg>
                </a>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr class="text-700">
            <td colspan="12">
                <i class="pi pi-info-circle text-xl mr-2"></i>
                {{ 'noResultsFound' | translate }}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-referenceTransaction>
        <tr id="table-head">
            <td colspan="1"></td>
            <td colspan="11">
                <div class="p-3" *ngFor="let travelTransaction of referenceTransaction.relatedReversalTransactions">
                    <p-table [value]="travelTransaction.transactions" dataKey="id" sortField="start" [sortOrder]="-1">
                        <ng-template pTemplate="header">
                            <tr>
                                <th id="travelTransaction">
                                    <div
                                        data-html="true"
                                        pTooltip="{{
                                            travelTransaction | refRelatedData: referenceTransaction.category
                                        }}"
                                        tooltipPosition="bottom">
                                        <div
                                            *ngIf="referenceTransaction.category === 'TravelInsurance'"
                                            class="text-xl font-bold mb-1">
                                            <i class="pi pi-info-circle"></i>
                                        </div>
                                    </div>
                                </th>
                                <th id="transactionType">
                                    {{ 'transactionType' | translate }}
                                </th>
                                <th id="billingText">
                                    {{ 'billingText' | translate }}
                                </th>
                                <th id="start" pSortableColumn="start">
                                    {{ 'start' | translate }}
                                    <p-sortIcon field="start"></p-sortIcon>
                                </th>
                                <th id="end">
                                    {{ 'end' | translate }}
                                </th>
                                <th id="amount">
                                    {{ 'amount' | translate }}
                                </th>
                                <th id="referenceTransaction">
                                    <div>
                                        <a
                                            class="cursor-pointer"
                                            (click)="openRelated(referenceTransaction.category, travelTransaction)"
                                            [pTooltip]="'reversal.tooltip.travel' | translate"
                                            tooltipPosition="top">
                                            <i class="pi pi-external-link"></i>
                                        </a>
                                    </div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-relatedObject let-i="rowIndex">
                            <tr>
                                <td>{{ i + 1 }}.</td>
                                <td>{{ relatedObject.transactionType }}</td>
                                <td>{{ relatedObject.billingText }}</td>
                                <td>
                                    {{ relatedObject.start | date: 'dd.MM.yyyy' }}
                                </td>
                                <td>{{ relatedObject.end | date: 'dd.MM.yyyy' }}</td>
                                <td>
                                    {{ relatedObject.amount }}
                                    {{ referenceTransaction.partnerId | partnerCurrency }}
                                </td>
                                <td>
                                    <core-transaction-reversal-approvement-button-small
                                        [openReversalTransaction]="relatedObject"
                                        [referenceTransactionRecord]="referenceTransaction"
                                        (checkUpdateReferenceButton)="setReferenceButtons($event)"
                                        [forceUpdatePending]="forceUpdatePending"
                                        [forceUpdateApprove]="forceUpdateApprove"
                                        [forceUpdateDeny]="forceUpdateDeny">
                                    </core-transaction-reversal-approvement-button-small>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">There are no transactions open for approval on the travel.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="paginatorright" let-state>
        <div *ngIf="true">
            {{
                'pagination'
                    | translate
                        : {
                              first: state.first,
                              last: state.totalRecords ? state.first + state.rows : 0,
                              totalRecords: state.totalRecords ?? 0,
                          }
            }}
        </div>
    </ng-template>
</p-table>
<div class="my-5 mr-5 flex justify-content-end">
    <p-button
        *ngIf="totalRecords > 0 && hasUpdateableState"
        styleClass="p-button-text text-xl"
        (click)="showDialog()"
        label="{{ 'reversal.approve.openDialog' | translate }}"></p-button>
</div>

<p-dialog
    header="{{ 'reversal.approve.dialogHeader' | translate }}"
    [(visible)]="showApproveDialog"
    [modal]="true"
    [style]="{ overflow: 'scroll' }"
    [draggable]="false"
    [resizable]="false">
    <div class="m-5" *ngIf="!showDialogLoading">
        <div></div>
        <div class="m-5" *ngIf="totalRecords > 0">
            <p-table [value]="dialogContractReversalButtonStates">
                <ng-template pTemplate="header">
                    <tr id="table-head">
                        <th></th>
                        <th>{{ 'policyNumber' | translate }}</th>
                        <th>{{ 'start' | translate }}</th>
                        <th>{{ 'end' | translate }}</th>
                        <th>{{ 'reversal.status.approved' | translate }}</th>
                        <th>{{ 'reversal.status.denied' | translate }}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dialogTransactionList let-row="rowIndex">
                    <tr>
                        <td>{{ row + 1 }}.</td>
                        <td>
                            {{ dialogTransactionList.policyNumber }}
                        </td>
                        <td>
                            {{ dialogTransactionList.periodStartedOn }}
                        </td>
                        <td>
                            {{ dialogTransactionList.periodEndsOn }}
                        </td>
                        <td>
                            <div *ngIf="dialogTransactionList.buttonState === 'APPROVE'">
                                <div class="flex justify-content-center text-green-700">x</div>
                            </div>
                        </td>
                        <td>
                            <div *ngIf="dialogTransactionList.buttonState === 'DENY'">
                                <div class="flex justify-content-center text-red-700">x</div>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="mt-5 flex justify-content-end">
            <p-button
                styleClass="p-button-text text-xl mt-5"
                (click)="sendReversals()"
                label="{{ 'reversal.approve.openDialog' | translate }}"></p-button>
        </div>
    </div>
    <div *ngIf="showDialogLoading" class="d-flex">
        <div class="progress-spinner" class="flex justify-content-center align-items-center">
            <p-progressSpinner></p-progressSpinner>
        </div>
    </div>
</p-dialog>
