import { InjectionToken } from '@angular/core';

export enum ProductCategory {
    TRAVEL_INSURANCE = 'TravelInsurance',
    MASTERCARD = 'Mastercard',
    TRAVEL_INSURANCE_DREI = 'TravelInsuranceDrei',
    REFURBED = 'Refurbed',
}

export const PRODUCT_CATEGORY = new InjectionToken<ProductCategory>('');

export enum SubProductCategory {
    ORGANIZATION = 'Organization',
}

export const SUB_PRODUCT_CATEGORY = new InjectionToken<SubProductCategory>('');
