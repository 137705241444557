import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { IbanPipe } from 'ngx-iban';
import { EditButtonEvent } from '../../models/edit-button-event.model';
import { PayoutAccountType, PayoutInformation } from '../../models/payout-information.model';
@Component({
    selector: 'shared-bank-account-details',
    templateUrl: './bank-account-details.component.html',
    providers: [IbanPipe],
})
export class BankAccountDetailsComponent implements OnInit {
    @Input() bankAccountDetails: PayoutInformation;

    @ViewChild('iban') ibanViewChild: NgModel;
    @Output() updateBankAccountInfo = new EventEmitter<PayoutInformation>();

    bankAccountDetailsCopy: PayoutInformation;

    ibanPipe = inject(IbanPipe);
    isBankAccountholderValid = true;
    isInputIbanValid = true;

    inputIban = '';
    bankAccountholder = '';

    isEditing = false;

    bankTypes = Object.values<PayoutAccountType>(PayoutAccountType);
    selectedBankType?: PayoutAccountType;

    ngOnInit(): void {
        if (
            !this.bankAccountDetails ||
            this.bankAccountDetails === undefined ||
            (this.bankAccountDetails && !this.bankAccountDetails?.details)
        )
            this.bankAccountDetails = { type: null, details: { bankAccountholder: '', iban: '' } };

        this.bankAccountholder = this.bankAccountDetails?.details?.bankAccountholder;
        this.inputIban = this.bankAccountDetails?.details?.iban;
        this.selectedBankType = this.bankTypes.filter(
            (q) => q?.toLowerCase().toString() === this.bankAccountDetails?.type?.toLowerCase().toString(),
        )?.[0];
    }

    isBankDataValid() {
        return (
            (!this.inputIban && !this.bankAccountholder) ||
            (this.inputIban && this.bankAccountholder && this.ibanViewChild?.valid)
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSetIban(input: any) {
        this.inputIban = this.ibanPipe.transform(input);
        this.bankAccountDetails.details.iban = input;
        this.checkOnBankDataChange();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSetBankAccountholder(input: any) {
        this.bankAccountholder = input;
        this.bankAccountDetails.details.bankAccountholder = input;
        this.checkOnBankDataChange();
    }

    checkOnBankDataChange() {
        if (
            (this.inputIban === '' || this.inputIban === null || this.inputIban === undefined) &&
            (this.bankAccountholder === '' || this.bankAccountholder === null || this.bankAccountholder === undefined)
        ) {
            this.isBankAccountholderValid = false;
            this.isInputIbanValid = false;
            return;
        } else if (
            (this.inputIban === '' || this.inputIban === null || this.inputIban === undefined) &&
            this.bankAccountholder !== ''
        ) {
            this.isBankAccountholderValid = true;
            this.isInputIbanValid = false;
            return;
        } else if (
            this.inputIban !== '' &&
            (this.bankAccountholder === '' || this.bankAccountholder === null || this.bankAccountholder === undefined)
        ) {
            this.isBankAccountholderValid = false;
            this.isInputIbanValid = true;
            return;
        } else {
            this.isBankAccountholderValid = true;
            this.isInputIbanValid = true;
        }
    }

    onEditEvent(event: EditButtonEvent) {
        this.checkOnBankDataChange();
        switch (event) {
            case 'edit':
                this.#edit();
                return;
            case 'saveEdit':
                this.#saveEdit();
                return;
            case 'cancelEdit':
                this.#cancelEdit();
                return;
            default:
                throw new Error();
        }
    }

    #edit() {
        this.bankAccountDetailsCopy = { ...this.bankAccountDetails };
    }

    #saveEdit() {
        this.updateBankAccountInfo.emit(this.bankAccountDetails);
    }

    #cancelEdit() {
        this.bankAccountDetails = this.bankAccountDetailsCopy;
    }
}
