import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
    AuthService,
    CommonStateService,
    ProductCategory,
    ProductTitle,
    RolesService,
    Theme,
    ThemeService,
    User,
    UserService,
} from '@luis/common/shared';
import { NavbarBreadcrumbService } from '../navbar-breadcrumb/navbar.breadcrumb.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { SIDEBAR_LANGUAGES, SidebarLanguage } from './models/sidebar-language.model';
import { SIDEBAR_TYPES, SidebarType } from './models/sidebar-type.model';
import { NavbarService } from './navbar.service';

@Component({
    selector: 'nav-navbar',
    templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
    private readonly navbarService = inject(NavbarService);
    private readonly sidebarService = inject(SidebarService);
    private readonly breadcrumbService = inject(NavbarBreadcrumbService);
    private readonly authService = inject(AuthService);
    private readonly userService = inject(UserService);
    private readonly themeService = inject(ThemeService);
    private readonly router = inject(Router);
    private readonly roles = inject(RolesService);
    private readonly commonStateService = inject(CommonStateService);
    private readonly destroyRef = inject(DestroyRef);

    travelInsurance = ProductTitle.TRAVEL_INSURANCE;
    languages = SIDEBAR_LANGUAGES;
    sidebarTypes = SIDEBAR_TYPES;
    showProductsMenu = true;
    selectedLanguage: SidebarLanguage | null;
    selectedType: SidebarType | null;
    selectedTheme: number;
    user: User;
    navbarStyle: ' w-full ' | ' ';
    isProductsMenuVisible = false;
    productCategory: ProductCategory;

    public ngOnInit() {
        this.navbarService.selectedSidebarLanguage$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((language) => (this.selectedLanguage = language));

        this.navbarService.selectedSidebarType$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((type) => this.checkSidebar(type));

        this.navbarService.selectedTheme$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((theme) => {
            this.selectedTheme = theme;
        });

        this.userService.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => (this.user = user));

        this.commonStateService.productCategory$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((productCategory) => (this.productCategory = productCategory));

        if (this.productCategory) {
            this.sidebarService.setProductsMenu(this.productCategory);
            this.sidebarService.setBaseSidebarMenu();
        }
    }

    deactivatePermissions() {
        this.roles.deactivate();
    }

    checkSidebar(type: SidebarType | null) {
        this.selectedType = type;
        this.showProductsMenu = type?.code !== 'ST';
        this.navbarStyle = type?.code === 'IC' ? ' ' : ' w-full ';
    }

    setSidebarType(product: string) {
        this.navbarService.setSidebarType(this.selectedType?.code ?? '');
        this.sidebarService.setProductsMenu(product);
        this.sidebarService.setBaseSidebarMenu();

        this.breadcrumbService.resetBreadcrumbs();
        this.router.navigate(['/']);
    }

    setTheme(type: number) {
        this.selectedTheme = type;
        this.navbarService.setTheme(type);
        this.themeService.setTheme(type === 0 ? Theme.LIGHT : Theme.DARK);
    }

    setLanguage() {
        this.navbarService.setLanguage(this.selectedLanguage?.code ?? null);
    }

    setSidebar(product: string) {
        this.toggleProductsMenu();

        this.sidebarService.setProductsMenu(product);
        this.sidebarService.setBaseSidebarMenu();
        this.router.navigate([`/${product}`]);
        this.breadcrumbService.resetBreadcrumbs();
    }

    toggleProductsMenu(): void {
        this.isProductsMenuVisible = !this.isProductsMenuVisible;
    }

    logOut(): void {
        this.authService.logOut().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }

    openWebsite(product: string): void {
        this.toggleProductsMenu();

        switch (product) {
            case 'Handset':
                window.open(
                    'https://crm.integral.local/Ant20/main.aspx?etc=10023&extraqs=%3fpagemode%3diframe%26sitemappath%3dANT%257cAdministration%257cant_policy&pagetype=entitylist&viewid=%7b07CC3B16-D471-4592-87B8-EA2DD6D5ADC5%7d&viewtype=1039',
                    'blank',
                );
                return;
            case 'Travel':
                window.open(
                    'https://crm.integral.local/Lamie/main.aspx?area=lamie_travelinsurancecontract&etc=10146&page=Reiseversicherung&pageType=EntityList&web=true',
                    'blank',
                );
                return;
            case 'TravelBGR':
                window.open(
                    'https://lamieag.crm4.dynamics.com/main.aspx?appid=d64c3ab9-2514-4c3f-9163-094be014a5f3&pagetype=entitylist&etn=lamie_ti2gocontract&viewid=5262675a-c7a1-4ef5-ac16-44a5c2a502bf&viewType=1039',
                    'blank',
                );
                return;
            case 'Cyber':
                window.open(
                    'https://crm.integral.local/Ant20/main.aspx?etc=10038&extraqs=%3fpagemode%3diframe%26sitemappath%3dANT%257cCyberInsurance%257cant_cyberprotectioncontract&pagetype=entitylist&viewid=%7bC1E3DF3C-67F4-430F-B4DF-ECBB84844180%7d&viewtype=1039',
                    'blank',
                );
                return;
            case 'Weddie':
                window.open(
                    'https://crm.integral.local/Lamie/main.aspx?etc=10106&extraqs=%3fpagemode%3diframe%26sitemappath%3dLAMIE%257cExtensions%257clamie_weddinginsurancecontract&pagetype=entitylist&viewid=%7b92E1265B-AB0F-E711-80CE-00155DD46FB8%7d&viewtype=1039',
                    'blank',
                );
                return;
            case 'WeddieStorno':
                window.open(
                    'https://crm.integral.local/Lamie/main.aspx?etc=10106&extraqs=%3fpagemode%3diframe%26sitemappath%3dLAMIE%257cExtensions%257clamie_weddinginsurancecontract&pagetype=entitylist&viewid=%7b053F7421-AB0F-E711-80CE-00155DD46FB8%7d&viewtype=1039',
                    'blank',
                );
                return;
            case 'Home':
                window.open(
                    'https://crm.integral.local/Lamie/main.aspx?etc=10014&extraqs=%3fpagemode%3diframe%26sitemappath%3dLAMIE%257cExtensions%257clamie_contract&pagetype=entitylist&viewid=%7b5D015258-22E7-4079-8C3C-DD1D576E36D1%7d&viewtype=1039',
                    'blank',
                );
                return;
        }
    }
}
