import { Component, EventEmitter, Input, output, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'shared-edit-button',
    standalone: true,
    imports: [TranslateModule, ButtonModule, TooltipModule],
    templateUrl: './edit-button.component.html',
})
export class EditButtonComponent {
    @Input() public isEditing = false;
    @Input() public isEditButtonDisabled = false;
    @Output() public isEditingChange = new EventEmitter<boolean>();

    public edit = output<void>();
    public save = output<void>();
    public cancel = output<void>();

    protected handleEdit() {
        this.isEditing = true;
        this.edit.emit();
        this.isEditingChange.emit(this.isEditing);
    }

    protected handleSave() {
        this.isEditing = false;
        this.save.emit();
        this.isEditingChange.emit(this.isEditing);
    }

    protected handleCancel() {
        this.isEditing = false;
        this.cancel.emit();
        this.isEditingChange.emit(this.isEditing);
    }
}
