<svg
    width="25"
    height="25"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    role="img"
    class="iconify iconify--noto"
    preserveAspectRatio="xMidYMid meet">
    <path
        d="M28 15c-11.23 4.24-16 1-16 1c0 11 5 11 5 11S4 37.89 4 56c0 21 10.49 28.22 10.49 28.22c.54.56 1.41 1.83 1.41 1.83s.3 1.53.29 2.31C16.12 93.84 16 100 22 106s13.19 6.22 16 7c6.18 1.72 40.04 1.06 47.15.59c8.85-.59 16.28-3.57 22.14-9.43c7.44-7.44 4.29-17.48 4.69-18.57c.4-1.1 13.12-15.62 12.03-33.58c-2-33-26-32-26-32S92 4 68 4c-28 0-36.07 9.52-40 11z"
        fill="#ab872f"></path>
    <radialGradient
        id="IconifyId17ecdb2904d178eab6047"
        cx="628.717"
        cy="33.995"
        r="33.722"
        gradientTransform="matrix(-.8776 .4793 -.3113 -.57 643.7 -187.995)"
        gradientUnits="userSpaceOnUse">
        <stop offset=".728" stop-color="#bfa055" stop-opacity="0"></stop>
        <stop offset="1" stop-color="#bfa055"></stop>
    </radialGradient>
    <path
        d="M64.03 114.11c11.02.06 21.72.25 25.1-.68c2.82-.78 11.05-2.42 17.84-8.95c5.12-4.93 6.23-11.84 4.57-17.96c-.18-.68-47.51 27.59-47.51 27.59z"
        fill="url(#IconifyId17ecdb2904d178eab6047)"></path>
    <radialGradient
        id="IconifyId17ecdb2904d178eab6048"
        cx="46.755"
        cy="34.369"
        r="34.005"
        gradientTransform="matrix(.8776 .4793 .3113 -.57 -4.974 90.808)"
        gradientUnits="userSpaceOnUse">
        <stop offset=".728" stop-color="#bfa055" stop-opacity="0"></stop>
        <stop offset="1" stop-color="#bfa055"></stop>
    </radialGradient>
    <path
        d="M64.03 114.11c-11.02.06-22.65-.17-26.03-1.11c-2.82-.78-10-1-16-7s-5.88-12.16-5.81-17.64c.01-.69-.22-1.95-.28-2.25L16 86l48.03 28.11z"
        fill="url(#IconifyId17ecdb2904d178eab6048)"></path>
    <radialGradient
        id="IconifyId17ecdb2904d178eab6049"
        cx="26.619"
        cy="80.139"
        r="34.328"
        gradientTransform="matrix(.0746 -.9972 -.8311 -.0622 91.236 79.391)"
        gradientUnits="userSpaceOnUse">
        <stop offset=".699" stop-color="#bfa055" stop-opacity="0"></stop>
        <stop offset="1" stop-color="#bfa055"></stop>
    </radialGradient>
    <path
        d="M11.56 81.33S21.1 68.02 23.95 60.2c.58-1.58 2.44-22.49 1.55-25.16c-1.66-5.02-3.74-11.63-8.5-8.04C7.99 35.55 4.49 45.1 4.07 53.75c-.74 15.21 4.82 24.44 7.49 27.58z"
        fill="url(#IconifyId17ecdb2904d178eab6049)"></path>
    <path
        d="M101.77 67.91H26.34c-8.13 0-14.79 6.4-14.79 14.23s6.65 14.23 14.79 14.23h75.43c8.13 0 14.79-6.4 14.79-14.23s-6.66-14.23-14.79-14.23z"
        fill="#c48e6a"></path>
    <g>
        <path
            d="M63.77 9.21c-23.86 0-45.96 25.07-45.96 61.14c0 35.88 22.77 53.62 45.96 53.62s45.96-17.74 45.96-53.62c0-36.07-22.1-61.14-45.96-61.14z"
            fill="#e0bb95"></path>
    </g>
    <path
        d="M68.89 87.13a1.63 1.63 0 0 0-.42-.11h-9.3c-.14.02-.28.05-.42.11c-.84.34-1.31 1.21-.91 2.14s2.25 3.54 5.98 3.54c3.73 0 5.58-2.61 5.98-3.54c.4-.93-.07-1.8-.91-2.14z"
        fill="#c48e6a"></path>
    <g fill="#ab872f">
        <path
            d="M32.51 68.42s.17-.25.5-.67c.09-.1.16-.21.28-.34c.14-.14.31-.28.48-.44c.18-.15.37-.32.57-.5c.2-.17.41-.3.63-.46c.22-.15.45-.31.7-.46c.26-.14.52-.28.79-.42c.27-.15.56-.28.87-.39c.3-.12.61-.24.93-.35c.65-.18 1.32-.38 2.02-.47c.7-.14 1.42-.15 2.14-.18c.72.04 1.44.05 2.14.18c.7.09 1.38.29 2.02.47c.32.11.63.23.93.35c.3.12.59.24.87.39c.27.14.54.29.79.42c.25.15.48.31.7.46c.22.16.43.29.62.45c.18.16.36.32.52.46c.16.15.31.28.44.41c.13.14.24.27.34.39c.4.47.61.75.61.75c.67.93.46 2.22-.47 2.88c-.56.41-1.26.49-1.88.28l-.38-.13s-.25-.09-.65-.26c-.1-.04-.2-.1-.32-.14c-.13-.04-.25-.09-.39-.14c-.27-.11-.58-.22-.92-.32c-.17-.05-.34-.12-.52-.17c-.19-.04-.37-.09-.57-.14c-.1-.03-.19-.05-.29-.08c-.1-.02-.2-.04-.31-.06c-.21-.03-.41-.09-.62-.13c-.43-.05-.86-.14-1.31-.16c-.45-.06-.9-.04-1.36-.07c-.46.03-.91.01-1.36.07c-.45.02-.88.11-1.31.16c-.21.04-.41.1-.62.13l-.31.06c-.1.03-.19.05-.29.08c-.19.05-.38.1-.57.14c-.18.05-.35.12-.52.17c-.17.05-.33.1-.48.15c-.13.05-.26.09-.38.14c-.12.04-.24.08-.36.11c-.13.05-.27.13-.38.19c-.47.24-.75.36-.75.36c-1.08.45-2.33-.06-2.78-1.15a2.18 2.18 0 0 1 .21-2.02z"></path>
        <path
            d="M92.55 71.58s-.28-.12-.75-.36c-.11-.06-.25-.14-.38-.19c-.12-.03-.23-.07-.36-.11c-.12-.04-.25-.09-.38-.14c-.15-.05-.32-.1-.48-.15c-.17-.05-.34-.12-.52-.17c-.18-.05-.37-.1-.57-.14c-.1-.03-.19-.05-.29-.08c-.1-.02-.2-.04-.31-.06c-.21-.03-.41-.09-.62-.13c-.43-.05-.86-.14-1.31-.16c-.45-.06-.9-.04-1.36-.07c-.46.03-.91.01-1.36.07c-.45.02-.88.11-1.31.16c-.21.04-.41.1-.62.13l-.31.06c-.1.03-.19.05-.29.08c-.19.05-.38.1-.57.14c-.18.05-.35.12-.52.17c-.34.1-.65.21-.92.32c-.14.05-.26.11-.39.14c-.12.05-.22.1-.32.14c-.39.18-.64.26-.64.26l-.37.13a2.06 2.06 0 0 1-2.63-1.27c-.23-.66-.1-1.36.27-1.89c0 0 .2-.28.61-.75c.1-.12.21-.25.34-.39c.13-.13.29-.26.44-.41c.16-.14.34-.3.52-.46c.19-.16.4-.29.62-.45c.22-.15.45-.31.7-.46c.26-.14.52-.28.79-.42c.27-.15.56-.28.86-.39c.3-.12.61-.24.93-.35c.65-.18 1.32-.38 2.02-.47c.7-.14 1.42-.15 2.14-.18c.72.04 1.44.04 2.14.18c.7.08 1.38.29 2.02.47c.32.11.63.23.93.35c.3.12.59.24.86.39c.27.14.54.29.79.42c.25.15.48.31.7.46c.22.16.43.29.63.46c.2.17.39.34.57.5c.17.16.34.3.48.44c.12.13.2.24.28.34c.33.41.5.67.5.67c.66.97.41 2.29-.56 2.95c-.6.43-1.37.48-2 .22z"></path>
    </g>
    <g fill="#5d4037">
        <ellipse cx="41.96" cy="80.34" rx="6.48" ry="6.71"></ellipse>
        <ellipse cx="85.68" cy="80.34" rx="6.48" ry="6.71"></ellipse>
    </g>
    <path
        d="M75.01 97.97c-4.19 2.49-17.91 2.49-22.1 0c-2.4-1.43-4.86.76-3.86 2.94c.98 2.15 8.47 7.13 14.95 7.13s13.87-4.98 14.85-7.13c.99-2.19-1.43-4.37-3.84-2.94z"
        fill="#6d4c41"></path>
    <g>
        <path
            d="M64 7C44.19 7 28.92 18.32 21.7 36.48c-2.9 7.29-5.26 15.05-5.26 22.89C16.44 73 19 85 19 85c7-9 5-18 5-18c9-12 35.82-21.64 36-22l-5.4 10.81c-.68 1.35.48 2.97 1.95 2.65C82 53 93.18 43.19 93.18 43.19S94 58 105 68c0 0 0 8 3 17c0 0 5-18 1.58-37.28c-2.25-12.66-9.52-24.83-20.04-32.67C82.15 9.54 73.15 7 64 7z"
            fill="#ab872f"></path>
        <radialGradient
            id="IconifyId17ecdb2904d178eab6050"
            cx="172.221"
            cy="61.128"
            r="46.468"
            gradientTransform="matrix(-.9378 -.3944 -.2182 .5285 236.462 66.99)"
            gradientUnits="userSpaceOnUse">
            <stop offset=".699" stop-color="#bfa055" stop-opacity="0"></stop>
            <stop offset="1" stop-color="#bfa055"></stop>
        </radialGradient>
        <path
            d="M81.19 5.93c9.95 3.28 16.06 9.58 17.95 17.49c.56 2.32.15 23.66-41.22-.97c-15.39-9.16-11.18-14.9-9.38-15.55c7.04-2.53 19.81-5.2 32.65-.97z"
            fill="url(#IconifyId17ecdb2904d178eab6050)"></path>
        <radialGradient
            id="IconifyId17ecdb2904d178eab6051"
            cx="161.283"
            cy="92.173"
            r="48.129"
            gradientTransform="matrix(.5235 .852 .6321 -.3884 -48.207 -37.265)"
            gradientUnits="userSpaceOnUse">
            <stop offset=".699" stop-color="#bfa055" stop-opacity="0"></stop>
            <stop offset="1" stop-color="#bfa055"></stop>
        </radialGradient>
        <path
            d="M116.24 79.21s14.72-20.84 3.79-44.33C112.9 19.58 98.42 19.93 98 20c0 0 1.57 3.84 1.01 6.77c-.95 5-5.11 6.44-5.11 6.44c16.38 9.98 10.8 35.53 22.34 46z"
            fill="url(#IconifyId17ecdb2904d178eab6051)"></path>
        <radialGradient
            id="IconifyId17ecdb2904d178eab6052"
            cx="183.604"
            cy="211.352"
            r="8.742"
            gradientTransform="matrix(.9968 .0796 .0943 -1.182 -185.896 247.333)"
            gradientUnits="userSpaceOnUse">
            <stop offset=".58" stop-color="#bfa055"></stop>
            <stop offset="1" stop-color="#bfa055" stop-opacity="0"></stop>
        </radialGradient>
        <path
            d="M27.28 15.27c-10.55 3.77-15.23.81-15.26.73c-.32 1.23.37 8.7 6.9 6.37c2.81-1.02 8.33-6.75 8.36-7.1z"
            fill="url(#IconifyId17ecdb2904d178eab6052)"></path>
        <radialGradient
            id="IconifyId17ecdb2904d178eab6053"
            cx="340.305"
            cy="96.032"
            r="7.22"
            gradientTransform="matrix(.6211 -.7838 -1.2436 -.9854 -70.425 384.055)"
            gradientUnits="userSpaceOnUse">
            <stop offset=".702" stop-color="#bfa055" stop-opacity="0"></stop>
            <stop offset="1" stop-color="#bfa055"></stop>
        </radialGradient>
        <path
            d="M11.98 15.99c0 .01-.01.03-.01.06c-.03 1.17-.01 10.13 5.03 10.95l7.61-3.35c-9.49-.19-12.62-7.69-12.62-7.69s-.01.02-.01.03z"
            fill="url(#IconifyId17ecdb2904d178eab6053)"></path>
    </g>
</svg>
