import { Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Permission, ProductTitle, Reference, RolesService } from '@luis/common/shared';
import { map, Observable } from 'rxjs';
import { TransactionListItem, TransactionTableStore } from '../../services/table-store.service';
import { TransactionTableService } from '../../services/transaction-table.service';
import { TransactionService } from '../../services/transaction.service';

@Component({
    selector: 'core-transaction-full-table',
    templateUrl: './full-table.component.html',
    providers: [TransactionService],
})
export class TransactionFullTableComponent implements OnInit {
    private readonly transactionTableService = inject(TransactionTableService);
    private readonly transactionSmallTableStore = inject(TransactionTableStore);
    private readonly roleService = inject(RolesService);
    private readonly destroyRef = inject(DestroyRef);

    @Input() public set ref(value: Reference) {
        this.reference = value;
        this.transactionTableService.setReference({
            refId: value.refId,
            refName: value.refName,
        } as Reference);
        this.loadTransactions(value.refId);
    }

    @Input() public partnerId: string;

    first = 0;
    totalRecords = 0;
    allTransactionsSelected = false;
    isLoading = false;
    reference: Reference;
    baseTransactions = signal<TransactionListItem[]>([]);
    transactions: TransactionListItem[] = [];

    transactionTypes = [
        { value: 'Credit', name: 'Credit' },
        { value: 'Debit', name: 'Debit' },
        { value: 'Reversal', name: 'Reversal' },
    ];

    paymentStates = [
        { value: 'Open', name: 'Open' },
        { value: 'Pending', name: 'Pending' },
        { value: 'Paid', name: 'Paid' },
        { value: 'Failed', name: 'Failed' },
        { value: 'Locked', name: 'Locked' },
    ];

    public ngOnInit() {
        this.transactionTableService.isLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isLoading) => {
            this.isLoading = isLoading;
        });

        this.transactionTableService.reference$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((reference) => {
            this.reference = reference;
        });

        this.transactionSmallTableStore.transactions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((transactions) => {
                this.baseTransactions.set(this.sort(transactions));
                this.transactions = this.sort(transactions);
                this.totalRecords = transactions.length;
            });
    }

    filterByTransactionType(event: any) {
        this.transactions = this.baseTransactions().filter(
            (q) => q.transactionType.toString().toLowerCase() === event?.value.toLowerCase(),
        );
    }
    filterByPaymentState(event: any) {
        this.transactions = this.baseTransactions().filter(
            (q) => q.paymentState.toString().toLowerCase() === event.value.toLowerCase(),
        );
    }

    sort(transactions: TransactionListItem[]) {
        return transactions.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    }

    loadTransactions(contractId: string) {
        this.isLoading = true;
        this.transactionTableService.loadAllByContractId(contractId).subscribe((response) => {
            const sortedTransactions = this.sortTransactions(response.records);
            this.transactionSmallTableStore.initTransaction(sortedTransactions);
            this.transactionTableService.setLoading(false);
        });
    }

    sortTransactions(transactions: TransactionListItem[]): TransactionListItem[] {
        let sortIndex = transactions.length + 1;
        return transactions
            .sort((a, b) => (a.start > b.start === false ? 1 : -1))
            .map((transaction) => {
                sortIndex = sortIndex - 1;
                return {
                    ...transaction,
                    sortIndex: sortIndex,
                };
            });
    }

    checkHasPermission(permission: Permission, partner: string): Observable<boolean> {
        return this.roleService.isGranted(permission, ProductTitle.TRAVEL_INSURANCE, partner).pipe(
            map((isGranted) => {
                return isGranted;
            }),
        );
    }
}
