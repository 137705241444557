import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { PrimeNgFilter } from '../../models/filter.model';
import { Sorting } from '../../models/sorting.model';
import * as fromTableActions from './table.actions';
import * as fromTableSelectors from './table.selectors';

@Injectable({
    providedIn: 'root',
})
export class TableFacade {
    constructor(private store: Store) {}

    setFilter(key: string, filters: PrimeNgFilter) {
        if (!key) return;
        const record: Record<string, PrimeNgFilter> = {};
        record[key] = _.cloneDeep(filters);
        this.store.dispatch(fromTableActions.setFilter({ filters: record }));
    }

    clearFilter(key: string) {
        if (!key) return;
        this.setFilter(key, null);
    }

    setKeywordSearch(keyword: string) {
        this.store.dispatch(fromTableActions.setKeyWord({ keyWord: keyword }));
    }

    getKeyWord(): Observable<string> {
        return this.store.select(fromTableSelectors.selectKeyWord);
    }

    selectFilter(key: string): Observable<PrimeNgFilter> {
        return this.store?.select(fromTableSelectors.selectFilters(key));
    }

    setSorting(key: string, sorting: Sorting) {
        if (!key) return;
        const record: Record<string, Sorting> = {};
        record[key] = _.cloneDeep(sorting);
        this.store.dispatch(fromTableActions.setSorting({ sorting: record }));
    }

    clearSorting(key: string) {
        if (!key) return;
        this.setSorting(key, null);
    }

    selectSorting(key: string): Observable<Sorting> {
        return this.store?.select(fromTableSelectors.selectSorting(key));
    }
}
