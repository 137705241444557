<p-table styleClass="p-datatable-sm mb-2 z-8">
    <ng-template pTemplate="header">
        <tr>
            <th
                class="flex flex-row justify-content-between algin-items-center h-3rem surface-ground border-1 border-50 border-round">
                <span class="align-items-center flex flex-row" #test>
                    <span class="mr-4" *ngIf="index">{{ index }}.</span>
                    {{ field | translate }}
                </span>
                <span class="align-items-center flex flex-row">
                    <ng-container *ngIf="isSortable">
                        <i [class]="sortIcon + ' ' + sortColor + ' mr-1'" (click)="sort()"></i>
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="fieldFilterTemplate"> </ng-container>
                </span>
            </th>
        </tr>
    </ng-template>
</p-table>
