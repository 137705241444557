<nuis-grid
    class="flex-1 overflow-hidden"
    [isLoading]="isLoading()"
    [items]="sortedNotifications()"
    [total]="total()"
    [sortField]="sortField()"
    [sortOrder]="sortOrder()"
    [columns]="gridColumns()"
    (stateChange)="stateChange($event)"
    (selectRow)="selectRow($event)" />
