import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, input, output, TrackByFunction, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LuxonDatePipe, LuxonDateTimePipe, MoneyPipe, Paths, StatusBadgeComponent } from '@nuis/common';
import { CheckboxModule } from 'primeng/checkbox';
import { Table, TableModule } from 'primeng/table';
import { GridColumn, GridState, RowSelection } from './models';

@Component({
    selector: 'nuis-grid',
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        RouterModule,
        FormsModule,
        TranslateModule,
        TableModule,
        CheckboxModule,
        LuxonDatePipe,
        LuxonDateTimePipe,
        MoneyPipe,
        StatusBadgeComponent,
    ],
    templateUrl: './grid.component.html',
})
export class GridComponent<T> {
    public isLoading = input.required<boolean>();
    public items = input.required<T[]>();
    public total = input.required<number | null>();
    public sortField = input.required<Paths<T> | null>();
    public sortOrder = input.required<number | null>();
    public columns = input.required<GridColumn<T>[]>();
    public stateChange = output<GridState<T>>();
    public selectRow = output<RowSelection<T>>();

    protected table = viewChild.required(Table);

    protected handleStateChange() {
        const table = this.table();

        // NOTE: Somehow primeng calls this multiple times with the same values
        //       So we need to check if the values actually changed
        if (table.sortField === this.sortField() && table.sortOrder === this.sortOrder()) {
            return;
        }

        this.stateChange.emit({
            sortField: (table.sortField as Paths<T>) ?? null,
            sortOrder: table.sortOrder ?? null,
        });
    }

    protected handleSelectRow(item: T, event: MouseEvent) {
        this.selectRow.emit({
            item: item,
            ctrlKey: event.ctrlKey,
        });
    }

    protected trackByIndex: TrackByFunction<T> = (index: number): number => index;
}
