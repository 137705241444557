export interface PayoutInformation {
    details?: PayoutInformationDetails;
    type?: PayoutAccountType;
}

export interface PayoutInformationDetails {
    bankAccountholder: string;
    iban: string;
}

export enum PayoutAccountType {
    BANK = 'bank',
}
