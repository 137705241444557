import { Pipe, PipeTransform } from '@angular/core';
import { toCamelCase } from '@luis/common/shared';
import { TransactionType } from '../models/transaction.model';

@Pipe({
    name: 'transactionType',
})
export class TransactionTypePipe implements PipeTransform {
    transform(value: TransactionType): string {
        return `transactionTypes.${toCamelCase(value)}`;
    }
}
