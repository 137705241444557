import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { Transaction } from '../../../core-transaction/models/transaction.model';
import { ReferenceOpenReversalsTableRecord, SELECTIONSTATE } from '../../models/reference-open-reversal';
import {
    ReferenceTransactionReversalApprovementButtonService,
    TransactionButton,
} from '../../services/transaction-reversal-approvement-button.service';

@Component({
    selector: 'core-transaction-reversal-approvement-button-small',
    templateUrl: './transaction-reversal-approvement-button-small.component.html',
})
export class TransactionReversalApprovementButtonSmallComponent implements OnInit {
    private readonly transactionReversalApprovementButtonService = inject(
        ReferenceTransactionReversalApprovementButtonService,
    );
    private readonly destroyRef = inject(DestroyRef);

    @Input() openReversalTransaction: Transaction;
    @Input() referenceTransactionRecord: ReferenceOpenReversalsTableRecord;
    @Input() row: number;
    @Input() forceUpdatePending: Subject<string>;
    @Input() forceUpdateApprove: Subject<string>;
    @Input() forceUpdateDeny: Subject<string>;

    //needs to update main button on a transaction button click
    @Output() checkUpdateReferenceButton = new EventEmitter<{
        buttonState: SELECTIONSTATE;
        rowContractId: string;
    }>();

    pendingSelected = true;
    approveSelected: boolean;
    denySelected: boolean;

    public ngOnInit() {
        this.forceUpdatePending.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((rowContractId) => {
            if (rowContractId == this.referenceTransactionRecord?.contractId) {
                this.approveSelected = false;
                this.denySelected = false;
            }
        });

        this.forceUpdateApprove.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((rowContractId) => {
            if (rowContractId == this.referenceTransactionRecord?.contractId) {
                this.approveSelected = true;
                this.denySelected = false;
            }
        });

        this.forceUpdateDeny.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((rowContractId) => {
            if (rowContractId == this.referenceTransactionRecord?.contractId) {
                this.approveSelected = false;
                this.denySelected = true;
            }
        });

        this.initButtonState(
            this.transactionReversalApprovementButtonService.getTransactionButtonStates(
                this.referenceTransactionRecord.contractId,
            ),
        );
    }

    private initButtonState(transactions: TransactionButton[]) {
        transactions.forEach((transaction) => {
            if (this.openReversalTransaction.id == transaction.transactionId) {
                this.toggleButton(transaction.transactionButtonState);
            }
        });
    }

    toggleButton(buttonState: SELECTIONSTATE) {
        let buttonStateToSave: SELECTIONSTATE = 'PENDING';
        switch (buttonState) {
            case 'APPROVE':
                this.pendingSelected = false;
                this.denySelected = false;
                this.approveSelected = !this.approveSelected;
                this.approveSelected ? (buttonStateToSave = 'APPROVE') : (buttonStateToSave = 'PENDING');
                break;
            case 'DENY':
                this.pendingSelected = false;
                this.denySelected = !this.denySelected;
                this.approveSelected = false;
                this.denySelected ? (buttonStateToSave = 'DENY') : (buttonStateToSave = 'PENDING');
                break;
        }

        this.transactionReversalApprovementButtonService.setTransactionButtonState(
            this.referenceTransactionRecord?.contractId,
            this.openReversalTransaction.id,
            buttonStateToSave,
        );
        this.checkUpdateReferenceButton.emit({
            buttonState: buttonStateToSave,
            rowContractId: this.referenceTransactionRecord?.contractId,
        });
    }
}
