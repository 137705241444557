import { EventEmitter } from '@angular/core';
import { TableView } from '../models/table-view.model';

export abstract class TableViewServiceBase {
    private customViews: TableView[] = [];
    selectedViewChanged = new EventEmitter<TableView>();

    private viewLabel: string;
    set selectedView(label: string) {
        localStorage.setItem('last-' + this.data.viewIdentifier, label);
        this.viewLabel = label;
        this.selectedViewChanged.emit(
            this.getViews().find((x) => x.label.toLowerCase() === this.viewLabel.toLowerCase()),
        );
    }

    get customViewCurrentCount(): number {
        return Number.parseInt(localStorage.getItem('customViewCurrentCount')) ?? 2;
    }

    set customViewCurrentCount(value: number) {
        localStorage.setItem('customViewCurrentCount', value.toString());
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    get selectedView(): string {
        const lastView = localStorage.getItem('last-' + this.data.viewIdentifier);
        return lastView !== '' && lastView !== 'undefined' && lastView !== undefined && lastView !== null
            ? lastView
            : 'standardView';
    }

    constructor(
        private data: {
            viewIdentifier: string;
            allFields: string[];
            sortFields: string[];
            standardViews: TableView[];
        },
    ) {
        this.restore();
    }

    restore() {
        const cachedViews = localStorage.getItem(this.data.viewIdentifier);
        this.customViews = JSON.parse(cachedViews || '[]');

        const lastCustomView = localStorage.getItem('last-' + this.data.viewIdentifier);

        if (lastCustomView && lastCustomView != '') {
            this.selectedView = lastCustomView;
        } else {
            this.selectedView = this.data.standardViews[0].label;
        }
    }

    getAllFields(): string[] {
        return [...this.data.allFields];
    }

    getSortFields(): string[] {
        return [...this.data.sortFields];
    }

    addView(view: TableView): TableView[] {
        const currentViewIndex = this.customViews.findIndex((q) => q.id.toString() === view.id.toString());

        if (currentViewIndex < 0) {
            this.customViews.push({ ...view, id: this.customViews.length });
        } else {
            this.customViews[currentViewIndex] = view;
        }

        this.selectedView = view.label;
        localStorage.setItem(this.data.viewIdentifier, JSON.stringify(this.customViews));

        return this.getViews();
    }

    getViews(): TableView[] {
        const views: TableView[] = [
            ...this.customViews.map((q) => {
                return { ...q, isStandardView: false };
            }),
            ...this.data.standardViews,
        ];

        this.customViewCurrentCount = (this.data.standardViews?.length ?? 2) + (this.customViews?.length - 1);

        const selected = views.splice(
            views.findIndex((v) => v.label === this.selectedView),
            1,
        );

        return [...selected, ...views];
    }

    removeView(view: TableView) {
        this.customViews = this.customViews.filter((q) => q.id !== view.id);
        localStorage.setItem(this.data.viewIdentifier, JSON.stringify(this.customViews));
        if (this.customViews && this.customViews.length > 0) {
            this.selectedView = this.customViews[this.customViews.length - 1]?.label;
        } else {
            this.selectedView = this.data.standardViews[this.customViews.length - 1]?.label;
        }
    }
}
