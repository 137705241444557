import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { CommonStateService, ProductCategory, ProductSubMenuTitle, ProductTitle } from '@luis/common/shared';
import { TableFacade } from '@luis/common/table';
import { BreadcrumbItem } from '../../navbar-breadcrumb/models/breadcrumb-item.model';
import { NavbarBreadcrumbService } from '../../navbar-breadcrumb/navbar.breadcrumb.service';
import { SidebarItem } from '../models/sidebar-item.model';
import { SidebarService } from '../sidebar.service';

@Component({
    selector: 'nav-sidebar-stacked',
    templateUrl: './stacked.component.html',
})
export class StackedComponent implements OnInit {
    private readonly sidebarService = inject(SidebarService);
    private readonly breadcrumbService = inject(NavbarBreadcrumbService);
    private readonly router = inject(Router);
    private readonly tableFacade = inject(TableFacade);
    private readonly commonStateService = inject(CommonStateService);
    private readonly destroyRef = inject(DestroyRef);

    productsMenu: SidebarItem[];
    baseMenu: SidebarItem[];

    selectedMenuItemId: string;
    selectedSubMenuItemId: string;
    selectedProductTitle: ProductTitle;

    public ngOnInit() {
        this.sidebarService.setProductsMenu('travel');

        this.sidebarService.baseMenu$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((menu) => (this.baseMenu = menu));

        this.sidebarService.productMenu$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((menu) => {
            if (!this.productsMenu) {
                this.productsMenu = menu;
                return;
            }

            if (
                this.productsMenu.length === menu.length &&
                menu.every((m) => this.productsMenu.filter((pm) => pm.id === m.id).length > 0)
            )
                return;

            this.productsMenu = menu;
        });

        this.sidebarService.selectProductNavigationItem$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((selectedNavigationItemId) => {
                this.selectedMenuItemId = selectedNavigationItemId;
            });

        this.sidebarService.selectProductSubNavigationItem$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((selectProductSubNavigationItem) => {
                this.selectedSubMenuItemId = selectProductSubNavigationItem;
            });

        this.sidebarService.selectedProductTitle$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((selectedProductTitle) => {
                this.selectedProductTitle = selectedProductTitle;
            });

        this.baseMenu = this.sidebarService.getBaseSidebarMenu();
    }

    changeComponent(
        routingKey: string,
        translationKey: string,
        hierarchy: number,
        productId: string,
        productCategory: ProductCategory,
        product?: SidebarItem,
        subItemTitle?: ProductSubMenuTitle,
    ) {
        if (productCategory) this.commonStateService.setSelectedCategory(productCategory);

        this.tableFacade.setKeywordSearch('');
        const breadcrumb: BreadcrumbItem = {
            name: routingKey,
            displayName: translationKey,
            componentName: routingKey,
            position: 1,
            hierarchy: hierarchy,
        };

        if (productId) this.sidebarService.setSelectNavigationItem(productId);
        if (subItemTitle) this.sidebarService.setSelectProductSubNavigationItem(subItemTitle);

        if (product) {
            this.sidebarService.setProductTitle(product.product);
        } else {
            this.sidebarService.setProductTitle(undefined);
        }

        this.breadcrumbService.setBreadcrumbs(breadcrumb);

        if (product) {
            this.router.navigate(['/' + product.routingKey + '/' + routingKey]);
        } else {
            this.router.navigate(['/' + routingKey]);
        }
    }
}
