<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>

@if (displayViewEditor) {
    <p-dialog
        [header]="'viewEditor.title' | translate"
        [(visible)]="displayViewEditor"
        showEffect="fade"
        [style]="{ width: '70%', height: '90%', position: 'absolute' }">
        <div class="p-2 pl-3 pr-3 m-0 w-full fill-height surface-ground">
            <p class="line-height-3 m-0">
                <table-view-editor
                    [allColumnFields]="allColumnFields"
                    [currentViewedColumnFields]="currentViewedColumnFields"
                    [editViewName]="editViewName"
                    [filters]="viewEditorFilters"
                    [viewId]="currentViewIndex"
                    (viewSaved)="saveView($event)">
                    <ng-template #filterTemplate let-field>
                        <ng-container
                            [ngTemplateOutlet]="viewEditor"
                            [ngTemplateOutletContext]="{
                                $implicit: field,
                                allowedPartners: allowedPartners$ | async,
                            }">
                        </ng-container>
                    </ng-template>
                </table-view-editor>
            </p>
        </div>
    </p-dialog>

    <div class="blurred-layer p-0 m-0" (click)="displayViewEditor = false"></div>
}

<ng-container *ngIf="sorting.asObservable() | async as sorting">
    <p-table
        #table
        [value]="records"
        scrollHeight="flex"
        scrollDirection="both"
        [resizableColumns]="true"
        [paginator]="true"
        [rowsPerPageOptions]="rowsPerPage"
        [rows]="rows"
        (rowsChange)="setRows($event)"
        responsiveLayout="scroll"
        [loading]="isLoading"
        [lazy]="true"
        [scrollable]="true"
        (onLazyLoad)="lazyLoad($event)"
        [sortOrder]="sorting.sortOrder"
        [sortField]="sorting.sortField"
        (onSort)="setSorting($event)"
        [totalRecords]="totalRecords"
        styleClass="p-datatable-sm p-0 m-0 z-1"
        [showCurrentPageReport]="false"
        paginatorPosition="bottom">
        <ng-template pTemplate="caption" *ngIf="showCaption">
            <div class="flex flex-row justify-content-between justify-content-between align-items-center p-0 m-0">
                <span class="ml-5" *ngIf="displayViewSelection">
                    <p-dropdown
                        [options]="views"
                        [style]="{ minWidth: '100%' }"
                        [(ngModel)]="selectedView"
                        (ngModelChange)="changeView($event)"
                        optionLabel="label">
                        <ng-template pTemplate="selectedItem">
                            <div>
                                {{ views[selectedViewIndex]?.label | translate }}
                            </div>
                        </ng-template>
                        <ng-template pTemplate="empty">
                            <div>
                                {{ 'choose' | translate }}
                            </div>
                        </ng-template>
                        <ng-template let-view pTemplate="item">
                            <div class="flex flex-row align-middle justify-center w-full">
                                <div class="align-self-center flex-grow-1 z-0">
                                    {{ view.label | translate }}
                                </div>
                                @if (view.isStandardView === false) {
                                    <div class="flex flex-row align-middle justify-center flex-initial">
                                        <div>
                                            <button
                                                pButton
                                                type="button"
                                                icon="pi pi-pencil"
                                                class="p-button-text button-sm text-xl flex-initial p-0 m-0"
                                                style="z-index: 100"
                                                pTooltip="{{ 'edit' | tooltip | translate }}"
                                                tooltipPosition="left"
                                                (click)="editViewEditor(view)"></button>
                                        </div>
                                        <div>
                                            <button
                                                pButton
                                                type="button"
                                                icon="pi pi-trash"
                                                style="z-index: 100"
                                                class="p-button-text p-button-danger flex-initial p-0 m-0"
                                                pTooltip="{{ 'delete' | tooltip | translate }}"
                                                tooltipPosition="left"
                                                (click)="removeView(view)"></button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button
                                pRipple
                                pButton
                                class="p-button-text p-button-success w-full"
                                pTooltip="{{ 'add' | tooltip | translate }}"
                                tooltipPosition="bottom"
                                icon="pi pi-plus"
                                (click)="addNewView()"></button>
                        </ng-template>
                    </p-dropdown>
                </span>
                <span class="flex flex-row flex-grow-1 justify-content-end align-items-center">
                    <button
                        pRipple
                        pButton
                        class="p-button-text text-xl mr-1"
                        pTooltip="{{ 'clearFilters' | tooltip | translate }}"
                        tooltipPosition="left"
                        icon="pi pi-filter-slash"
                        (click)="clear()"></button>
                    <span class="p-input-icon-left mr-5" *ngIf="showSearchbar">
                        <i class="pi pi-search"></i>
                        <input
                            pInputText
                            type="text"
                            [(ngModel)]="searchKeyword"
                            (ngModelChange)="searchKeywordChanged.next($event)"
                            placeholder="{{ 'search' | translate }}" />
                    </span>
                </span>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr id="table-head" [class]="'m-0 p-0 ' + (isLoading ? ' table-head-loading' : '')">
                <th id="empty"></th>
                <th
                    id="head"
                    *ngFor="let field of views[selectedViewIndex]?.fields"
                    [ngSwitch]="field"
                    [pSortableColumn]="currentViewedColumnFields.includes(field) ? field : ''"
                    class="m-0">
                    <span>
                        {{ field | translate }}
                        <p-sortIcon *ngIf="currentViewedColumnFields.includes(field)" [field]="field"></p-sortIcon>
                    </span>
                    <ng-container
                        [ngTemplateOutlet]="header"
                        [ngTemplateOutletContext]="{
                            $implicit: field,
                            allowedPartners: allowedPartners$ | async,
                        }">
                    </ng-container>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record let-i="rowIndex">
            <tr
                class="m-0 p-0 cursor-pointer border-round text-700 hover:surface-200 transition-duration-150 transition-colors">
                <td class="font-medium">{{ i + 1 }}.</td>
                <td
                    *ngFor="let field of views[selectedViewIndex]?.fields"
                    [ngSwitch]="field"
                    (click)="selectRow(record, field, $event)"
                    (dblclick)="selectRow(record, field, $event)">
                    <ng-container
                        [ngTemplateOutlet]="body"
                        [ngTemplateOutletContext]="{ field: field, record: record }">
                    </ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
            <tr class="text-700">
                <td colspan="20">
                    <i class="pi pi-info-circle text-xl mr-2"></i>
                    {{ 'noResultsFound' | translate }}
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="paginatorright" let-state>
            <div class="z-50">
                {{
                    'pagination'
                        | translate
                            : {
                                  first: state.first,
                                  last:
                                      state.totalRecords && rows != null
                                          ? totalRecords < rows
                                              ? totalRecords
                                              : state.first + state.rows
                                          : 0,
                                  totalRecords: state.totalRecords ?? 0,
                              }
                }}
            </div>
        </ng-template>
    </p-table>
</ng-container>
