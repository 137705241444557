import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { StatusColor } from '../../enums';

@Component({
    selector: 'nuis-status-badge',
    standalone: true,
    imports: [NgClass],
    template: `
        <div class="w-min border-1 border-round-3xl py-1 px-2 text-sm" [ngClass]="statusColorClasses[color()]">
            {{ label() }}
        </div>
    `,
})
export class StatusBadgeComponent {
    public label = input.required<string | null>();
    public color = input.required<StatusColor>();

    protected statusColorClasses: Record<StatusColor, string> = {
        green: 'bg-green-100 border-green-700 text-green-700',
        yellow: 'bg-yellow-100 border-yellow-700 text-yellow-700',
        orange: 'bg-orange-100 border-orange-700 text-orange-700',
        red: 'bg-red-100 border-red-700 text-red-700',
        gray: 'bg-gray-100 border-gray-700 text-gray-700',
        'dark-gray': 'bg-bluegray-100 border-bluegray-700 text-bluegray-700',
    };
}
