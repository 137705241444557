import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { StepsModule } from 'primeng/steps';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

export const PRIMENG_MODULES = [
    CardModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    TabMenuModule,
    ScrollPanelModule,
    CalendarModule,
    ChipModule,
    ScrollTopModule,
    AccordionModule,
    ButtonModule,
    TimelineModule,
    TableModule,
    CascadeSelectModule,
    InputTextareaModule,
    CheckboxModule,
    ConfirmDialogModule,
    ProgressBarModule,
    MultiSelectModule,
    SkeletonModule,
    DialogModule,
    DynamicDialogModule,
    DropdownModule,
    ToastModule,
    StepsModule,
    InputNumberModule,
    MenuModule,
    DividerModule,
    DropdownModule,
    RadioButtonModule,
    SlideMenuModule,
    BreadcrumbModule,
    StyleClassModule,
    RippleModule,
    ProgressSpinnerModule,
    SelectButtonModule,
    OverlayPanelModule,
    AutoCompleteModule,
    ChipsModule,
    FieldsetModule,
    TriStateCheckboxModule,
    BadgeModule,
    TabViewModule,
];
