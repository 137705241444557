import { Pipe, PipeTransform } from '@angular/core';
import { PaymentState, TRANSACTION_REFERAL_STATUS, Transaction, TransactionType } from '../models/transaction.model';

@Pipe({
    name: 'disableTransactionCheckbox',
})
export class TransactionCheckboxClickablePipe implements PipeTransform {
    //checkable if
    //state is paid && type is Debit and has no linkedTransaction and is not already reversed
    //needs to be inverted because it is disabled
    transform(transaction: Transaction) {
        if (this.#isAlreadyReversed(transaction.transactionType)) return true;
        if (this.#isAlreadyRequested(transaction.reversalStatus)) return true;

        return !this.#isPaid(transaction.paymentState);
    }

    #isAlreadyRequested(reversalStatus: TRANSACTION_REFERAL_STATUS) {
        switch (reversalStatus) {
            case 'Requested':
            case 'Approved':
            case 'Denied':
            case 'Finished':
                return true;
            default:
                return false;
        }
    }

    #isPaid(paymentState: PaymentState): boolean {
        switch (paymentState) {
            case 'Paid':
                return true;
        }

        return false;
    }

    #isAlreadyReversed(transactionType: TransactionType): boolean {
        if (transactionType === 'Reversal') return true;
        else return false;
    }
}
