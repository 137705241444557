import { Injectable } from '@angular/core';
import { State } from '../models/state.model';
import { Observable, shareReplay } from 'rxjs';

interface ContractStatusState {
    openClaims?: number;
    closedClaims?: number;
    openComplaints?: number;
    closedComplaints?: number;
    linkedContracts?: number;
}

const initialState: ContractStatusState = {
    closedClaims: null,
    closedComplaints: null,
    linkedContracts: null,
    openClaims: null,
    openComplaints: null,
};

@Injectable({
    providedIn: 'root',
})
export class ContractStatusService extends State<ContractStatusState> {
    openClaims$: Observable<number> = this.select((state) => state.openClaims).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    closedClaims$: Observable<number> = this.select((state) => state.closedClaims).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    openComplaints$: Observable<number> = this.select((state) => state.openComplaints).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    closedComplaints$: Observable<number> = this.select((state) => state.closedComplaints).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    linkedContracts$: Observable<number> = this.select((state) => state.linkedContracts).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor() {
        super(initialState);
    }

    setOpenClaims(value: number) {
        this.setState({ ...this.state, openClaims: value });
    }

    setClosedClaims(value: number) {
        this.setState({ ...this.state, closedClaims: value });
    }

    setOpenComplaints(value: number) {
        this.setState({ ...this.state, openComplaints: value });
    }

    setClosedComplaints(value: number) {
        this.setState({ ...this.state, closedComplaints: value });
    }

    setLinkedContractsCount(value: number) {
        this.setState({ ...this.state, linkedContracts: value });
    }
}
