import { Injectable, inject } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { ProductCategory, SubProductCategory } from '../models/product-category.model';
import { State } from '../models/state.model';
import { Configuration } from '../models/configuration.model';

export interface CommonStateModel {
    selectedProductCategory: ProductCategory;
    selectedSubProductCategory?: SubProductCategory;
    partnerIds: string[];
    selectedProductContractTypeName: string;
}

const defaultCategory: CommonStateModel = {
    selectedProductCategory: ProductCategory.TRAVEL_INSURANCE,
    selectedSubProductCategory: null,
    partnerIds: [],
    selectedProductContractTypeName: '',
};

@Injectable({
    providedIn: 'root',
})
export class CommonStateService extends State<CommonStateModel> {
    private config = inject(Configuration);

    productCategory$: Observable<ProductCategory> = this.select((state) => state.selectedProductCategory).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    subProductCategory$: Observable<SubProductCategory> = this.select((state) => state.selectedSubProductCategory).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    partnerIds$: Observable<string[]> = this.select((state) => state.partnerIds).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    selectedProductContractTypeName$: Observable<string> = this.select(
        (state) => state.selectedProductContractTypeName,
    ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    constructor() {
        super(defaultCategory);
    }

    setSelectedProductContractTypeName(contractTypeName: string) {
        this.setState({ selectedProductContractTypeName: contractTypeName });
    }

    getSelectedProductContractTypeName(): string {
        return this.state.selectedProductContractTypeName;
    }

    setSelectedCategory(productCategory: ProductCategory) {
        this.setState({ selectedProductCategory: productCategory });
    }

    getSelectedCategory() {
        return this.state.selectedProductCategory;
    }

    setPartnerIds() {
        const partnerIds = this.config.partnerIds;
        this.setState({ partnerIds: [partnerIds.a1_aut, partnerIds.a1_srb, partnerIds.a1_hrv] });
    }

    getPartnerIds(): string[] {
        return this.state.partnerIds;
    }
}
