export function failOver(callback: () => void, retries: number, timeOut = 0) {
    try {
        callback();
    } catch {
        if (retries === 0) return;

        if (timeOut > 0) {
            setTimeout(() => failOver(callback, retries - 1, timeOut), timeOut);
        } else failOver(callback, retries - 1);
    }
}
