import { ProductCategory, State } from '@luis/common/shared';
import {
    ReferenceOpenReversalsTableRecord,
    SELECTIONSTATE,
    TransactionRelated,
} from '../models/reference-open-reversal';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface TransactionButton {
    transactionId: string;
    transactionButtonState: SELECTIONSTATE;
}

export interface ContractTransactionButton {
    contractId: string;
    contractButtonState: SELECTIONSTATE;
    category: ProductCategory;
    transactionButtonStates: TransactionButton[];
}

export interface ContractTransactionButtonState {
    contractTransactionButtonStates: ContractTransactionButton[];
}

export interface UpdatedButtonState {
    hasUpdated: boolean;
}

const initial: ContractTransactionButtonState = {
    contractTransactionButtonStates: [],
};

@Injectable({
    providedIn: 'root',
})
export class ReferenceTransactionReversalApprovementButtonService extends State<ContractTransactionButtonState> {
    contracts$: Observable<ContractTransactionButton[]> = this.select((state) => state.contractTransactionButtonStates);

    hasUpdateableState = new Subject<UpdatedButtonState>();
    hasUpdateableState$ = this.hasUpdateableState.asObservable();

    constructor() {
        super(initial);
    }

    setContract(
        contractId: string,
        buttonState: SELECTIONSTATE,
        category: ProductCategory,
        transactionButtonStates: TransactionButton[],
    ) {
        const contractIndex = this.state.contractTransactionButtonStates.findIndex((q) => q?.contractId === contractId);

        if (contractIndex >= 0) {
            this.state.contractTransactionButtonStates[contractIndex].contractButtonState = buttonState;
            this.state.contractTransactionButtonStates[contractIndex].transactionButtonStates = transactionButtonStates;
            this.setState({
                contractTransactionButtonStates: this.state.contractTransactionButtonStates,
            });
        } else {
            this.setState({
                contractTransactionButtonStates: [
                    ...this.state.contractTransactionButtonStates,
                    {
                        contractId: contractId,
                        contractButtonState: buttonState,
                        category: category,
                        transactionButtonStates: transactionButtonStates,
                    },
                ],
            });
        }

        this.checkStates();
    }

    checkStates() {
        this.hasUpdateableState.next({
            hasUpdated:
                this.state.contractTransactionButtonStates.filter((contract) =>
                    contract.transactionButtonStates.filter((q) => q.transactionButtonState != 'PENDING'),
                ).length > 0,
        });
    }

    getContractButtonStates(): ContractTransactionButton[] {
        return this.state.contractTransactionButtonStates;
    }

    setTransactionButtonState(contractId: string, transactionId: string, transactionButtonState: SELECTIONSTATE) {
        const contractIndex = this.state.contractTransactionButtonStates.findIndex((q) => q?.contractId === contractId);
        if (contractIndex >= 0) {
            const contractButtonStates = this.state.contractTransactionButtonStates;
            const transactions = contractButtonStates[contractIndex].transactionButtonStates;
            const transactionIndex = transactions.findIndex((q) => q.transactionId === transactionId);
            if (transactionIndex >= 0) {
                contractButtonStates[contractIndex].transactionButtonStates[transactionIndex].transactionButtonState =
                    transactionButtonState;
                this.setState({
                    ...this.state,
                    contractTransactionButtonStates: contractButtonStates,
                });
            }
        }

        this.checkStates();
    }

    getTransactionButtonStates(contractId: string): TransactionButton[] {
        const contractIndex = this.state.contractTransactionButtonStates.findIndex((q) => q?.contractId == contractId);

        if (contractIndex >= 0) {
            return this.state.contractTransactionButtonStates[contractIndex].transactionButtonStates;
        }
        return [];
    }

    init(openReversalTransactions: ReferenceOpenReversalsTableRecord[]) {
        const contractTransactionButtons: ContractTransactionButton[] = openReversalTransactions?.map((transaction) => {
            return {
                category: transaction.category,
                contractButtonState: 'PENDING',
                contractId: transaction?.contractId,
                transactionButtonStates: this.#mapInitButtonStates(transaction.relatedReversalTransactions),
            };
        });
        this.setState({
            contractTransactionButtonStates: contractTransactionButtons,
        });
    }

    #mapInitButtonStates(transactionRelated: TransactionRelated[]): TransactionButton[] {
        const states: TransactionButton[] = [];
        transactionRelated?.map((transaction) => {
            transaction.transactions?.map((transaction2) => {
                states.push({
                    transactionId: transaction2.id,
                    transactionButtonState: 'PENDING',
                });
            });
        });

        return states;
    }

    getByButtonState(buttonState: SELECTIONSTATE) {
        const list: TransactionButton[] = [];
        this.state.contractTransactionButtonStates.forEach((contract) => {
            contract.transactionButtonStates.forEach((transactionButtonState) => {
                if (transactionButtonState.transactionButtonState == buttonState) {
                    list.push(transactionButtonState);
                }
            });
        });

        return list;
    }

    getCountByState(contractId: string, state: SELECTIONSTATE) {
        let count = 0;
        this.contracts$.subscribe((reversals) => {
            const contracts = reversals.filter((contract) => contract?.contractId === contractId);
            if (contracts) {
                contracts.filter((contract) =>
                    contract.transactionButtonStates.filter((transaction) => {
                        if (transaction.transactionButtonState === state) {
                            count++;
                        }
                    }),
                );
            }
        });

        return count;
    }

    setReference(
        contractId: string,
        buttonState: SELECTIONSTATE,
        category: ProductCategory,
        transactionButtonStates: TransactionButton[],
    ) {
        const referenceIndex = this.state.contractTransactionButtonStates.findIndex(
            (q) => q?.contractId === contractId,
        );

        if (referenceIndex >= 0) {
            this.state.contractTransactionButtonStates[referenceIndex].contractButtonState = buttonState;
            this.state.contractTransactionButtonStates[referenceIndex].transactionButtonStates =
                transactionButtonStates;
            this.setState({
                contractTransactionButtonStates: this.state.contractTransactionButtonStates,
            });
        } else {
            this.setState({
                contractTransactionButtonStates: [
                    ...this.state.contractTransactionButtonStates,
                    {
                        contractId: contractId,
                        contractButtonState: buttonState,
                        transactionButtonStates: transactionButtonStates,
                        category: category,
                    },
                ],
            });
        }

        this.checkStates();
    }

    getCategoryByContractId(contractId: string): ProductCategory {
        const index = this.state.contractTransactionButtonStates.findIndex((q) => q?.contractId == contractId);
        if (index) return this.state.contractTransactionButtonStates[index].category;

        return ProductCategory.TRAVEL_INSURANCE;
    }
}
