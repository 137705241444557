import { Injectable } from '@angular/core';
import { LanguageService, State } from '@luis/common/shared';
import { Observable, shareReplay, tap } from 'rxjs';
import { NavbarState } from './models/navbar-state.model';
import { SIDEBAR_LANGUAGES, SidebarLanguage } from './models/sidebar-language.model';
import { SIDEBAR_TYPES, SidebarType } from './models/sidebar-type.model';

const initialState: NavbarState = {
    type: '',
    sidebarTypes: [],
    selectedSidebarType: { name: 'Products-Menu', code: 'PM', type: 'product' },
    languages: [],
    selectedSidebarLanguage: SIDEBAR_LANGUAGES[0],
    selectedTheme: null,
    user: null,
};

@Injectable({
    providedIn: 'root',
})
export class NavbarService extends State<NavbarState> {
    selectedSidebarType$: Observable<SidebarType> = this.select((state) => state.selectedSidebarType).pipe(
        tap((sidebarType: SidebarType) => localStorage.setItem('sidebarType', sidebarType.code)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    selectedSidebarLanguage$: Observable<SidebarLanguage> = this.select((state) => state.selectedSidebarLanguage).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    selectedTheme$: Observable<number> = this.select((state) => state.selectedTheme).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor(private lang: LanguageService) {
        super(initialState);

        this.restoreSidebarType();

        lang.language$.subscribe((lang) => {
            const selectedLanguage = SIDEBAR_LANGUAGES.find((language) => language.code === lang);
            this.setState({
                selectedSidebarLanguage: selectedLanguage,
            });
        });
    }

    private restoreSidebarType() {
        const stored = localStorage.getItem('sidebarType');
        if (stored) {
            this.setSidebarType(stored);
        }
    }

    getSidebarWidth(): number {
        switch (this.state.selectedSidebarType.code) {
            case 'PM':
            case 'ST':
                return 280;
            case 'IC':
                return 113;
            default:
                return 0;
        }
    }

    setSidebarType(code: string) {
        const selectedType = SIDEBAR_TYPES.find((type) => type.code == code);
        this.setState({ selectedSidebarType: selectedType });
    }

    getSelectedSidebarType() {
        return this.state.selectedSidebarType;
    }

    setLanguage(code: string | null) {
        this.lang.setLanguage(code ?? 'de');
    }

    getCurrentLanguage() {
        return this.state.selectedSidebarLanguage;
    }

    setTheme(theme: number) {
        this.setState({ selectedTheme: theme });
    }
}
