import { Pipe, PipeTransform } from '@angular/core';
import { toCamelCase } from '@luis/common/shared';
import { PaymentState } from '../models/transaction.model';

@Pipe({
    name: 'paymentStateStyle',
})
export class PaymentStateStylePipe implements PipeTransform {
    transform(value: PaymentState): string {
        return `payment-state-${toCamelCase(value)}`;
    }
}
