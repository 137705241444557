import { CurrencyPipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'money',
    standalone: true,
})
export class MoneyPipe implements PipeTransform {
    private readonly currencyPipe = inject(CurrencyPipe);

    public transform(value: number | null | undefined, currency: string): string | null {
        return this.currencyPipe.transform(value, currency, 'code', '1.0-3', 'de');
    }
}
