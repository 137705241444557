import { Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, LazyLoadResponse } from '@luis/common/shared';
import { Observable, Subject, map, tap } from 'rxjs';
import { OpenReversalResponse } from '../models/open-reversal-response';
import { ReferenceOpenReversals, ReferenceOpenReversalsTableRecord } from '../models/reference-open-reversal';

@Injectable({
    providedIn: 'root',
})
export class TransactionReversalApprovementTableService {
    baseUrl: string;

    reversals = new Subject<ReferenceOpenReversalsTableRecord[]>();
    reversals$ = this.reversals.asObservable();

    reversalCount = new Subject<number>();
    reversalCount$ = this.reversalCount.asObservable();

    constructor(
        private http: HttpClientAdapter,
        config: Configuration,
    ) {
        this.baseUrl = config.baseUrl + 'Transactions/';
    }

    loadRecords(): Observable<LazyLoadResponse<ReferenceOpenReversalsTableRecord>> {
        const uri = this.baseUrl + 'QueryOpenReversals';

        return this.http.get<OpenReversalResponse>(uri).pipe(
            tap((transactions) => {
                this.reversalCount.next(this.#getCount(transactions.records));
            }),
            map((res) => {
                this.reversalCount.next(this.#getCount(res.records));
                return {
                    records: res.records?.map((travelTransactions) => ({
                        ...travelTransactions,
                        id: travelTransactions.partnerId,
                        allReversalsSelected: false,
                        selectionState: 'PENDING',
                        category: travelTransactions.category,
                        partnerId: travelTransactions.partnerId,
                    })),
                    totalRecords: res.totalRecords,
                };
            }),
        );
    }

    #getCount(travelOpenReversalResponse: ReferenceOpenReversals[]) {
        let count = 0;
        travelOpenReversalResponse.forEach((element) => {
            if (element.relatedReversalTransactions) {
                count = count + element.relatedReversalTransactions?.length;
            }
        });

        return count;
    }
}

export interface TravelReversalDashboarStatistics {
    OpenReversalCount: number;
}
