import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { PrimeNgFilter } from '../../models/filter.model';
import { Sorting } from '../../models/sorting.model';
import * as TableActions from './table.actions';

export const TABLE_FEATURE_KEY = 'table';

export interface State {
    filters: Record<string, PrimeNgFilter>;
    sorting: Record<string, Sorting>;
    keyWord: string;
}

export const initialState: State = {
    filters: {},
    sorting: {},
    keyWord: '',
};

const tableReducer = createReducer(
    initialState,
    immerOn(TableActions.setFilter, (state, { filters }): State => {
        if (state) {
            state.filters = {
                ...state.filters,
                ...filters,
            };
        }
        return state;
    }),
    immerOn(TableActions.setSorting, (state, { sorting }): State => {
        if (state) {
            state.sorting = {
                ...state.sorting,
                ...sorting,
            };
        }
        return state;
    }),
    immerOn(TableActions.setKeyWord, (state, { keyWord }): State => {
        if (state) {
            state.keyWord = keyWord;
        }
        return state;
    }),
);

export function reducer(state: State | undefined, action: Action) {
    return tableReducer(state, action);
}
