import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { catchError, Observable, throwError } from 'rxjs';
import { Configuration } from '../models/configuration.model';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthorizationHttpInterceptor implements HttpInterceptor {
    constructor(
        private config: Configuration,
        private user: UserService,
    ) {}

    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!httpRequest.url.includes(this.config.baseUrl)) return next.handle(httpRequest);
        const request = httpRequest.clone({
            headers: httpRequest.headers
                .set('subscription-key', this.config.subscriptionKey)
                .set('correlationId', Guid.create().toString())
                .set('userPrincipalId', this.user.getUserPrincipal()),
        });

        return next.handle(request ?? httpRequest).pipe(
            catchError((error) => {
                console.error(error);
                return throwError(() => error);
            }),
        );
    }
}
