import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BreadcrumbItem } from '../../navbar-breadcrumb/models/breadcrumb-item.model';
import { NavbarBreadcrumbService } from '../../navbar-breadcrumb/navbar.breadcrumb.service';
import { SidebarItem } from '../models/sidebar-item.model';
import { SidebarService } from '../sidebar.service';

@Component({
    selector: 'nav-sidebar-icons',
    templateUrl: './icons.component.html',
})
export class IconsComponent implements OnInit {
    private readonly sidebarService = inject(SidebarService);
    private readonly breadcrumbService = inject(NavbarBreadcrumbService);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    productsMenu: SidebarItem[];
    baseMenu: SidebarItem[];

    public ngOnInit() {
        this.sidebarService.baseMenu$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((menu) => (this.baseMenu = menu));

        this.sidebarService.productMenu$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((menu) => (this.productsMenu = menu));

        this.baseMenu = this.sidebarService.getBaseSidebarMenu();
    }

    changeComponent(componentName: string, displayName: string, hierarchy: number, product?: SidebarItem) {
        const breadcrumb: BreadcrumbItem = {
            name: componentName,
            displayName: displayName,
            componentName: componentName,
            position: 1,
            hierarchy: hierarchy,
        };

        this.breadcrumbService.setBreadcrumbs(breadcrumb);
        if (product) this.router.navigate(['/' + product.name + '/' + componentName]);
        else this.router.navigate(['/' + componentName]);
    }
}
