export interface Roles {
    officeemployee: {
        permissions: Permission[];
    };
    agent: {
        permissions: Permission[];
    };
    subteamlead: {
        permissions: Permission[];
    };
    teamlead: {
        permissions: Permission[];
    };
    projectmanager: {
        permissions: Permission[];
    };
    admin: {
        permissions: Permission[];
    };
    assistance: {
        permissions: Permission[];
    };
}

export type Permission =
    | 'read_contract'
    | 'create_contract'
    | 'update_contract'
    | 'delete_contract'
    | 'read_claim'
    | 'create_claim'
    | 'update_claim'
    | 'delete_claim'
    | 'read_transaction'
    | 'update_transaction'
    | 'approve_refund_transaction'
    | 'read_promotion'
    | 'create_promotion'
    | 'update_promotion';

export const ROLES: Roles = {
    officeemployee: {
        permissions: ['read_contract', 'read_claim', 'read_transaction', 'read_promotion'],
    },
    assistance: {
        permissions: [
            'read_contract',
            'read_claim',
            'update_claim',
            'create_claim',
            'read_transaction',
            'read_promotion',
        ],
    },
    agent: {
        permissions: [
            'read_contract',
            'create_contract',
            'update_contract',
            'read_claim',
            'create_claim',
            'update_claim',
            'read_transaction',
            'read_promotion',
        ],
    },
    subteamlead: {
        permissions: [
            'read_contract',
            'create_contract',
            'update_contract',
            'read_claim',
            'create_claim',
            'update_claim',
            'read_transaction',
            'update_transaction',
            'approve_refund_transaction',
            'read_promotion',
        ],
    },
    teamlead: {
        permissions: [
            'read_contract',
            'create_contract',
            'update_contract',
            'read_claim',
            'create_claim',
            'update_claim',
            'read_transaction',
            'update_transaction',
            'approve_refund_transaction',
            'read_promotion',
        ],
    },
    projectmanager: {
        permissions: [
            'read_promotion',
            'create_promotion',
            'update_promotion',
            'read_contract',
            'update_contract',
            'read_claim',
            'update_claim',
            'read_transaction',
        ],
    },
    admin: {
        permissions: [
            'read_contract',
            'create_contract',
            'update_contract',
            'delete_contract',
            'read_claim',
            'create_claim',
            'update_claim',
            'delete_claim',
            'read_transaction',
            'update_transaction',
            'approve_refund_transaction',
            'read_promotion',
            'create_promotion',
            'update_promotion',
        ],
    },
};
