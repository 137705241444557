import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from './sidebar/sidebar.module';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarBreadcrumbComponent } from './navbar-breadcrumb/navbar-breadcrumb.component';
import { SharedModule } from '@luis/common/shared';

@NgModule({
    declarations: [NavbarComponent, NavbarBreadcrumbComponent],
    imports: [CommonModule, FormsModule, HttpClientModule, SharedModule, SidebarModule],
    exports: [FormsModule, SidebarModule, NavbarComponent, NavbarBreadcrumbComponent],
})
export class NavigationModule {}
