export enum ProductTitle {
    TRAVEL_INSURANCE = 'ti',
    TRAVEL_INSURANCE_DREI = 'ti_drei',
    MASTERCARD = 'MASC',
    ALL = 'all',
}

export enum ProductSubMenuTitle {
    ORGANIZATIONS = 'submenu_organizations',
    CONTRACT = 'submenu_contract',
    CLAIM = 'submenu_claims',
    TRAVEL = 'submenu_travel',
    TRAVEL_WARNINGS = 'submenu_travel_warnings',
    CONSISTENCY_CHECKS = 'submenu_consistency_checks',
}
