import { Component, effect, input } from '@angular/core';
import { ContractCancellationData } from '../../models/contract-cancellation-details';

@Component({
    selector: 'shared-contract-cancellation-details',
    templateUrl: './cancellation-details.component.html',
})
export class CancellationDetailsComponent {
    public cancellationData = input.required<ContractCancellationData>();

    protected cancellationReceivedOn: Date;
    protected terminationOn: Date;
    protected cancellationProcessedOn: Date;

    private onInit = effect(
        () => {
            if (this.cancellationData() != null) {
                this.cancellationReceivedOn = new Date(this.cancellationData().cancellationReceivedOn);
                this.cancellationProcessedOn = new Date(this.cancellationData().cancellationProcessedOn);
                this.terminationOn = new Date(this.cancellationData().terminationOn);
            }
        },
        { allowSignalWrites: true },
    );
}
