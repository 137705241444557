import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { ViewDto } from '../../dtos';

@Component({
    selector: 'nuis-view-selector',
    standalone: true,
    imports: [FormsModule, TranslateModule, ButtonModule, DropdownModule, TooltipModule],
    templateUrl: './view-selector.component.html',
})
export class ViewSelectorComponent<T> {
    public views = input.required<ViewDto<T>[]>();
    public selectedView = input.required<ViewDto<T> | null>();

    public selectView = output<ViewDto<T>>();
    public createView = output<void>();
    public editView = output<ViewDto<T>>();
    public removeView = output<ViewDto<T>>();

    protected handleCreate(event: Event) {
        event.stopPropagation();
        this.createView.emit();
    }

    protected handleEdit(event: Event, view: ViewDto<T>) {
        event.stopPropagation();
        this.editView.emit(view);
    }

    protected handleRemove(event: Event, view: ViewDto<T>) {
        event.stopPropagation();
        this.removeView.emit(view);
    }
}
