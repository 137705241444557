import { ProductCategory } from '../models/product-category.model';
import { ProductTitle } from '../permissions/models/product-titles';

export function mapToProductTitle(product: ProductCategory): ProductTitle {
    switch (product) {
        case ProductCategory.TRAVEL_INSURANCE:
            return ProductTitle.TRAVEL_INSURANCE;
        default:
            return null;
    }
}
