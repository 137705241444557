import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'contracttypeSubType',
})
export class ContractTypeSubTypePipe implements PipeTransform {
    B2B_PRIVATE_KEY = 'b2b_private';
    B2B_COMPANY_KEY = 'b2b_company';

    transform(value: string) {
        if (!value) return '';

        if (value.toLowerCase().includes(this.B2B_PRIVATE_KEY)) {
            return 'contracttype_names.' + this.B2B_PRIVATE_KEY.toLowerCase();
        } else if (value.toLowerCase().includes(this.B2B_COMPANY_KEY)) {
            return 'contracttype_names.' + this.B2B_COMPANY_KEY.toLowerCase();
        } else {
            return '';
        }
    }
}
