import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Permission, ProductTitle, Reference, RolesService, TableSettingsStoreService } from '@luis/common/shared';
import { map, Observable } from 'rxjs';
import { TransactionCheckboxClickablePipe } from '../../pipes/transaction-checkbox.pipe';
import { TransactionListItem, TransactionTableStore } from '../../services/table-store.service';
import { TransactionTableService } from '../../services/transaction-table.service';
import { TransactionService } from '../../services/transaction.service';

interface TransactionCheckboxState {
    transaction: TransactionListItem;
    ischecked: boolean;
}
@Component({
    selector: 'core-transaction-small-table',
    templateUrl: './small-table.component.html',
    providers: [TransactionCheckboxClickablePipe, TransactionService],
})
export class TransactionSmallTableComponent implements OnInit {
    private readonly transactionTableService = inject(TransactionTableService);
    private readonly transactionSmallTableStore = inject(TransactionTableStore);
    private readonly checkBoxPipe = inject(TransactionCheckboxClickablePipe);
    private readonly roleService = inject(RolesService);
    private readonly tableSettingsStoreService = inject(TableSettingsStoreService);
    private readonly destroyRef = inject(DestroyRef);

    @Output() transactionCheckboxChanged = new EventEmitter<TransactionCheckboxState>();

    @Input() set triggerUnselect(shouldTrigger: boolean) {
        if (shouldTrigger) this.onTriggerUnselect();
    }

    @Input() set ref(value: Reference) {
        this.reference = value;
    }

    @Input() partnerId: string;

    rowsPerPage: number;
    allowedRowsPerPage: number[];
    first = 0;
    totalRecords = 0;
    allTransactionsSelected = false;
    isLoading = false;
    reference: Reference;
    transactions: TransactionListItem[] = [];

    public ngOnInit() {
        this.loadTransactions();

        this.transactionTableService.isLoading$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isLoading) => {
            this.isLoading = isLoading;
        });

        this.transactionTableService.reference$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((reference) => (this.reference = reference));

        this.transactionSmallTableStore.transactions$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((transactions) => {
                this.transactions = this.sort(transactions);
                this.totalRecords = transactions.length;
            });

        this.rowsPerPage = this.tableSettingsStoreService.getTableRowsPerSiteByView('TRANSACTION_SMALL');

        this.tableSettingsStoreService.transactionSmallTable$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((settings) => {
                this.rowsPerPage = settings.maxRowsPerSite;
                this.allowedRowsPerPage = settings.allowedRowCounts;
            });
    }

    sort(transactions: TransactionListItem[]) {
        return transactions.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    }

    loadTransactions() {
        this.isLoading = true;
        this.transactionTableService.loadRecords(null, null, null, this.reference).subscribe((response) => {
            const sortedTransactions = this.sortTransactions(response.records);
            this.transactionSmallTableStore.initTransaction(sortedTransactions);
            this.transactionTableService.setLoading(false);
        });
    }

    sortTransactions(transactions: TransactionListItem[]): TransactionListItem[] {
        let sortIndex = transactions.length + 1;
        return transactions
            .sort((a, b) => (a.start > b.start === false ? 1 : -1))
            .map((transaction) => {
                sortIndex = sortIndex - 1;
                return {
                    ...transaction,
                    sortIndex: sortIndex,
                };
            });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onTransactionSelected(event: any, transaction: TransactionListItem): void {
        //disable checkbox if any rnd is selected manually
        this.allTransactionsSelected = false;

        if (transaction.transactionType === 'Debit') {
            const foundRelatedCredits = this.transactions.filter(
                (t) =>
                    t.id === transaction.linkedTransactionId &&
                    t.transactionType === 'Credit' &&
                    t.reversalStatus === null,
            );
            if (foundRelatedCredits && foundRelatedCredits.length > 0) {
                this.transactionCheckboxChanged.emit({
                    transaction: foundRelatedCredits[0],
                    ischecked: event.checked,
                });
                this.transactionSmallTableStore.setSelection(foundRelatedCredits[0]);
                foundRelatedCredits[0].isChecked = event.checked;
            }
        }

        this.transactionCheckboxChanged.emit({
            transaction: transaction,
            ischecked: event.checked,
        });
    }

    public onTriggerUnselect(): void {
        this.transactionSmallTableStore.updateCheckbox();

        this.unselectAllCheckboxes();
    }

    unselectAllCheckboxes(): void {
        this.allTransactionsSelected = false;
        this.transactions = this.transactions?.map((transaction) => {
            return {
                ...transaction,
                isChecked: false,
            };
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkAll($event: any) {
        this.transactions.forEach((transaction) => {
            if (!this.checkBoxPipe.transform(transaction))
                if ($event.checked === true) {
                    this.transactionSmallTableStore.setSelection(transaction);
                    transaction.isChecked = $event.checked;
                    this.transactionCheckboxChanged.emit({
                        transaction: transaction,
                        ischecked: $event.checked,
                    });
                } else {
                    transaction.isChecked = $event.checked;
                    this.transactionCheckboxChanged.emit({
                        transaction: transaction,
                        ischecked: $event.checked,
                    });
                }
        });
    }

    checkHasPermission(permission: Permission, partner: string): Observable<boolean> {
        return this.roleService.isGranted(permission, ProductTitle.TRAVEL_INSURANCE, partner).pipe(
            map((isGranted) => {
                return isGranted;
            }),
        );
    }

    setRows(rows: number) {
        this.tableSettingsStoreService.setTableRowsPerSite('TRANSACTION_SMALL', rows);
    }
}
