import { Component, DestroyRef, inject, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContractStatistics } from '../../models/contract-statistics.model';
import { ContractStatus } from '../../models/status.model';
import { ContractStatusService } from '../../services/contract-status.service';

@Component({
    selector: 'shared-status-overview',
    templateUrl: './status-overview.component.html',
})
export class StatusOverviewComponent {
    private readonly contractStatusService = inject(ContractStatusService);
    private readonly destroyRef = inject(DestroyRef);

    @Input() status: ContractStatus;
    statistics: ContractStatistics = {};

    constructor() {
        this.contractStatusService.linkedContracts$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((count) => {
            this.statistics.linkedContracts = count;
        });

        this.contractStatusService.openClaims$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((count) => {
            this.statistics.openClaims = count;
        });

        this.contractStatusService.closedClaims$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((count) => {
            this.statistics.closedClaims = count;
        });

        this.contractStatusService.openComplaints$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((count) => {
            this.statistics.openComplaints = count;
        });

        this.contractStatusService.closedComplaints$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((count) => {
            this.statistics.closedComplaints = count;
        });
    }
}
