import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ContentChild, EventEmitter, inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Filter } from '../../models/filter.model';
import { defaultSorting, Sorting } from '../../models/sorting.model';
import { TableView } from '../../models/table-view.model';

@Component({
    selector: 'table-view-editor',
    templateUrl: './view-editor.component.html',
    styleUrls: ['./view-editor.component.scss'],
})
export class ViewEditorComponent implements OnInit {
    private readonly message = inject(MessageService);
    private readonly translate = inject(TranslateService);

    @Input() public filters: Filter;
    @Input() public allColumnFields: string[] = [];
    @Input() public currentViewedColumnFields: string[] = [];
    @Input() public editViewName = '';
    @Input() public viewId = -1;
    @Output() viewSaved = new EventEmitter<TableView>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ContentChild('filterTemplate', { static: false }) protected filterTemplate: TemplateRef<any>;

    availableColumns: string[] = [];

    view: TableView = {
        id: this.viewId,
        label: this.editViewName ? this.editViewName : null,
        filters: null,
        fields: [],
        sorting: defaultSorting,
        isStandardView: false,
    };
    resetSorting = new BehaviorSubject<Sorting>(defaultSorting);

    public ngOnInit() {
        this.availableColumns = this.allColumnFields.filter((a) => this.currentViewedColumnFields.indexOf(a) < 0);

        if (this.view) {
            this.view.label = this.editViewName ? this.translate.instant(this.editViewName) : null;
            this.view.fields = this.currentViewedColumnFields;
            this.view.id = this.viewId;
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    save() {
        if (!this.view?.label) {
            this.message.add({
                severity: 'error',
                summary: this.translate.instant('error'),
                detail: this.translate.instant('message.error.requiredFieldEmpty'),
            });

            return;
        }

        this.viewSaved.emit({
            ...this.view,
            filters: this.filters,
        });
    }

    setFilter(filterKeyValuePair: [string, Filter]) {
        this.view.filters = {
            ...this.view.filters,
            [filterKeyValuePair[0]]: filterKeyValuePair[1],
        };
    }

    sortingChanged(sorting: Sorting) {
        this.resetSorting.next(sorting);
        this.view.sorting = sorting;
    }
}
