import { Pipe, PipeTransform } from '@angular/core';
import { PartnerIdKeyPipe } from './partner-id-key.pipe';
import { Currency } from '../models/currency.model';

@Pipe({
    name: 'partnerCurrency',
})
export class PartnerCurrencyPipe implements PipeTransform {
    /**
     *
     */
    constructor(private partnerIdKeyPipe: PartnerIdKeyPipe) {}

    transform(partnerId: string): string {
        if (this.partnerIdKeyPipe.transform(partnerId) == 'a1srb') {
            return Currency.RSD;
        } else if (this.partnerIdKeyPipe.transform(partnerId) == 'a1bgr') {
            return Currency.BGN;
        } else {
            return Currency.EUR;
        }
    }
}
