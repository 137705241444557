import { Injectable } from '@angular/core';
import { Transaction } from '../models/transaction.model';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { Observable } from 'rxjs';

export interface CreateReversalRequest {
    TransactionIds: string[];
    ReversalReason: string;
}

@Injectable()
export class TransactionService {
    baseUrl: string;

    constructor(
        private http: HttpClientAdapter,
        private config: Configuration,
    ) {
        this.baseUrl = this.config.baseUrl + 'transactions/';
    }

    approveForReversal(
        transactions: Transaction[],
        selectedTransactionReason: string,
    ): Observable<TransactionReversalResponse> {
        const transactionIds = transactions?.map((transaction) => transaction.id);

        const request: CreateReversalRequest = {
            TransactionIds: transactionIds,
            ReversalReason: selectedTransactionReason,
        };
        return this.http.post<TransactionReversalResponse>(this.baseUrl + 'CreateReversalRequests', request);
    }
}
export interface TransactionReversalResponse {
    failedReversalRequests: string[];
}
