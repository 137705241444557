import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ProductCategory } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { TransactionRelated } from '../models/reference-open-reversal';

@Pipe({
    name: 'refRelatedData',
})
export class TransactionRefRelatedDataPipe implements PipeTransform {
    datePipe: DatePipe;
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private translate: TranslateService,
    ) {
        this.datePipe = new DatePipe(this.locale);
    }

    transform(value: TransactionRelated, category: ProductCategory) {
        if (!value.relatedObject) return '';

        switch (category) {
            case 'TravelInsurance':
                return this.mapTravelRelatedData(value.relatedObject as TravelRelatedData);
            default:
                return '';
        }
    }

    mapTravelRelatedData(travel: TravelRelatedData): string {
        return (
            this.translate.instant('startDate') +
            ': ' +
            this.datePipe.transform(travel.startedOn, 'dd.MM.yyyy') +
            '\n' +
            this.translate.instant('endDate') +
            ': ' +
            this.isDataSet(this.datePipe.transform(travel.endsOn, 'dd.MM.yyyy')) +
            '\n' +
            this.translate.instant('travelState') +
            ': ' +
            this.isDataSet(travel.travelState) +
            '\n' +
            this.translate.instant('travelSubState') +
            ': ' +
            this.isDataSet(travel.travelSubState)
        );
    }
    isDataSet(value: string): string {
        if (value !== null) return value;
        else return '-';
    }
}

export interface TravelRelatedData {
    startedOn: Date;
    endsOn: Date;
    travelState: string;
    travelSubState: string;
    id: string;
}
