import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-checkbox',
    standalone: true,
    imports: [ReactiveFormsModule, CheckboxModule, FormLabelComponent],
    template: `
        <div class="flex gap-2 align-items-center">
            <p-checkbox [formControl]="control()" [binary]="true" />

            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }
        </div>
    `,
})
export class InputCheckboxComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<boolean>>();
}
