import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { CountryNamePipe } from '../pipes/country-name.pipe';

export class CountryService {
    translationService = inject(TranslateService);
    countryNamePipe = inject(CountryNamePipe);

    getCountryList(): Observable<CountryModel[]> {
        const countryList: CountryModel[] = [];

        const countryListCodes: string[] = [
            'af',
            'ax',
            'al',
            'ad',
            'dz',
            'as',
            'ao',
            'ai',
            'aq',
            'ag',
            'ar',
            'am',
            'aw',
            'au',
            'at',
            'az',
            'bs',
            'bh',
            'bd',
            'bb',
            'by',
            'be',
            'bz',
            'bj',
            'bm',
            'bt',
            'bo',
            'bq',
            'ba',
            'bw',
            'bv',
            'br',
            'io',
            'bn',
            'bg',
            'bf',
            'bi',
            'kh',
            'cm',
            'ca',
            'cv',
            'ky',
            'cf',
            'td',
            'cl',
            'cn',
            'cx',
            'cc',
            'co',
            'km',
            'cg',
            'cd',
            'ck',
            'cr',
            'ci',
            'hr',
            'cu',
            'cw',
            'cy',
            'cz',
            'dk',
            'dj',
            'dm',
            'do',
            'ec',
            'eg',
            'sv',
            'gq',
            'er',
            'ee',
            'et',
            'fk',
            'fo',
            'fj',
            'fi',
            'fr',
            'gf',
            'pf',
            'tf',
            'ga',
            'gm',
            'ge',
            'de',
            'gh',
            'gi',
            'gr',
            'gl',
            'gd',
            'gp',
            'gu',
            'gt',
            'gg',
            'gn',
            'gw',
            'gy',
            'ht',
            'hm',
            'va',
            'hn',
            'hk',
            'hu',
            'is',
            'in',
            'id',
            'ir',
            'iq',
            'ie',
            'im',
            'il',
            'it',
            'jm',
            'jp',
            'je',
            'jo',
            'kz',
            'ke',
            'ki',
            'kp',
            'kr',
            'xk',
            'kw',
            'kg',
            'la',
            'lv',
            'lb',
            'ls',
            'lr',
            'ly',
            'li',
            'lt',
            'lu',
            'mo',
            'mk',
            'mg',
            'mw',
            'my',
            'mv',
            'ml',
            'mt',
            'mh',
            'mq',
            'mr',
            'mu',
            'yt',
            'mx',
            'fm',
            'md',
            'mc',
            'mn',
            'me',
            'ms',
            'ma',
            'mz',
            'mm',
            'na',
            'nr',
            'np',
            'nl',
            'an',
            'nc',
            'nz',
            'ni',
            'ne',
            'ng',
            'nu',
            'nf',
            'mp',
            'no',
            'om',
            'pk',
            'pw',
            'ps',
            'pa',
            'pg',
            'py',
            'pe',
            'ph',
            'pn',
            'pl',
            'pt',
            'pr',
            'qa',
            'rs',
            're',
            'ro',
            'ru',
            'rw',
            'bl',
            'sh',
            'kn',
            'lc',
            'mf',
            'pm',
            'vc',
            'ws',
            'sm',
            'st',
            'sa',
            'sn',
            'cs',
            'sc',
            'sl',
            'sg',
            'sx',
            'sk',
            'si',
            'sb',
            'so',
            'za',
            'gs',
            'ss',
            'es',
            'lk',
            'sd',
            'sr',
            'sj',
            'sz',
            'se',
            'ch',
            'sy',
            'tw',
            'tj',
            'tz',
            'th',
            'tl',
            'tg',
            'tk',
            'to',
            'tt',
            'tn',
            'tr',
            'xt',
            'tm',
            'tc',
            'tv',
            'ug',
            'ua',
            'ae',
            'gb',
            'us',
            'um',
            'uy',
            'uz',
            'vu',
            've',
            'vn',
            'vg',
            'vi',
            'wf',
            'eh',
            'ye',
            'zm',
            'zw',
        ];

        countryListCodes.forEach((code) => {
            countryList.push({
                code: code,
                name: this.translationService.instant(this.countryNamePipe.transform(code)),
            });
        });

        return of(countryList);
    }
}

export interface CountryModel {
    name: string;
    code: string;
}
