export interface QuickFillNote {
    color: string;
    chipTitle: string;
    title: string;
    description: string;
}

export function getQuickFillNoteArr(): QuickFillNote[] {
    const arr: QuickFillNote[] = [
        {
            color: 'yellow',
            chipTitle: 'notes.autofill.additionalinsured.chiptitle',
            title: 'notes.autofill.additionalinsured.subject',
            description: 'notes.autofill.additionalinsured.body',
        },
        {
            color: 'green',
            chipTitle: 'notes.autofill.maininusured.chiptitle',
            title: 'notes.autofill.maininusured.subject',
            description: 'notes.autofill.maininusured.body',
        },
        {
            color: 'orange',
            chipTitle: 'notes.autofill.customerdata.chiptitle',
            title: 'notes.autofill.customerdata.subject',
            description: 'notes.autofill.customerdata.body',
        },
        {
            color: 'orange',
            chipTitle: 'notes.autofill.changecover.chiptitle',
            title: 'notes.autofill.changecover.subject',
            description: 'notes.autofill.changecover.body',
        },
        {
            color: 'red',
            chipTitle: 'notes.autofill.cancellation.chiptitle',
            title: 'notes.autofill.cancellation.subject',
            description: 'notes.autofill.cancellation.body',
        },
        {
            color: 'purple',
            chipTitle: 'notes.autofill.claim.creation.chiptitle',
            title: 'notes.autofill.claim.creation.subject',
            description: 'notes.autofill.claim.creation.body',
        },
        {
            color: 'purple',
            chipTitle: 'notes.autofill.claim.statusupdate.chiptitle',
            title: 'notes.autofill.claim.statusupdate.subject',
            description: 'notes.autofill.claim.statusupdate.body',
        },
        {
            color: 'purple',
            chipTitle: 'notes.autofill.claim.dailyreceipt.chiptitle',
            title: 'notes.autofill.claim.dailyreceipt.subject',
            description: 'notes.autofill.claim.dailyreceipt.body',
        },
    ];

    return arr;
}
