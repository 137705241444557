import { Color } from './color.model';

export interface ProfilePicture {
    initials: string;
    color: Color;
}

export function generateProfilePicture(userEmail: string): ProfilePicture {
    userEmail = userEmail.toLocaleUpperCase();
    const names = userEmail.split('.');
    if (names.length <= 1) {
        return {
            color: 'bluegray',
            initials: userEmail[0],
        };
    }

    return {
        initials: names[0][0] + names[1][0],
        color: mapLetterToColor(names[0][0]),
    };
}

function mapLetterToColor(letter: string): Color {
    const charCode = letter.toLocaleLowerCase().charCodeAt(0);
    if (charCode <= 102) return 'red';
    if (charCode <= 108) return 'orange';
    if (charCode <= 113) return 'yellow';
    if (charCode <= 118) return 'green';
    if (charCode <= 122) return 'blue';
    return 'bluegray';
}
