<div
    class="p-2 text-center border-round flex flex-column justify-content-around h-full align-items-center"
    [style]="{ height: '120px' }">
    <span>
        <shared-product-icon></shared-product-icon>
    </span>
    <span>
        <div class="text-xl font-medium mb-1 text-900">
            {{ partnerId | partnerId | translate }}
        </div>
        <div class="text-lg font-medium mb-1 text-500">
            {{ contractTypeName | contracttypeSubType | translate }}
        </div>
    </span>
</div>
