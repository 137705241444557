/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SortIcon, SORT_ICONS } from '../../models/sort-icon.model';
import { defaultSorting, Sorting, SortOrder } from '../../models/sorting.model';

@Component({
    selector: 'table-view-editor-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
})
export class FieldComponent {
    @Input() field: string;
    @Input() isSortable: boolean;
    @Input() index: number | null;
    @Input() set resetSorting(sorting: Sorting) {
        if (this.counter === 0) return;
        if (sorting?.sortField && sorting?.sortField === this.field) return;

        this.counter = 0;
        this.sortIcon = SORT_ICONS[0];
        this.sortColor = 'text-600';
    }
    @Output() sortingChange = new EventEmitter<Sorting>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @ContentChild('fieldFilterTemplate', { static: false })
    fieldFilterTemplate: TemplateRef<any>;
    sortIcon: SortIcon = 'pi pi-sort-alt';
    sortColor: 'text-600' | 'text-primary' = 'text-600';
    private counter = 0;

    sort() {
        if (++this.counter === SORT_ICONS.length) {
            this.counter = 0;
            this.sortColor = 'text-600';
            this.sortingChange.emit(defaultSorting);
        } else {
            this.sortColor = 'text-primary';
            this.sortingChange.emit({
                sortField: this.field,
                sortOrder: this.counter === 1 ? SortOrder.ASC : SortOrder.DESC,
            });
        }

        this.sortIcon = SORT_ICONS[this.counter];
    }
}
