import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { initialSorting } from '../../models/sorting.model';
import { State, TABLE_FEATURE_KEY } from './table.reducer';

export const selectTableState = createFeatureSelector<State>(TABLE_FEATURE_KEY);

export const selectFilters = (key: string) =>
    createSelector(selectTableState, (state: State) =>
        state?.filters ? (key in state.filters ? _.cloneDeep(state.filters[key]) : {}) : {},
    );

export const selectSorting = (key: string) =>
    createSelector(selectTableState, (state: State) =>
        state?.sorting ? (key in state.sorting ? _.cloneDeep(state.sorting[key]) : initialSorting) : initialSorting,
    );

export const selectKeyWord = createSelector(selectTableState, (state: State) => state?.keyWord);
