import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-checkbox-tri',
    standalone: true,
    imports: [ReactiveFormsModule, TriStateCheckboxModule, FormLabelComponent],
    template: `
        <div class="flex gap-2 align-items-center">
            <p-triStateCheckbox [formControl]="control()" />

            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }
        </div>
    `,
})
export class InputCheckboxTriComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<boolean | null>>();
}
