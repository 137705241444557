<ul class="list-none p-0 m-0">
    <li class="surface-card p-3 shadow-2 border-round">
        @if (cancellationData()) {
            <span>
                <div class="grid formgrid p-fluid p-2">
                    <div class="col-12 mb-3">
                        <div class="mb-2 flex flex-row justify-content-between align-items-center">
                            <span class="text-xl font-medium text-900">{{ 'cancellationData.title' | translate }}</span>
                        </div>
                    </div>
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="cancellationReceivedDate" class="font-medium text-900">{{
                            'cancellationData.cancellationReceivedDate' | translate
                        }}</label>
                        <p-calendar
                            id="cancellationReceivedDate"
                            dateFormat="dd.mm.yy"
                            [showTime]="false"
                            [(ngModel)]="cancellationReceivedOn"
                            [disabled]="true"
                            [showIcon]="false">
                        </p-calendar>
                    </div>
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="cancellationReason" class="font-medium text-900">{{
                            'cancellationData.cancellationReason' | translate
                        }}</label>
                        <input
                            id="cancellationReason"
                            type="text"
                            pInputText
                            [ngModel]="cancellationData().cancellationReason | translate"
                            [disabled]="true" />
                    </div>
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="cancellationProcessedOn" class="font-medium text-900">{{
                            'cancellationData.cancellationProcessedOn' | translate
                        }}</label>
                        <p-calendar
                            id="cancellationProcessedOn"
                            dateFormat="dd.mm.yy"
                            [showTime]="false"
                            [(ngModel)]="cancellationProcessedOn"
                            [disabled]="true"
                            [showIcon]="false"></p-calendar>
                    </div>
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="terminationDate" class="font-medium text-900">{{
                            'cancellationData.terminationDate' | translate
                        }}</label>
                        <p-calendar
                            id="terminationDate"
                            dateFormat="dd.mm.yy"
                            [showTime]="false"
                            [(ngModel)]="terminationOn"
                            inputId="dateformat"
                            [disabled]="true"
                            [showIcon]="false"></p-calendar>
                    </div>
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="terminationDate" class="font-medium text-900">{{
                            'cancellationData.transmitter' | translate
                        }}</label>
                        <input
                            id="terminationDate"
                            [ngModel]="cancellationData().transmitter | translate"
                            pInputText
                            [disabled]="true" />
                    </div>
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="terminationDate" class="font-medium text-900">{{
                            'cancellationData.executedAgent' | translate
                        }}</label>
                        <input
                            id="terminationDate"
                            [(ngModel)]="cancellationData().executedAgentEmail"
                            inputId="dateformat"
                            [disabled]="true"
                            pInputText />
                    </div>
                </div>
            </span>
        } @else {
            <span class="text-700 mt-2">
                <i class="pi pi-info-circle text-xl mr-2"></i>
                {{ 'noCancellationData' | translate }}
            </span>
        }
    </li>
</ul>
