import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsComponent } from './icons/icons.component';
import { StackedComponent } from './stacked/stacked.component';
import { ProductmenuComponent } from './productmenu/productmenu.component';
import { SharedModule } from '@luis/common/shared';

@NgModule({
    declarations: [IconsComponent, StackedComponent, ProductmenuComponent],
    imports: [CommonModule, SharedModule],
    exports: [StackedComponent, IconsComponent, ProductmenuComponent],
})
export class SidebarModule {}
