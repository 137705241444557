import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../models/configuration.model';

@Injectable()
export class LoggingHttpInterceptor implements HttpInterceptor {
    constructor(private config: Configuration) {}

    intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.config.logging) {
            console.table(httpRequest);
        }

        return next.handle(httpRequest);
    }
}
