import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { BreadcrumbItem } from './models/breadcrumb-item.model';
import { NavbarBreadcrumbService } from './navbar.breadcrumb.service';

@Component({
    selector: 'nav-navbar-breadcrumb',
    templateUrl: './navbar-breadcrumb.component.html',
})
export class NavbarBreadcrumbComponent implements OnInit {
    private readonly breadcrumbService = inject(NavbarBreadcrumbService);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    breadcrumbs: BreadcrumbItem[] = [];

    public ngOnInit() {
        this.breadcrumbService.breadcrumbs$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((crumbs) => {
            this.breadcrumbs = crumbs;
        });
    }

    changeComponent(crumb: BreadcrumbItem) {
        const newRoute =
            this.breadcrumbs
                .map((crumb) => crumb.componentName)
                .join('/')
                .split(crumb.componentName)[0] + crumb.componentName;

        this.router.navigate(['/' + newRoute]);
        this.breadcrumbService.setBreadcrumbs(crumb);
    }
}
