<div class="h-full overflow-auto">
    <div class="flex justify-content-center h-full w-full align-items-center">
        <div class="shadow-1 p-3 w-8 h-20rem border-round bg-red-200">
            <div class="h-full flex flex-column justify-content-center align-items-center text-red-900">
                <i class="pi pi-lock text-6xl font-bold"></i>
                <span class="font-medium text-2xl p-2">{{ 'accessDenied' | translate }}</span>
                <span class="p-2">{{ 'noPermissions' | translate }}</span>
            </div>
        </div>
    </div>
</div>
