import { Injectable, inject } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import {
    Configuration,
    defaultSmallTableAllowedRows,
    defaultSmallTableRows,
    defaultTableAllowedRows,
    defaultTableRows,
} from '../models/configuration.model';
import { State } from '../models/state.model';

export interface TableSettingsState {
    contractTable: TableSettings;
    notificationTable: TableSettings;
    notificationSmallTable: TableSettings;
    transactionTable: TableSettings;
    transactionSmallTable: TableSettings;
    travelTable: TableSettings;
    travelSmallTable: TableSettings;
    claimTable: TableSettings;
    claimSmallTable: TableSettings;
    travelWarningTable: TableSettings;
    promotionTable: TableSettings;
    organizationTable: TableSettings;
    organizationLinkedContractTable: TableSettings;
}

const initial: TableSettingsState = {
    contractTable: {
        tableView: 'CONTRACT',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
    notificationTable: {
        tableView: 'NOTIFICATION',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
    notificationSmallTable: {
        tableView: 'NOTIFICATION_SMALL',
        maxRowsPerSite: defaultSmallTableRows,
        allowedRowCounts: defaultSmallTableAllowedRows,
    },
    transactionTable: {
        tableView: 'TRANSACTION',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
    transactionSmallTable: {
        tableView: 'TRANSACTION_SMALL',
        maxRowsPerSite: defaultSmallTableRows,
        allowedRowCounts: defaultSmallTableAllowedRows,
    },
    travelTable: { tableView: 'TRAVEL', maxRowsPerSite: defaultTableRows, allowedRowCounts: defaultTableAllowedRows },
    travelSmallTable: {
        tableView: 'TRAVEL_SMALL',
        maxRowsPerSite: defaultSmallTableRows,
        allowedRowCounts: defaultSmallTableAllowedRows,
    },
    claimTable: { tableView: 'CLAIM', maxRowsPerSite: defaultTableRows, allowedRowCounts: defaultTableAllowedRows },
    claimSmallTable: {
        tableView: 'CLAIM_SMALL',
        maxRowsPerSite: defaultSmallTableRows,
        allowedRowCounts: defaultSmallTableAllowedRows,
    },
    travelWarningTable: {
        tableView: 'TRAVELWARNING',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
    promotionTable: {
        tableView: 'PROMOTION',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
    organizationTable: {
        tableView: 'ORGANIZATION',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
    organizationLinkedContractTable: {
        tableView: 'ORGANIZATION_LINKED_CONTRACT',
        maxRowsPerSite: defaultTableRows,
        allowedRowCounts: defaultTableAllowedRows,
    },
};

@Injectable({ providedIn: 'root' })
export class TableSettingsStoreService extends State<TableSettingsState> {
    configuration = inject(Configuration);

    contractTable$: Observable<TableSettings> = this.select((state) => state.contractTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    notificationTable$: Observable<TableSettings> = this.select((state) => state.notificationTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    notificationSmallTable$: Observable<TableSettings> = this.select((state) => state.notificationSmallTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    transactionTable$: Observable<TableSettings> = this.select((state) => state.transactionTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    transactionSmallTable$: Observable<TableSettings> = this.select((state) => state.transactionSmallTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    travelTable$: Observable<TableSettings> = this.select((state) => state.travelTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    travelSmallTable$: Observable<TableSettings> = this.select((state) => state.travelSmallTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    claimTable$: Observable<TableSettings> = this.select((state) => state.claimTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    claimSmallTable$: Observable<TableSettings> = this.select((state) => state.claimSmallTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    travelWarningTable$: Observable<TableSettings> = this.select((state) => state.travelWarningTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    promotionTable$: Observable<TableSettings> = this.select((state) => state.promotionTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    organizationTable$: Observable<TableSettings> = this.select((state) => state.organizationTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    organizationSmallTable$: Observable<TableSettings> = this.select(
        (state) => state.organizationLinkedContractTable,
    ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    /**
     *
     */
    constructor() {
        super(initial);
    }

    init() {
        this.setState({
            claimTable: {
                tableView: 'CLAIM',
                maxRowsPerSite: this.getTableRowsPerSiteByView('CLAIM'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('CLAIM'),
            },
            claimSmallTable: {
                tableView: 'CLAIM_SMALL',
                maxRowsPerSite: this.getTableRowsPerSiteByView('CLAIM_SMALL'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('CLAIM_SMALL'),
            },
            contractTable: {
                tableView: 'CONTRACT',
                maxRowsPerSite: this.getTableRowsPerSiteByView('CONTRACT'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('CONTRACT'),
            },
            notificationSmallTable: {
                tableView: 'NOTIFICATION_SMALL',
                maxRowsPerSite: this.getTableRowsPerSiteByView('NOTIFICATION_SMALL'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('NOTIFICATION_SMALL'),
            },
            notificationTable: {
                tableView: 'NOTIFICATION',
                maxRowsPerSite: this.getTableRowsPerSiteByView('NOTIFICATION'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('NOTIFICATION'),
            },
            transactionSmallTable: {
                tableView: 'TRANSACTION_SMALL',
                maxRowsPerSite: this.getTableRowsPerSiteByView('TRANSACTION_SMALL'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('TRANSACTION_SMALL'),
            },
            transactionTable: {
                tableView: 'TRANSACTION',
                maxRowsPerSite: this.getTableRowsPerSiteByView('TRANSACTION'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('TRANSACTION'),
            },
            travelSmallTable: {
                tableView: 'TRAVEL_SMALL',
                maxRowsPerSite: this.getTableRowsPerSiteByView('TRAVEL_SMALL'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('TRAVEL_SMALL'),
            },
            travelTable: {
                tableView: 'TRAVEL',
                maxRowsPerSite: this.getTableRowsPerSiteByView('TRAVEL'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('TRAVEL'),
            },
            travelWarningTable: {
                tableView: 'TRAVELWARNING',
                maxRowsPerSite: this.getTableRowsPerSiteByView('TRAVELWARNING'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('TRAVELWARNING'),
            },
            promotionTable: {
                tableView: 'PROMOTION',
                maxRowsPerSite: this.getTableRowsPerSiteByView('PROMOTION'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('PROMOTION'),
            },
            organizationTable: {
                tableView: 'ORGANIZATION',
                maxRowsPerSite: this.getTableRowsPerSiteByView('ORGANIZATION'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('ORGANIZATION'),
            },
        });
    }

    setStoreByViewType(tableViewType: TableViewType, rowCount: number) {
        const settings = {
            tableView: tableViewType,
            maxRowsPerSite: rowCount,
            allowedRowCounts: this.getTableAllowedRowCountPerSiteByView(tableViewType),
        };
        switch (tableViewType) {
            case 'CONTRACT': {
                this.setState({ contractTable: settings });
                break;
            }
            case 'TRAVEL': {
                this.setState({ travelTable: settings });
                break;
            }
            case 'TRAVEL_SMALL': {
                this.setState({ travelSmallTable: settings });
                break;
            }
            case 'TRANSACTION': {
                this.setState({ transactionTable: settings });
                break;
            }
            case 'TRANSACTION_SMALL': {
                this.setState({ transactionSmallTable: settings });
                break;
            }
            case 'NOTIFICATION': {
                this.setState({ notificationTable: settings });
                break;
            }
            case 'NOTIFICATION_SMALL': {
                this.setState({ notificationSmallTable: settings });
                break;
            }
            case 'CLAIM': {
                this.setState({ claimTable: settings });
                break;
            }
            case 'CLAIM_SMALL': {
                this.setState({ claimSmallTable: settings });
                break;
            }
            case 'TRAVELWARNING': {
                this.setState({ travelWarningTable: settings });
                break;
            }
            case 'PROMOTION': {
                this.setState({ promotionTable: settings });
                break;
            }
            case 'ORGANIZATION': {
                this.setState({ organizationTable: settings });
                break;
            }
        }
    }

    getTableRowsPerSiteByView(tableView: TableViewType): number {
        const tableSettingsJSON = localStorage.getItem(this.getTableKey(tableView));
        const settings: TableSettings = JSON.parse(tableSettingsJSON);
        if (
            !settings ||
            settings === undefined ||
            (settings && (!settings.maxRowsPerSite || settings.maxRowsPerSite === 0))
        ) {
            this.setTableRowsPerSite(tableView, this.getDefaultRows(tableView));
            return this.getTableRowsPerSiteByView(tableView);
        }
        return settings.maxRowsPerSite;
    }

    getTableAllowedRowCountPerSiteByView(tableView: TableViewType): number[] {
        const tableSettingsJSON = localStorage.getItem(this.getTableKey(tableView));
        const settings: TableSettings = JSON.parse(tableSettingsJSON);
        if (!settings || settings === undefined || (settings && !settings.allowedRowCounts)) {
            this.setTableRowsPerSite(tableView, this.getDefaultRows(tableView));
            return this.getTableAllowedRowCountPerSiteByView(tableView);
        }
        return settings.allowedRowCounts;
    }

    setTableRowsPerSite(tableView: TableViewType, rowsPerSite: number) {
        const key = this.getTableKey(tableView);
        const tableSettingsJSON = localStorage.getItem(key);
        let settings: TableSettings;
        if (tableSettingsJSON === null || tableSettingsJSON === '' || tableSettingsJSON === undefined) {
            settings = {
                tableView: tableView,
                maxRowsPerSite: rowsPerSite,
                allowedRowCounts: this.getDefaultAllowedRows(tableView),
            };
        } else {
            settings = JSON.parse(tableSettingsJSON);
            settings.maxRowsPerSite = rowsPerSite;
        }

        localStorage.setItem(key, JSON.stringify(settings));
        this.setStoreByViewType(tableView, rowsPerSite);
    }

    getTableKey(tableView: TableViewType) {
        return tableView.toLowerCase() + '_' + 'table_settings';
    }

    private getDefaultRows(tableView: TableViewType): number {
        return this.configuration.defaultMaxTableRows.find((q) => q.tableView === tableView)?.maxRowsPerSite ?? 25;
    }

    private getDefaultAllowedRows(tableView: TableViewType): number[] {
        return (
            this.configuration.defaultMaxTableRows.find((q) => q.tableView === tableView)?.allowedRowCounts ?? [10, 25]
        );
    }
}

export interface TableSettings {
    tableView: TableViewType;
    maxRowsPerSite: number;
    allowedRowCounts: number[];
}

export type TableViewType =
    | 'CONTRACT'
    | 'TRAVEL'
    | 'TRAVEL_SMALL'
    | 'TRANSACTION'
    | 'TRANSACTION_SMALL'
    | 'NOTIFICATION'
    | 'NOTIFICATION_SMALL'
    | 'CLAIM'
    | 'CLAIM_SMALL'
    | 'TRAVELWARNING'
    | 'PROMOTION'
    | 'ORGANIZATION'
    | 'ORGANIZATION_LINKED_CONTRACT';
