import { Injectable, inject } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { Configuration, defaultSmallTableAllowedRows, defaultSmallTableRows } from '../models/configuration.model';
import { State } from '../models/state.model';

export interface TableSettingsState {
    transactionSmallTable: TableSettings;
}

const initial: TableSettingsState = {
    transactionSmallTable: {
        tableView: 'TRANSACTION_SMALL',
        maxRowsPerSite: defaultSmallTableRows,
        allowedRowCounts: defaultSmallTableAllowedRows,
    },
};

@Injectable({
    providedIn: 'root',
})
export class TableSettingsStoreService extends State<TableSettingsState> {
    private readonly configuration = inject(Configuration);

    public transactionSmallTable$: Observable<TableSettings> = this.select((state) => state.transactionSmallTable).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor() {
        super(initial);
    }

    public init() {
        this.setState({
            transactionSmallTable: {
                tableView: 'TRANSACTION_SMALL',
                maxRowsPerSite: this.getTableRowsPerSiteByView('TRANSACTION_SMALL'),
                allowedRowCounts: this.getTableAllowedRowCountPerSiteByView('TRANSACTION_SMALL'),
            },
        });
    }

    public setStoreByViewType(tableViewType: TableViewType, rowCount: number) {
        const settings = {
            tableView: tableViewType,
            maxRowsPerSite: rowCount,
            allowedRowCounts: this.getTableAllowedRowCountPerSiteByView(tableViewType),
        };
        switch (tableViewType) {
            case 'TRANSACTION_SMALL': {
                this.setState({ transactionSmallTable: settings });
                break;
            }
        }
    }

    public getTableRowsPerSiteByView(tableView: TableViewType): number {
        const tableSettingsJSON = localStorage.getItem(this.getTableKey(tableView));
        const settings: TableSettings = JSON.parse(tableSettingsJSON);
        if (
            !settings ||
            settings === undefined ||
            (settings && (!settings.maxRowsPerSite || settings.maxRowsPerSite === 0))
        ) {
            this.setTableRowsPerSite(tableView, this.getDefaultRows(tableView));
            return this.getTableRowsPerSiteByView(tableView);
        }
        return settings.maxRowsPerSite;
    }

    public getTableAllowedRowCountPerSiteByView(tableView: TableViewType): number[] {
        const tableSettingsJSON = localStorage.getItem(this.getTableKey(tableView));
        const settings: TableSettings = JSON.parse(tableSettingsJSON);
        if (!settings || settings === undefined || (settings && !settings.allowedRowCounts)) {
            this.setTableRowsPerSite(tableView, this.getDefaultRows(tableView));
            return this.getTableAllowedRowCountPerSiteByView(tableView);
        }
        return settings.allowedRowCounts;
    }

    public setTableRowsPerSite(tableView: TableViewType, rowsPerSite: number) {
        const key = this.getTableKey(tableView);
        const tableSettingsJSON = localStorage.getItem(key);
        let settings: TableSettings;
        if (tableSettingsJSON === null || tableSettingsJSON === '' || tableSettingsJSON === undefined) {
            settings = {
                tableView: tableView,
                maxRowsPerSite: rowsPerSite,
                allowedRowCounts: this.getDefaultAllowedRows(tableView),
            };
        } else {
            settings = JSON.parse(tableSettingsJSON);
            settings.maxRowsPerSite = rowsPerSite;
        }

        localStorage.setItem(key, JSON.stringify(settings));
        this.setStoreByViewType(tableView, rowsPerSite);
    }

    public getTableKey(tableView: TableViewType) {
        return tableView.toLowerCase() + '_' + 'table_settings';
    }

    private getDefaultRows(tableView: TableViewType): number {
        return this.configuration.defaultMaxTableRows.find((q) => q.tableView === tableView)?.maxRowsPerSite ?? 25;
    }

    private getDefaultAllowedRows(tableView: TableViewType): number[] {
        return (
            this.configuration.defaultMaxTableRows.find((q) => q.tableView === tableView)?.allowedRowCounts ?? [10, 25]
        );
    }
}

export interface TableSettings {
    tableView: TableViewType;
    maxRowsPerSite: number;
    allowedRowCounts: number[];
}

export type TableViewType = 'TRANSACTION_SMALL';
