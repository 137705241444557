import { Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, Reference } from '@luis/common/shared';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, Subject, map, pipe, switchMap, tap } from 'rxjs';
import { Transaction } from '../models/transaction.model';

interface TransactionState {
    transactions: TransactionListItem[];
    selectedTransactions?: TransactionListItem[];
    contractId: string;
}

export interface TransactionListItem extends Transaction {
    isChecked: boolean;
    sortIndex?: number;
}

@Injectable({
    providedIn: 'root',
})
export class TransactionTableStore extends ComponentStore<TransactionState> {
    private isLoading = new Subject<boolean>();
    isLoading$ = this.isLoading.asObservable();

    constructor(
        private http: HttpClientAdapter,
        config: Configuration,
    ) {
        super({
            transactions: [],
            selectedTransactions: [],
            contractId: '',
        });

        this.#url = `${config.baseUrl}Transactions`;
    }

    #url: string;

    readonly transactions$ = this.select((state) => state.transactions);
    readonly selectedTransactions$ = this.select((state) => state.selectedTransactions);

    readonly loadTransactions = this.effect((ref$: Observable<Reference>) => {
        return ref$.pipe(
            tap(() => this.showLoading(true)),
            switchMap((ref) =>
                this.#getTransactions(ref).pipe(
                    tapResponse((transactions) => {
                        this.setState({
                            transactions,
                            contractId: transactions[0]?.contractId,
                        });
                    }, console.error),
                ),
            ),
        );
    });

    // eslint-disable-next-line @ngrx/updater-explicit-return-type
    private readonly updateCheckboxes = this.updater((state) => ({
        transactions: state.transactions?.map((t) => ({ ...t, isChecked: false })),
        contractId: state?.contractId,
    }));

    readonly updateCheckbox = this.effect<void>(pipe(tap(() => this.updateCheckboxes())));

    reloadTransactions(reference: Reference) {
        this.#getTransactions(reference).subscribe((transactions) => {
            this.setState({
                transactions,
                contractId: transactions[0]?.contractId,
            });
        });
    }

    #getTransactions(ref: Reference): Observable<TransactionListItem[]> {
        let url = this.#url;
        let body = {};
        switch (ref.refName) {
            case 'Contract':
                url = url + `/ListByContractId?contractId=contractId=` + ref.refId;
                break;
            case 'Travel':
                url = url + `/GetAllByRef`;
                body = {
                    params: {
                        ...ref,
                    },
                };
                break;
        }

        return this.http.get<Transaction[]>(url, body).pipe(
            map((transactions) => {
                return transactions?.map((transaction) => {
                    return {
                        ...transaction,
                        isChecked: false,
                    };
                });
            }),
            tap(() => {
                this.showLoading(false);
            }),
        );
    }

    showLoading(loading: boolean) {
        this.isLoading.next(loading);
    }

    initTransaction(transactions: TransactionListItem[]) {
        this.setState({
            transactions,
            selectedTransactions: [],
            contractId: transactions[0]?.contractId,
        });
    }

    setSelection(newTransaction: TransactionListItem) {
        // eslint-disable-next-line @ngrx/updater-explicit-return-type
        this.updater((state: TransactionState) => {
            return {
                ...state,
                selectedTransactions: [...state.selectedTransactions, newTransaction],
            };
        });
    }
}
