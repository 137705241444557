import { inject, Injectable } from '@angular/core';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { filter, from, mergeMap, Observable, of, tap } from 'rxjs';
import { Configuration } from '../models/configuration.model';
import { generateProfilePicture } from '../models/profile-picture.model';
import { State } from '../models/state.model';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { AuthState } from './models/auth-state.model';
import { ClientInfo } from './models/client-info.model';
import { StateInfo } from './models/state-info.model';

const initialState: AuthState = {
    client: null,
    roles: [],
    allowedPartners: [],
};

@Injectable({
    providedIn: 'root',
})
export class AuthService extends State<AuthState> {
    private readonly keyCloakService = inject(KeycloakService);
    private readonly userService = inject(UserService);
    private readonly config = inject(Configuration);

    constructor() {
        super(initialState);
    }

    public loggedUser: Observable<User> = this.userService.user$;
    public client$: Observable<ClientInfo | null> = this.select((state) => state.client);

    protected stateInfo: StateInfo = {
        debug: {
            showClient: false,
            showToken: false,
            showUserInfo: false,
            showLogsEvents: {
                onAuthRefreshSuccess: false,
                onTokenExpired: false,
            },
        },
    };

    public init(state: StateInfo | null): void {
        if (state && state.debug) {
            this.stateInfo = state;
        }

        this.onTokenExpired().subscribe(() => {
            if (this.stateInfo && this.stateInfo.debug && this.stateInfo.debug.showLogsEvents) {
                if (this.stateInfo.debug.showLogsEvents.onTokenExpired) {
                    console.warn('onTokenExpired triggered');
                }
            }
        });

        this.onAuthRefreshSuccess().subscribe(() => {
            if (this.stateInfo && this.stateInfo.debug && this.stateInfo.debug.showLogsEvents) {
                if (this.stateInfo.debug.showLogsEvents.onAuthRefreshSuccess) {
                    console.warn('onAuthRefreshSuccess triggered');
                }
            }
        });

        this.onLoadUserInfoFromToken().subscribe(() => this.setRoles());

        this.onLoadClientInfoFromToken().subscribe();
    }

    public logIn(): Observable<void> {
        return this.config.keycloak.enabled ? from(this.keyCloakService.login()) : of();
    }

    public getToken(): Observable<string | null> {
        return this.config.keycloak.enabled ? from(this.keyCloakService.getToken()) : of(null);
    }

    public isLoggedIn(): Observable<boolean> {
        return this.config.keycloak.enabled ? of(this.keyCloakService.isLoggedIn()) : of(false);
    }

    public isTokenExpired(): boolean {
        return this.config.keycloak.enabled ? this.keyCloakService.isTokenExpired() : false;
    }

    public logOut(): Observable<boolean> {
        return this.isLoggedIn().pipe(
            tap((flag: boolean) => {
                if (flag) {
                    this.keyCloakService.logout(window.location.origin);
                }
            }),
        );
    }

    public get isEnabled() {
        return this.config.keycloak.enabled;
    }

    public getRoles(): string[] {
        return this.state.roles;
    }

    public onTokenExpired(): Observable<boolean> {
        return this.keyCloakService.keycloakEvents$
            .pipe(filter((event: KeycloakEvent) => event.type === KeycloakEventType.OnTokenExpired))
            .pipe(
                mergeMap(() => {
                    return from(this.keyCloakService.updateToken(0));
                }),
            );
    }

    onAuthRefreshSuccess(): Observable<string | null> {
        return this.keyCloakService.keycloakEvents$
            .pipe(filter((event: KeycloakEvent) => event.type === KeycloakEventType.OnAuthRefreshSuccess))
            .pipe(
                mergeMap(() => {
                    return this.getToken().pipe(
                        tap((token) => {
                            if (this.stateInfo) {
                                if (this.stateInfo.debug.showToken) {
                                    console.warn('Token:' + token);
                                }
                            }
                        }),
                    );
                }),
            );
    }

    onLoadUserInfoFromToken(): Observable<boolean> {
        if (this.config.keycloak.enabled) {
            return this.isLoggedIn().pipe(
                tap((flag: boolean) => {
                    if (flag) {
                        const tokenInfo = this.keyCloakService.getKeycloakInstance().idTokenParsed;
                        if (tokenInfo != null) {
                            const user: User = {
                                email: tokenInfo['email'],
                                firstname: tokenInfo['given_name'],
                                lastname: tokenInfo['family_name'],
                                profilePicture: generateProfilePicture(tokenInfo['email']),
                            };
                            this.userService.setUser(user);
                            if (this.stateInfo) {
                                if (this.stateInfo.debug.showUserInfo && !this.config.production) {
                                    console.warn('Logged user:' + JSON.stringify(user));
                                }
                            }
                        }
                    }
                }),
            );
        } else {
            return of(false);
        }
    }

    onLoadClientInfoFromToken(): Observable<boolean> {
        if (this.config.keycloak.enabled) {
            return this.isLoggedIn().pipe(
                tap((flag: boolean) => {
                    if (flag) {
                        const tokenInfo = this.keyCloakService.getKeycloakInstance().idTokenParsed;
                        if (tokenInfo != null) {
                            const client: ClientInfo = {
                                name: tokenInfo['azp'] ?? null,
                                iss: tokenInfo['iss'] ?? null,
                                expiredTokenDate: null,
                            };
                            if (tokenInfo['exp'] && tokenInfo['exp'] > 0) {
                                client.expiredTokenDate = new Date(tokenInfo['exp'] * 1000);
                            }
                            this.setClient(client);
                            if (this.stateInfo) {
                                if (this.stateInfo.debug.showClient && !this.config.production) {
                                    console.warn('Client info:' + JSON.stringify(this.state.client));
                                }
                            }
                        }
                    }
                }),
            );
        } else {
            return of(false);
        }
    }

    getInitials(user: User): string {
        let result = '';
        if (user.firstname) {
            result += user.firstname.charAt(0);
        }
        if (user.lastname) {
            result += user.lastname.charAt(0);
        }
        return result.toUpperCase();
    }

    setClient(client: ClientInfo): void {
        this.setState({ client: client });
    }

    setRoles(): void {
        let roles: string[];
        if (this.config.keycloak.enabled) {
            roles = this.keyCloakService.getUserRoles(true);
        }
        this.setState({ roles: roles });
    }

    setAllowedPartners(partners: string[]): void {
        this.setState({ allowedPartners: partners });
    }

    getAllowedPartners(): string[] {
        return this.state.allowedPartners;
    }
}
