<div class="min-h-full h-full flex surface-ground z-10">
    <div
        id="app-sidebar-9"
        class="h-full surface-section hidden lg:block flex-shrink-0 lg:sticky left-0 top-0 z-10 border-right-1 surface-border w-18rem lg:w-7rem select-none">
        <div class="flex flex-column h-full z-10">
            <div class="mt-3">
                <ul class="list-none p-0 m-0">
                    <li>
                        <a
                            pRipple
                            class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-cyan-600 border-left-2 border-cyan-600 hover:border-300 transition-duration-150 transition-colors">
                            <i
                                class="pi pi-home mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"
                                (click)="this.changeComponent('', '', 1)"></i>
                            <span class="font-medium inline text-base lg:text-xs lg:block">{{
                                'dashboard' | translate
                            }}</span>
                        </a>
                    </li>
                    <li>
                        <p-divider></p-divider>
                    </li>
                    <li class="relative" *ngFor="let product of productsMenu">
                        <a
                            pRipple
                            class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors z-10"
                            pStyleClass="@next"
                            enterFromClass="hidden"
                            leaveToClass="hidden"
                            [hideOnOutsideClick]="true">
                            <i
                                class="pi {{ product.icon }} mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl"
                                pBadge="3"
                                severity="danger"></i>
                            <span class="font-medium inline text-base lg:text-xs lg:block">{{
                                product.displayTranslationKey | translate
                            }}</span>
                            <i class="pi pi-chevron-down ml-auto lg:hidden"></i>
                        </a>
                        <ul
                            class="list-none pl-3 pr-0 py-0 lg:p-3 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out static border-round-right lg:absolute left-100 top-0 z-10 surface-overlay shadow-none lg:shadow-2 w-full lg:w-15rem">
                            <li *ngFor="let subItem of product.subMenu">
                                <a
                                    pRipple
                                    class="flex align-items-center cursor-pointer p-3 hover:surface-100 hover:text-900 border-round text-600 hover:text-700 transition-duration-150 transition-colors"
                                    pStyleClass="@next"
                                    toggleClass="hidden"
                                    (click)="
                                        this.changeComponent(subItem.displayTranslationKey, subItem.name, 1, product)
                                    ">
                                    <i class="pi {{ subItem.icon }} mr-2"></i>
                                    <span class="font-medium">{{ subItem.displayTranslationKey | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="baseMenu">
                        <p-divider></p-divider>
                    </li>
                    <li *ngIf="baseMenu">
                        <ul *ngIf="baseMenu">
                            <li
                                *ngFor="let baseItem of baseMenu"
                                class="flex flex-row lg:flex-column align-items-center cursor-pointer p-3 lg:justify-content-center hover:surface-100 text-600 border-left-2 border-transparent hover:text-900 hover:border-300 transition-duration-150 transition-colors">
                                <a
                                    pRipple
                                    class="flex align-items-center cursor-pointer p-3 border-round text-600 transition-duration-150 transition-colors"
                                    pStyleClass="@next"
                                    toggleClass="hidden"
                                    (click)="this.changeComponent(baseItem.displayTranslationKey, baseItem.name, 0)">
                                    <span class="font-medium">{{ baseItem.displayTranslationKey | translate }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
