import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { ReferenceOpenReversalsTableRecord, SELECTIONSTATE } from '../../models/reference-open-reversal';
import { ReferenceTransactionReversalApprovementButtonService } from '../../services/transaction-reversal-approvement-button.service';

@Component({
    selector: 'core-transaction-reversal-approvement-button',
    templateUrl: './transaction-reversal-approvement-button.component.html',
})
export class TransactionReversalApprovementButtonComponent implements OnInit {
    private readonly transactionReversalApprovementButtonService = inject(
        ReferenceTransactionReversalApprovementButtonService,
    );
    private readonly destroyRef = inject(DestroyRef);

    @Input() contractTransactionRecord: ReferenceOpenReversalsTableRecord;
    @Input() row: number;
    @Input() transactionButtonStateChange = new Subject<{
        buttonState: SELECTIONSTATE;
        rowContractId: string;
    }>();

    @Output() approveTransactions = new EventEmitter<{
        buttonState: SELECTIONSTATE;
        rowContractId: string;
    }>();
    @Output() denyTransactions = new EventEmitter<{
        buttonState: SELECTIONSTATE;
        rowContractId: string;
    }>();
    @Output() unselectTransactions = new EventEmitter<{
        buttonState: SELECTIONSTATE;
        rowContractId: string;
    }>();

    current: SELECTIONSTATE;
    pendingSelected = true;
    approveSelected: boolean;
    denySelected: boolean;
    hasDifferentButtonStates = false;

    public ngOnInit(): void {
        this.transactionButtonStateChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
            if (this.contractTransactionRecord?.contractId == data.rowContractId) {
                this.hasDifferentButtonStates = this.#checkDifferentTransactionState(
                    data.rowContractId,
                    data.buttonState,
                );
                if (!this.hasDifferentButtonStates) {
                    this.toggleButton(data.buttonState);
                    this.transactionReversalApprovementButtonService.setReference(
                        data.rowContractId,
                        data.buttonState,
                        this.transactionReversalApprovementButtonService.getCategoryByContractId(data.rowContractId),
                        this.transactionReversalApprovementButtonService.getTransactionButtonStates(data.rowContractId),
                    );
                } else {
                    this.pendingSelected = false;
                    this.transactionReversalApprovementButtonService.setReference(
                        data.rowContractId,
                        'MIX',
                        this.transactionReversalApprovementButtonService.getCategoryByContractId(data.rowContractId),
                        this.transactionReversalApprovementButtonService.getTransactionButtonStates(data.rowContractId),
                    );
                }
            }
        });
    }

    #checkDifferentTransactionState(rowContractId: string, buttonState: SELECTIONSTATE): boolean {
        const transactions = this.transactionReversalApprovementButtonService.getTransactionButtonStates(rowContractId);
        let hasDifferentState = false;
        transactions.forEach((transaction) => {
            if (transaction.transactionButtonState != buttonState) hasDifferentState = true;
        });

        return hasDifferentState;
    }

    toggleButton(buttonState: SELECTIONSTATE) {
        this.current = buttonState;

        this.transactionReversalApprovementButtonService.setReference(
            this.contractTransactionRecord?.contractId,
            buttonState,
            this.transactionReversalApprovementButtonService.getCategoryByContractId(
                this.contractTransactionRecord?.contractId,
            ),
            this.transactionReversalApprovementButtonService
                .getTransactionButtonStates(this.contractTransactionRecord?.contractId)
                .map((transaction) => {
                    return {
                        transactionId: transaction.transactionId,
                        transactionButtonState: buttonState,
                    };
                }),
        );

        switch (buttonState) {
            case 'PENDING':
                this.pendingSelected = true;
                this.denySelected = false;
                this.approveSelected = false;
                this.hasDifferentButtonStates = false;
                this.unselectTransactions.emit({
                    buttonState: 'PENDING',
                    rowContractId: this.contractTransactionRecord?.contractId,
                });
                break;
            case 'APPROVE':
                this.pendingSelected = false;
                this.denySelected = false;
                this.approveSelected = true;
                this.hasDifferentButtonStates = false;
                this.approveTransactions.emit({
                    buttonState: 'APPROVE',
                    rowContractId: this.contractTransactionRecord?.contractId,
                });
                break;
            case 'DENY':
                this.pendingSelected = false;
                this.denySelected = true;
                this.approveSelected = false;
                this.hasDifferentButtonStates = false;
                this.denyTransactions.emit({
                    buttonState: 'DENY',
                    rowContractId: this.contractTransactionRecord?.contractId,
                });
                break;
        }
    }
}
