import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'reversalStatus',
})
export class ReversalStatusPipe implements PipeTransform {
    transform(reversalStatus: string) {
        if (!reversalStatus) return '';
        return 'reversal.status.' + reversalStatus.toLowerCase();
    }
}
