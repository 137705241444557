import {
    Component,
    ContentChild,
    DestroyRef,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AuthService,
    Configuration,
    PartnerIdKeyPipe,
    RolesService,
    TableSettingsStoreService,
    TableViewType,
} from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, FilterMetadata, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    iif,
    map,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { TableFacade } from '../../+state/table/table.facade';
import { PrimeNgFilter } from '../../models/filter.model';
import { LazyLoadResponse } from '../../models/lazy-load-response.model';
import { defaultSorting, initialSorting, Sorting } from '../../models/sorting.model';
import { TableService } from '../../models/table-service.model';
import { TableView } from '../../models/table-view.model';
import { TableViewServiceBase } from '../../services/table-views.service';

@Component({
    selector: 'table-lazy',
    templateUrl: './lazy-table.component.html',
    styleUrls: ['./lazy-table.component.scss'],
})
export class LazyTableComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);

    @ViewChild('table', { static: false }) table: Table;
    @ContentChild('viewEditor', { static: false })
    viewEditor: TemplateRef<unknown>;
    @ContentChild('header', { static: false }) header: TemplateRef<unknown>;
    @ContentChild('body', { static: false }) body: TemplateRef<unknown>;
    @Input() tableService: TableService;
    @Input() loadRecords: (
        event: LazyLoadEvent,
        args: string[],
        allowedPartners: string[],
    ) => Observable<LazyLoadResponse<unknown>>;
    @Input() loadMastercardRecords: (event: LazyLoadEvent, args: string[]) => Observable<LazyLoadResponse<unknown>>;
    @Input() loadRecordArgs: string[];
    @Input() viewService: TableViewServiceBase;
    @Input() viewEditorFilters: PrimeNgFilter;
    @Input() showSearchbar = false;
    @Input() showCaption = true;
    @Input() set headerFilters(filters: PrimeNgFilter) {
        if (!filters) return;
        this.setFilters(filters);

        this.lazyLoad({
            ...this.lazyLoadEvent,
            filters: filters as { [s: string]: FilterMetadata },
        });
    }
    @Input() rowsPerPage: number[];
    @Input() rows: number;
    @Input() tableViewType: TableViewType;
    @Input() tableIdentifier: string;
    @Output() rowSelected = new EventEmitter<[unknown, string, boolean]>();
    @Output() tableViewChange = new EventEmitter<PrimeNgFilter>();
    @Output() visibleFieldsChange = new EventEmitter<string[]>();
    @Output() clearFilters = new EventEmitter<void>();
    @Input() displayViewSelection = true;
    @Input() defaultFilter: PrimeNgFilter;

    displayViewEditor = false;
    isLoading = true;
    isBooting = new BehaviorSubject(true);
    records: unknown[];
    totalRecords: number;
    searchKeyword = '';
    searchKeywordChanged = new Subject<string>();
    views: TableView[];
    selectedView: TableView;
    selectedViewLabel: string;
    selectedViewIndex: number;
    lastSelectedViewIndex = -1;
    allColumnFields: string[];
    currentViewedColumnFields: string[];
    fieldTranslationScope: string;
    viewScope: string;
    allowedPartners$: Observable<string[]>;
    sorting = new BehaviorSubject<Sorting>(defaultSorting);
    private lazyLoadEvent: LazyLoadEvent = null;
    partnerIds: unknown;

    editViewName = '';
    currentViewIndex = -1;

    isCalled = false;

    constructor(
        private tableFacade: TableFacade,
        private authService: AuthService,
        private rolesService: RolesService,
        private partnerIdKeyPipe: PartnerIdKeyPipe,
        private config: Configuration,
        private confirmationService: ConfirmationService,
        private translationService: TranslateService,
        private tableSettingsStoreService: TableSettingsStoreService,
    ) {
        this.allowedPartners$ = this.rolesService.roles$.pipe(
            map((roles) => roles?.map((role) => role.partner)),
            map((partners) => partners?.map((partner) => this.partnerIdKeyPipe.transformBack(partner))),
            map((partnerIds) => (partnerIds.includes('admin') ? Object.values(this.config.partnerIds) : partnerIds)),
            tap((partnerIds) => {
                this.partnerIds = partnerIds;
            }),
        );

        this.searchKeywordChanged
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                debounceTime(800),
                distinctUntilChanged(),
                tap((keyword) => {
                    this.searchKeyword = keyword;
                    this.tableFacade.setKeywordSearch(keyword);
                }),
                switchMap((keyword) => {
                    return this.lazyLoadRecords(
                        {
                            ...this.lazyLoadEvent,
                            globalFilter: this.trimSearch(keyword),
                            rows: this.rows,
                        },
                        authService.getAllowedPartners(),
                    );
                }),
            )
            .subscribe();
    }

    public ngOnInit() {
        this.viewService.restore();
        this.allColumnFields = this.viewService.getAllFields();
        this.currentViewedColumnFields = this.viewService.getSortFields();

        this.views = this.viewService.getViews();

        this.selectedViewLabel = this.viewService.selectedView ?? 'standardView';
        this.selectedView = this.views.filter(
            (q) => q.label?.toLowerCase() === this.selectedViewLabel.toLowerCase(),
        )[0];
        this.selectedViewIndex =
            this.views.findIndex((view) => view.label.toLowerCase() === this.selectedViewLabel.toLowerCase()) ?? 0;

        this.tableFacade
            .getKeyWord()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((searchKeyword) => {
                this.searchKeyword = this.trimSearch(searchKeyword);
            });

        this.isBooting
            .asObservable()
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                distinctUntilChanged(),
                filter((booting) => !booting),
                switchMap(() => this.tableFacade.selectSorting(this.tableIdentifier)),
                filter((sorting) => !(sorting?.sortField === undefined || sorting?.sortOrder === undefined)),
                debounceTime(1000),
                tap((sorting) => {
                    this.sorting.next(sorting);
                }),
            )
            .subscribe();

        if (this.defaultFilter !== null) {
            this.headerFilters = this.defaultFilter;
            this.viewEditorFilters = this.defaultFilter;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lazyLoad(event: any) {
        if (!this.isCalled) {
            this.isCalled = true;
            this.lazyLoadRecords(
                {
                    ...event,
                    rows: this.rows,
                    first: 0,
                    sortOrder: event.sortOrder ?? -1,
                    globalFilter: this.trimSearch(this.searchKeyword),
                },
                this.authService.getAllowedPartners(),
            )
                .pipe(
                    take(1),
                    debounceTime(1000),
                    tap(() => {
                        this.isCalled = false;
                        this.isLoading = false;
                    }),
                )
                .subscribe();
        }
    }

    trimSearch(value: string): string {
        return value?.replace(/^\s+|\s+$/g, '');
    }

    private lazyLoadRecords(event: LazyLoadEvent, allowedPartners: string[]): Observable<LazyLoadResponse<unknown>> {
        if (this.lazyLoadEvent === event) return of(null);
        this.isLoading = true;
        this.lazyLoadEvent = event;

        if (!this.tableService) return of(null);

        return this.tableService.loadRecords(event, this.loadRecordArgs, allowedPartners).pipe(
            tap((response) => {
                this.records = response.records;
                this.totalRecords = response.totalRecords;
                this.isLoading = false;
            }),
            debounceTime(1000),
        );
    }

    clear() {
        this.lazyLoadEvent = {};
        this.table.filters = {};
        this.table.clear();
        this.tableFacade.setFilter(this.tableIdentifier, {});
        this.tableFacade.setSorting(this.tableIdentifier, initialSorting);

        this.searchKeywordChanged.next('');
        this.searchKeyword = '';

        this.clearFilters.emit();
    }

    changeView(selection: TableView) {
        this.changeViewSelection(selection)
            .pipe(
                switchMap(() => this.isBooting.asObservable()),
                filter((isBooting) => isBooting),
                tap(() => {
                    this.isBooting.next(false);
                }),
            )
            .subscribe();
    }

    private changeViewSelection(selection: TableView): Observable<TableView | null> {
        return this.isBooting.asObservable().pipe(
            take(1),
            tap((isBooting) => {
                if (!isBooting) {
                    this.clear();

                    this.selectedViewIndex = this.views.findIndex((view) => view?.label == selection?.label) ?? 0;
                    if (this.lastSelectedViewIndex == this.selectedViewIndex) return;

                    this.lastSelectedViewIndex = this.selectedViewIndex;
                    this.viewService.selectedView = this.views[this.selectedViewIndex]?.label;
                }
            }),
            switchMap(() =>
                this.tableFacade.selectSorting(this.tableIdentifier).pipe(
                    shareReplay(1),
                    take(1),
                    switchMap((sorting) =>
                        iif(
                            () => !!sorting?.sortField && !!sorting?.sortOrder,
                            of(sorting).pipe(
                                tap(() => this.sorting.next(defaultSorting)),
                                debounceTime(200),
                                tap((sorting) => {
                                    this.sorting.next(sorting);
                                    this.tableFacade.setSorting(this.tableIdentifier, sorting);

                                    this.tableViewChange.emit(
                                        this.views[this.selectedViewIndex]?.filters as PrimeNgFilter,
                                    );
                                }),
                            ),
                            of(sorting).pipe(
                                tap(() =>
                                    this.tableFacade.setSorting(
                                        this.tableIdentifier,
                                        this.views[this.selectedViewIndex]?.sorting,
                                    ),
                                ),
                            ),
                        ),
                    ),
                    map(() => this.views[this.selectedViewIndex]),
                ),
            ),
            tap(() => {
                this.tableViewChange.emit(this.views[this.selectedViewIndex]?.filters as PrimeNgFilter);
            }),
            map(() => this.views[this.selectedViewIndex]),
        );
    }

    addNewView() {
        this.editViewName = '';
        this.currentViewedColumnFields = this.allColumnFields;
        this.currentViewedColumnFields = [];
        this.currentViewIndex = -1;

        this.displayViewEditor = true;
    }

    saveView(view: TableView) {
        this.views = this.viewService.addView({ ...view });
        this.changeViewSelection(this.views[this.views.length - 1])
            .pipe(
                tap(() => {
                    this.displayViewEditor = false;
                }),
            )
            .subscribe(() => {
                this.viewService.restore();
                this.selectedViewLabel = this.viewService.selectedView;
                this.selectedView = this.views.filter(
                    (q) => q.label?.toLowerCase() === this.selectedViewLabel.toLowerCase(),
                )[0];
                this.selectedViewIndex = this.views.findIndex((storeView) => storeView.label == view.label) ?? 0;
            });
    }

    removeView(view: TableView) {
        this.confirmationService.confirm({
            header: this.replaceTranslation(
                this.translationService.instant('viewEditor.confirmation.delete.header'),
                '{{view-name}}',
                view?.label,
            ),
            message: this.replaceTranslation(
                this.translationService.instant('viewEditor.confirmation.delete.message'),
                '{{view-name}}',
                view?.label,
            ),
            icon: 'pi pi-delete-circle',
            accept: () => {
                this.viewService.removeView(view);
                this.views = this.viewService.getViews();
                this.selectedViewLabel = this.viewService.selectedView;
                this.selectedView = this.views.filter(
                    (q) => q.label?.toLowerCase() === this.selectedViewLabel.toLowerCase(),
                )[0];
                this.selectedViewIndex = this.views.findIndex((view) => view.label == this.selectedViewLabel) ?? 0;
            },
            reject: () => {
                return;
            },
        });
    }

    private replaceTranslation(text: string, replaceKey: string, newString: string) {
        const newText = text.replace(replaceKey, newString);
        return newText;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectRow(record: unknown, field: string, event: any) {
        this.rowSelected.emit([record, field, event.ctrlKey]);
    }

    private setFilters(filters: PrimeNgFilter) {
        if (!this.table) return;
        filters ??= {};
        this.table.filters = filters;
        this.tableFacade.setFilter(this.tableIdentifier, filters);
    }

    setSorting(event: { field: string; order: 1 | -1 }) {
        this.sorting
            .asObservable()
            .pipe(
                shareReplay(1),
                take(1),
                filter((sorting) => sorting?.sortField != event.field || sorting?.sortOrder != event.order),
                tap(() => {
                    this.tableFacade.setSorting(this.tableIdentifier, {
                        sortField: event.field,
                        sortOrder: event.order,
                    });
                }),
            )
            .subscribe();
    }

    setRows(rows: number) {
        if (this.tableViewType) this.tableSettingsStoreService.setTableRowsPerSite(this.tableViewType, rows);
    }

    editViewEditor(item: TableView) {
        this.editViewName = item?.label;
        this.currentViewIndex = item?.id;
        this.currentViewedColumnFields = item.fields;
        this.displayViewEditor = true;
    }
}
