import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { Note, UINote } from '../../models/note.model';
import { CreatorPipe } from '../../pipes/creator.pipe';
import { ModalService } from '../../services/modal.service';
import { NoteReloadService } from '../../services/note.reload.service';
import { NoteService } from '../../services/note.service';
import { UserService } from '../../services/user.service';
import { generateProfilePicture } from '../../utils/profile-picture';
import { getQuickFillNoteArr, QuickFillNote } from './note-quick.model';

@Component({
    selector: 'shared-notes',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        DatePipe,
        FormsModule,
        TranslateModule,
        ButtonModule,
        InputTextModule,
        InputTextareaModule,
        TooltipModule,
        ChipModule,
        CreatorPipe,
    ],
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    providers: [NoteService],
})
export class NotesComponent implements OnInit {
    private readonly notesService = inject(NoteService);
    private readonly confirmationService = inject(ConfirmationService);
    private readonly translate = inject(TranslateService);
    private readonly userService = inject(UserService);
    private readonly notesReloadService = inject(NoteReloadService);
    private readonly modalService = inject(ModalService);
    private readonly destroyRef = inject(DestroyRef);

    @Input() refId: string;
    @Input() refName: string;
    @Input() contractId: string;
    @Input() isEditing = false;
    @Input() showNewNote = false;
    @Input() showButtons = true;
    @Input() showSavedNotes = true;
    @Input() noteViewHeader = 'notes.notes';
    @Input() showView = true;
    @Input() loadAllNotes = true;
    @Input() subject = '';
    @Input() body = '';
    @Output() subjectEvent = new EventEmitter<string>();
    @Output() bodyEvent = new EventEmitter<string>();

    editingNote: UINote;
    newText = '';
    newSubject = '';
    icon = 'pi pi-plus';
    tooltip = 'add';
    notes: UINote[];
    userEmail: string;
    quickNotes: QuickFillNote[] = [];

    public ngOnInit() {
        this.notesReloadService.reloadNotes$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.getAllNotes();
        });

        this.quickNotes = getQuickFillNoteArr();

        this.notesService.notes$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((notes) => {
            this.notes = notes?.map((note) => {
                const profilePicture = generateProfilePicture(note.creator);
                return {
                    ...note,
                    color: profilePicture.color,
                    initials: profilePicture.initials,
                };
            });
        });

        this.notesReloadService.resetNoteView$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.subject = '';
            this.body = '';
        });

        this.userService.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user) => {
            this.userEmail = user.email;
        });

        if (this.loadAllNotes == true) {
            this.getAllNotes();
        }
    }

    fillInputs(note: QuickFillNote) {
        this.body = this.translate.instant(note.description);
        this.bodyEvent.next(this.body);
        this.subject = this.translate.instant(note.title);
        this.subjectEvent.next(this.subject);
    }

    private getAllNotes() {
        this.notesService
            .getNotesByRefId(this.refId, this.refName)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((notes) => {
                this.notes = notes?.map((note) => {
                    const profilePicture = generateProfilePicture(note.creator);
                    return {
                        ...note,
                        color: profilePicture.color,
                        initials: profilePicture.initials,
                    };
                });
            });
    }

    showNote() {
        this.showNewNote = !this.showNewNote;
        this.editingNote = null;
        this.icon = this.showNewNote ? 'pi pi-minus' : 'pi pi-plus';
        this.tooltip = this.showNewNote ? 'close' : 'add';
    }

    addNote() {
        if (!this.subject && !this.body) {
            this.modalService.showToastMessage(
                'error',
                this.translate.instant('notes.toastnothingset'),
                this.translate.instant('notes.toastnothingsetHeader'),
            );
            return;
        }

        const newNote: Note = {
            subject: this.subject,
            body: this.body,
            creator: this.userService.getUserPrincipal(),
            timestamp: new Date(),
            refId: this.refId,
            refName: this.refName,
            contractId: this.contractId,
            type: 'note',
        };

        this.notesService
            .addNote(newNote)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.getAllNotes());

        this.body = '';
        this.subject = '';
        this.showNote();
    }

    deleteNote(note: UINote) {
        this.confirmationService.confirm({
            header: this.translate.instant('notes.confirmation.header'),
            message: this.translate.instant('notes.confirmation.message'),
            icon: 'pi pi-info-circle',
            accept: () => {
                this.notesService
                    .deleteNote(note.id)
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe(() => this.getAllNotes());
            },
            reject: () => {
                return;
            },
        });
    }

    editNote(note: UINote) {
        this.isEditing = true;
        this.editingNote = note;
        this.newSubject = note.subject;
        this.newText = note.body;
    }

    saveEdit(note: UINote) {
        note.body = this.newText;
        note.subject = this.newSubject;
        this.editingNote = null;
        this.isEditing = false;

        this.notesService.editNote(note).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }

    cancelEdit() {
        this.newText = '';
        this.editingNote = null;
        this.isEditing = false;
    }

    showText(note: UINote): boolean {
        if (!this.editingNote) {
            return true;
        }
        return this.editingNote != note;
    }
}
