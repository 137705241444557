export interface SidebarType {
    name: string;
    code: string;
    type: string;
}

export const SIDEBAR_TYPES: SidebarType[] = [
    { name: 'Products Menu', code: 'PM', type: 'product' },
    { name: 'Stacked', code: 'ST', type: 'stacked' },
    // { name: 'Icons', code: 'IC', type: 'icons' }
];
