import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Theme } from '../models/theme.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    isDark = new Subject<boolean>();
    isDark$ = this.isDark.asObservable();

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.restoreTheme();
    }

    restoreTheme() {
        const cachedTheme = this.getCachedTheme();
        if (cachedTheme) {
            this.setTheme(cachedTheme);
        } else {
            const isOSDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            this.setTheme(isOSDarkMode ? Theme.DARK : Theme.LIGHT);
        }

        this.checkThemeState(cachedTheme);
    }

    private getCachedTheme(): Theme | null {
        const theme = localStorage.getItem('theme');

        switch (theme) {
            case Theme.LIGHT:
                return Theme.LIGHT;
            case Theme.DARK:
                return Theme.DARK;
            default:
                return null;
        }
    }

    setTheme(theme: Theme) {
        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        themeLink.href = theme + '.css';

        localStorage.setItem('theme', theme);

        this.checkThemeState(theme);
    }

    switchTheme() {
        const currentTheme = this.getCachedTheme();
        let theme: Theme;

        switch (currentTheme) {
            case Theme.LIGHT:
                theme = Theme.DARK;
                break;
            case Theme.DARK:
            default:
                theme = Theme.LIGHT;
        }

        this.setTheme(theme);
        this.checkThemeState(theme);
    }

    private checkThemeState(theme: Theme) {
        switch (theme) {
            case Theme.LIGHT:
                this.isDark.next(false);
                break;
            case Theme.DARK:
                this.isDark.next(true);
                break;
            default:
                this.isDark.next(false);
        }
    }
}
