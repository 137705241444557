import { Route } from '@angular/router';

export const travelRoutes: Route = {
    loadComponent: () => import('./travel.component'),
    children: [
        { path: '', redirectTo: 'contracts', pathMatch: 'full' },
        {
            path: 'contracts',
            loadComponent: () => import('./pages/contracts/contracts.component'),
        },
        {
            path: 'claims',
            loadComponent: () => import('./pages/claims/claims.component'),
        },
        {
            path: 'travels',
            loadComponent: () => import('./pages/travels/travels.component'),
        },
        {
            path: 'organizations',
            loadComponent: () => import('./pages/framework-contracts/framework-contracts.component'),
        },
        {
            path: 'travel-warnings',
            loadComponent: () => import('./pages/travel-warnings/travel-warnings.component'),
        },
    ],
};
