import { Injectable } from '@angular/core';

import { HttpClientAdapter } from '@luis/common/shared';
import { environment } from '../../../environments/environment';
import { DashboardStatistics } from './dashboard-statistics.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DashboardResolver {
    url: string;

    constructor(private http: HttpClientAdapter) {
        this.url = environment.baseUrl + 'dashboard/';
    }

    resolve(): Observable<DashboardStatistics> {
        return this.http.get<DashboardStatistics>(this.url + 'GetStatistics');
    }
}
