import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProductCategory } from '../../models/product-category.model';
import { CommonStateService } from '../../services/common-state.service';

@Component({
    selector: 'shared-product-icon',
    templateUrl: './product-icon.component.html',
})
export class ProductIconComponent implements OnInit {
    private readonly commonStateService = inject(CommonStateService);
    private readonly destroyRef = inject(DestroyRef);

    productCategory: ProductCategory;

    public ngOnInit() {
        this.commonStateService.productCategory$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((productCategory) => {
                this.productCategory = productCategory;
            });
    }
}
