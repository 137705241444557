import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'cloneDeep',
})
export class CloneDeepPipe implements PipeTransform {
    transform(value: unknown): unknown {
        return _.cloneDeep(value);
    }
}
