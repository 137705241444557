import { FilterMetadata, LazyLoadEvent, SortMeta } from 'primeng/api';

export function getDateUtc(date: Date): Date {
    if (!date) return date;

    date = new Date(date?.toUTCString());
    return new Date(Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate()));
}

export function isPast(date: Date): boolean {
    const currentDate: Date = new Date();
    if (date != null) {
        return currentDate.getTime() - new Date(date)?.getTime() >= 0;
    } else {
        return false;
    }
}

interface MultiFilterLazyLoadEvent {
    first?: number;
    rows?: number;
    sortField?: string;
    sortOrder?: number;
    multiSortMeta?: SortMeta[];
    filters?: {
        [s: string]: FilterMetadata[];
    };
    globalFilter?: string;
}

// this is needed because the standard datetime behaviour returns the datetime in the local timezone instead of utc
// this is especially important when it comes to date filtering in the backend (which stores dates as utc)
export function transformFilterDateToUtc(event: LazyLoadEvent, fieldName: string): LazyLoadEvent {
    const multiFilterEvent = event as MultiFilterLazyLoadEvent;
    if (!multiFilterEvent.filters) return event;
    if (!multiFilterEvent.filters[fieldName]) return event;

    if (multiFilterEvent.filters[fieldName][0] && multiFilterEvent.filters[fieldName][0].value)
        multiFilterEvent.filters[fieldName][0].value = getDateUtc(multiFilterEvent.filters[fieldName][0].value);

    if (multiFilterEvent.filters[fieldName][1] && multiFilterEvent.filters[fieldName][1].value)
        multiFilterEvent.filters[fieldName][1].value = getDateUtc(multiFilterEvent.filters[fieldName][1].value);

    return multiFilterEvent as LazyLoadEvent;
}

export function getValueFromTranslation(event: LazyLoadEvent, fieldName: string): LazyLoadEvent {
    const multiFilterEvent = event as MultiFilterLazyLoadEvent;
    if (!multiFilterEvent.filters) return event;
    if (!multiFilterEvent.filters[fieldName]) return event;
    if (multiFilterEvent.filters[fieldName].length == 0) return event;

    if (multiFilterEvent.filters[fieldName][0] && multiFilterEvent.filters[fieldName][0].value) {
        const valueString = multiFilterEvent.filters[fieldName][0].value as string[];
        if (valueString.length == 0) return event;

        const values: string[] = [];
        valueString.forEach((value: string) => {
            const split = value.split('.');
            if (split.length > 1) {
                values.push(split[split.length - 1]);
            }
        });
        multiFilterEvent.filters[fieldName][0].value = values;
    }

    return multiFilterEvent as LazyLoadEvent;
}

export function getTimeOffset(): number {
    return new Date()?.getTimezoneOffset();
}

export function getLocalTime(utc: Date): Date {
    if (utc != null) {
        return new Date(new Date(utc)?.getTime() - 60 * 1000 * getTimeOffset());
    } else {
        return utc;
    }
}

export function isValidJMBG(jmbg: string): boolean {
    if (jmbg.length === 0) {
        return true;
    }
    if (jmbg.length === 13) {
        const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        const arrJMBG = jmbg.split('');
        //check_birthyear
        let birthYear = arrJMBG.slice(4, 7).join('');
        let birthYearInt = Number(birthYear);
        if (birthYear[0] === '0') {
            birthYearInt = Number(birthYear) + 2000;
        } else if (birthYear[0] === '9') {
            birthYearInt = Number(birthYear) + 1000;
        } else {
            return false;
        }
        //check month
        const birthMonth = arrJMBG.slice(2, 4).join('');
        const birthMonthInt = Number(birthMonth);
        if (birthMonthInt > 12 || birthMonthInt < 1) {
            return false;
        }
        //check leap year and set number of days in february
        if (birthYearInt % 4 === 0 && (birthYearInt % 100 !== 0 || birthYearInt % 400 === 0)) {
            daysInMonth[1] = 29;
        }

        //check if birth date is valid
        const birthDate = arrJMBG.slice(0, 2).join('');
        const birthDateInt = Number(birthDate);
        if (birthDateInt > daysInMonth[birthMonthInt - 1] || birthDateInt < 1) {
            return false;
        }

        //check control number
        let controlNumber =
            11 -
            ((7 * (Number(arrJMBG[0]) + Number(arrJMBG[6])) +
                6 * (Number(arrJMBG[1]) + Number(arrJMBG[7])) +
                5 * (Number(arrJMBG[2]) + Number(arrJMBG[8])) +
                4 * (Number(arrJMBG[3]) + Number(arrJMBG[9])) +
                3 * (Number(arrJMBG[4]) + Number(arrJMBG[10])) +
                2 * (Number(arrJMBG[5]) + Number(arrJMBG[11]))) %
                11);
        if (controlNumber > 9) {
            controlNumber = 0;
        }

        if (controlNumber !== Number(arrJMBG[12])) {
            return false;
        }

        return true;
    }
    return false;
}

export function checkSetValidBirthdateByNationalIdentificationNumber(
    nationalIdentificationNumber: string,
): Date | null {
    if (nationalIdentificationNumber.length >= 7) {
        let dateStringFull = '';
        const dateStringDay = nationalIdentificationNumber.substring(0, 2);
        const dateStringMonth = nationalIdentificationNumber.substring(2, 4);
        const dateStringYear = nationalIdentificationNumber.substring(4, 7);
        const genZNumberIdentifier = nationalIdentificationNumber.substring(4, 5);

        try {
            if (Number.parseInt(genZNumberIdentifier) === 0) {
                dateStringFull = dateStringMonth + '.' + dateStringDay + '.' + '2' + dateStringYear;
            } else {
                dateStringFull = dateStringMonth + '.' + dateStringDay + '.' + '1' + dateStringYear;
            }

            const dateAsNumber = Date.parse(dateStringFull);
            if (dateAsNumber > 0) {
                return new Date(dateAsNumber);
            }
        } catch (ex) {
            //unparsable number -> log
            console.log(ex);
            return null;
        }
    }
    return null;
}
