import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    BehaviorSubject,
    combineLatest,
    EMPTY,
    filter,
    map,
    Observable,
    of,
    Subscription,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { AuthService } from '../../authentication/auth.service';
import { Configuration } from '../../models/configuration.model';
import { ProductCategory } from '../../models/product-category.model';
import { Permission } from '../../models/roles.model';
import { State } from '../../models/state.model';
import { PartnerIdKeyPipe } from '../../pipes/partner-id-key.pipe';
import { CommonStateService } from '../../services/common-state.service';
import { ProductTitle } from '../models/product-titles';
import { RegExpInfo } from '../models/reg-exp-info';
import { RolesState } from '../models/roles-state';
import { SecurityInfo } from '../models/security-info';

const initialState: RolesState = {
    allRoles: [],
    roles: [],
};

@Injectable({
    providedIn: 'root',
})
export class RolesService extends State<RolesState> {
    allRoles$: Observable<string[]> = this.select((state) => state.allRoles);
    roles$: Observable<SecurityInfo[]> = this.select((state) => state.roles);

    susbcription = new Subscription();
    product: ProductCategory;

    private isDeactivated = new BehaviorSubject(false);
    private readonly isDeactivated$ = this.isDeactivated.asObservable();

    constructor(
        private authService: AuthService,
        private config: Configuration,
        private router: Router,
        private commonStateService: CommonStateService,
        private partnerIdKeyPipe: PartnerIdKeyPipe,
    ) {
        super(initialState);

        this.susbcription.add(
            this.commonStateService.productCategory$.subscribe((selectedProduct) => (this.product = selectedProduct)),
        );
    }

    init(): void {
        this.authService
            .isLoggedIn()
            .pipe(take(1))
            .subscribe((flag) => {
                if (flag) {
                    const skippedRoles: string[] = [
                        'read-token',
                        'manage-account',
                        'view-profile',
                        'manage-account-links',
                        'offline_access',
                        'default-roles-core',
                        'uma_authorization',
                    ];
                    if (this.config.keycloak.enabled) {
                        const roles = this.authService.getRoles().filter((item) => !skippedRoles.includes(item));
                        this.setState({ allRoles: [...roles] });
                        this.processRoleInfos();
                    }

                    this.commonStateService.setPartnerIds();
                }
            });
    }

    isAdmin(): boolean {
        if (this.state.roles.findIndex((s) => s.partner === 'admin' && s.product == null) != -1) {
            return true;
        }
        return false;
    }

    deactivate() {
        this.isDeactivated.next(true);
    }

    isGranted(permission: Permission, product: ProductTitle = null, partner: string = null): Observable<boolean> {
        return combineLatest({
            roles: this.roles$,
            isDeactivated: this.isDeactivated$,
        }).pipe(
            switchMap(({ roles, isDeactivated }) => {
                if (roles.length === 0) {
                    return of(false);
                }
                return isDeactivated
                    ? of(true)
                    : of(roles).pipe(
                          map((roles) => {
                              if (roles.find((role) => role.partner === 'admin')) return true;

                              if (
                                  this.product == ProductCategory.MASTERCARD ||
                                  this.product == ProductCategory.TRAVEL_INSURANCE_DREI
                              )
                                  return true;

                              return !!roles.find((role) => {
                                  return (
                                      (!this.isNullOrUndefined(partner) ? this.checkPartner(role, partner) : true) &&
                                      (!this.isNullOrUndefined(product) ? this.checkProduct(role, product) : true) &&
                                      (!this.isNullOrUndefined(permission)
                                          ? role.permissions.includes(permission)
                                          : true)
                                  );
                              });
                          }),
                      );
            }),
        );
    }

    guard(permission: Permission, product: ProductTitle = null, partner: string = null): Observable<unknown> {
        return this.isDeactivated$.pipe(
            switchMap((isDeactivated) => {
                if (isDeactivated) return EMPTY;

                if (partner && partner === 'none') {
                    this.router.navigate(['/no-access']);
                    return EMPTY;
                }

                return this.isGranted(permission, product, partner).pipe(
                    filter((isGranted) => !isGranted),
                    tap(() => this.router.navigate(['/no-access'])),
                );
            }),
        );
    }

    private checkPartner(role: SecurityInfo, partner: string): boolean {
        if (!role.partner || role.partner === 'admin') return true;
        if (this.product == ProductCategory.MASTERCARD || this.product == ProductCategory.TRAVEL_INSURANCE_DREI)
            return true;
        return this.checkWithConversion(role.partner, partner);
    }

    private checkWithConversion(partner1: string, partner2: string): boolean {
        const isPartner1NotAnId = Object.keys(this.config.partnerIds)
            .map((s) => s.replace('_', ''))
            .includes(partner1);
        const isPartner2AlreadyAnId = Object.values(this.config.partnerIds)
            .map((p) => p.toLocaleLowerCase())
            .includes(partner2.toLocaleLowerCase());

        if (isPartner1NotAnId && isPartner2AlreadyAnId) {
            const partner1Id = `${partner1.slice(0, 2)}_${partner1.slice(2)}`;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.config.partnerIds as any)[partner1Id].toLocaleLowerCase() === partner2.toLocaleLowerCase()
            );
        }

        return partner1 === partner2;
    }

    private checkProduct(role: SecurityInfo, product: ProductTitle): boolean {
        if (!role.product) return true;
        if (this.product == ProductCategory.MASTERCARD || this.product == ProductCategory.TRAVEL_INSURANCE_DREI)
            return true;

        return role.product === product;
    }

    private isNullOrUndefined(t: unknown): boolean {
        return t === undefined || t === null;
    }

    processRoleInfos(): void {
        const roleInfos: SecurityInfo[] = [];
        const partnerIds: string[] = [];
        this.state.allRoles.forEach((role) => {
            const groupInfo = this.getValidGroups(role);
            if (groupInfo) {
                this.addNewPartnerId(partnerIds, this.partnerIdKeyPipe.transformBack(groupInfo.partner));
                if (
                    !roleInfos.some(
                        (role) =>
                            role.partner.toLowerCase() === groupInfo.partner.toLowerCase() &&
                            role.product.toLowerCase() === groupInfo.product.toLowerCase(),
                    )
                ) {
                    const roleInfo: SecurityInfo = {
                        product: groupInfo.product as ProductTitle,
                        partner: groupInfo.partner,
                        permissions: this.getPermissions(groupInfo.role),
                    };
                    roleInfos.push(roleInfo);
                } else {
                    const roleInfo = roleInfos.find(
                        (role) =>
                            role.partner.toLowerCase() === groupInfo.partner.toLowerCase() &&
                            role.product.toLowerCase() === groupInfo.product.toLowerCase(),
                    );
                    if (roleInfo != null) {
                        this.getPermissions(groupInfo.role).forEach((permission) => {
                            roleInfo.permissions.push(permission);
                        });
                    }
                }
            } else {
                if (role.toLocaleLowerCase() === 'luis_admin') {
                    const roleInfo: SecurityInfo = {
                        product: null,
                        partner: 'admin',
                        permissions: this.getPermissions('admin'),
                    };
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_aut);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_hrv);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_srb);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_bgr);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_svn);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.h3a_aut);
                    const isExist = roleInfos.find(
                        (role) =>
                            role.partner.toLowerCase() === roleInfo.partner &&
                            role.product.toLowerCase() === roleInfo.product,
                    );
                    if (!isExist) {
                        roleInfos.push(roleInfo);
                    }
                }
            }
        });
        this.authService.setAllowedPartners(partnerIds);
        this.setState({ roles: roleInfos });
    }

    private addNewPartnerId(partnerIds: string[], partnerId: string): string[] {
        if (
            !partnerIds
                .map((q) => {
                    return q.toLowerCase();
                })
                .includes(partnerId.toLowerCase())
        )
            partnerIds.push(partnerId);

        return partnerIds;
    }

    getValidGroups(role: string): RegExpInfo {
        let info: RegExpInfo;
        const patternExp = /^(.\w+)[_](.\w+)[_](.\w+)$/;
        if (patternExp.test(role)) {
            const match = patternExp.exec(role);
            info = {
                product: match[1],
                partner: match[2],
                role: match[3],
            };
        }
        return info;
    }

    getPermissions(role: string): Permission[] {
        if (role.toLowerCase() === 'agent') {
            return this.config.keycloak.roles.agent.permissions;
        }
        if (role.toLowerCase() === 'assistance') {
            return this.config.keycloak.roles.assistance.permissions;
        }
        if (role.toLowerCase() === 'officeemployee') {
            return this.config.keycloak.roles.officeemployee.permissions;
        }
        if (role.toLowerCase() === 'subteamlead') {
            return this.config.keycloak.roles.subteamlead.permissions;
        }
        if (role.toLowerCase() === 'teamlead') {
            return this.config.keycloak.roles.teamlead.permissions;
        }
        if (role.toLowerCase() === 'projectmanager') {
            return this.config.keycloak.roles.projectmanager.permissions;
        }
        if (role.toLowerCase() === 'admin') {
            return this.config.keycloak.roles.admin.permissions;
        }
        return [];
    }
}
