<p-table
    #table
    [loading]="isLoading"
    [value]="transactions"
    scrollHeight="flex"
    scrollDirection="both"
    [resizableColumns]="true"
    [paginator]="false"
    responsiveLayout="scroll"
    [scrollable]="true"
    sortField="start"
    [sortOrder]="-1"
    [totalRecords]="totalRecords"
    styleClass="p-datatable-sm"
    [showCurrentPageReport]="false"
    paginatorPosition="bottom">
    <ng-template pTemplate="header">
        <tr id="table-head">
            <th id="blank"></th>
            <th id="transactionType">
                {{ 'transactionType' | translate }}
            </th>
            <th id="billingText">{{ 'billingText' | translate }}</th>
            <th id="start" pSortableColumn="start">
                {{ 'start' | translate }} <p-sortIcon field="start"></p-sortIcon>
            </th>
            <th id="end" pSortableColumn="end">{{ 'end' | translate }} <p-sortIcon field="end"></p-sortIcon></th>
            <th id="createdOn" pSortableColumn="createdOn">
                {{ 'createdOn' | translate }}
                <p-sortIcon field="createdOn"></p-sortIcon>
            </th>
            <th id="amount">{{ 'amount' | translate }}</th>
            <th id="currency">{{ 'currency' | translate }}</th>
            <th id="paymentState">
                {{ 'paymentState' | translate }}
            </th>
            <th id="reversalStatus">{{ 'reversalStatus' | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction let-i="rowIndex">
        <tr
            class="mb-2 cursor-pointer p-3 border-round text-700 hover:surface-200 transition-duration-150 transition-colors">
            <td class="font-medium">{{ transaction.sortIndex }}.</td>

            <td>
                <shared-table-cell [value]="transaction.transactionType">
                    {{ transaction.transactionType | transactionType | translate }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.billingText">
                    {{ transaction.billingText }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.start">
                    {{ transaction.start | date: 'dd.MM.yyyy' }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.end">
                    {{ transaction.end | date: 'dd.MM.yyyy' }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.createdOn">
                    {{ transaction.createdOn | date: 'dd.MM.yyyy HH:mm' }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.amount">
                    {{ transaction.amount | number }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.currency">
                    {{ transaction.currency }}
                </shared-table-cell>
            </td>

            <td>
                <shared-table-cell [value]="transaction.paymentState">
                    <p-chip
                        [styleClass]="transaction.paymentState | paymentStateStyle"
                        [pTooltip]="transaction.errorMessage"
                        [tooltipDisabled]="false"
                        tooltipPosition="top">
                        {{ transaction.paymentState | paymentState | translate }}
                    </p-chip>
                </shared-table-cell>
            </td>
            <td>
                <shared-table-cell [value]="transaction.reversalStatus">
                    {{ transaction.reversalStatus | reversalStatus | translate }}
                </shared-table-cell>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr class="text-700">
            <td colspan="20">
                <i class="pi pi-info-circle text-xl mr-2"></i>
                {{ 'noResultsFound' | translate }}
            </td>
        </tr>
    </ng-template>
</p-table>
