<div class="mb-2 flex flex-row justify-content-between align-items-center">
    <span class="text-xl font-medium text-900">{{ 'marketing' | translate }}</span>

    <shared-edit-button
        (event)="onEditEvent($event)"
        [(isEditing)]="isEditing"
        *hasPermission="'update_contract'; partner: partnerId | partnerIdKey"></shared-edit-button>
</div>

<div
    class="p-2 text-center flex flex-column justify justify-content-center align-items-center"
    (click)="updateMarketingFlag()">
    <div
        pRipple
        [class]="
            'font-bold mb-1 text-white bg-' + (isMarketingAllowedEditCopy ? 'green' : 'red') + '-600 p-3 border-round'
        ">
        <i
            class="pi pi-flag"
            style="font-size: 2.5rem"
            [pTooltip]="'click' | tooltip | translate"
            [tooltipDisabled]="!isEditing"></i>
    </div>
</div>
