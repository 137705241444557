import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'shared-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss'],
})
export class InputCurrencyComponent implements OnInit {
    private readonly themeService = inject(ThemeService);
    private readonly destroyRef = inject(DestroyRef);

    @Input() label: string;
    @Input() value = 0;
    @Input() decimals = 2;
    @Input() disabled: boolean;
    @Input() currencyCode = 'EUR';
    @Input() required = false;
    @Input() isValid = true;
    @Input() errorMessage = 'Invalid input';
    @Output() valueChange = new EventEmitter<number>();

    isDark = false;

    public ngOnInit(): void {
        if (!this.value) this.value = 0;

        this.themeService.isDark$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isDark) => {
            this.isDark = isDark;
        });
    }

    protected emitValue() {
        this.valueChange.emit(this.value);
    }
}
