import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'in',
})
export class InPipe implements PipeTransform {
    transform(value: string, args: string[]): boolean {
        return args.includes(value);
    }
}
