import { NgClass } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FileIconComponent, HumanizeFilesizePipe, IsDroppingDirective } from '@nuis/common';
import { ButtonModule } from 'primeng/button';
import { FormErrorComponent, showErrors } from './form-error.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-upload',
    standalone: true,
    imports: [
        NgClass,
        ReactiveFormsModule,
        TranslateModule,
        ButtonModule,
        IsDroppingDirective,
        FileIconComponent,
        HumanizeFilesizePipe,
        FormLabelComponent,
        FormErrorComponent,
    ],
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            @if (control().value; as value) {
                <div class="flex gap-3 align-items-center surface-0 p-3 border-1 border-300 border-round">
                    <nuis-file-icon [name]="value.name" size="large" />

                    <div class="flex-1 flex flex-column gap-1">
                        <div class="font-bold">{{ value.name }}</div>
                        <div class="text-sm text-400">{{ value.size | humanizeFilesize }}</div>
                    </div>

                    <div>
                        <p-button [text]="true" icon="pi pi-times" severity="secondary" (onClick)="clear()" />
                    </div>
                </div>
            } @else {
                <div
                    class="relative h-10rem flex flex-column gap-3 justify-content-center align-items-center surface-0 p-3 border-1 border-300 border-round border-dashed hover:border-primary hover:bg-primary-50"
                    [ngClass]="{
                        'bg-primary-50 border-primary': isDropping(),
                        'border-red-500': showErrors(control()),
                    }">
                    <i class="pi pi-cloud-upload text-5xl text-primary"></i>

                    <label for="upload" class="text-sm">{{ 'fileUpload.info' | translate }}</label>

                    <input
                        class="absolute h-full w-full opacity-0 cursor-pointer"
                        type="file"
                        id="upload"
                        onclick="value = null"
                        (change)="filesSelected($event)"
                        nuisDropping
                        [(isDropping)]="isDropping" />
                </div>
            }

            @if (showErrors(control())) {
                <nuis-form-error [label]="label()" [control]="control()" />
            }
        </div>
    `,
})
export class InputUploadComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<File | null>>();

    protected isDropping = signal<boolean>(false);
    protected showErrors = showErrors;

    protected filesSelected(event: Event) {
        const files = Array.from((event.target as HTMLInputElement).files ?? []);
        const file = files[0] ?? null;
        this.valueChange(file);
    }

    protected clear() {
        this.valueChange(null);
    }

    private valueChange(value: File | null) {
        this.control().setValue(value);
        this.control().markAsTouched();
        this.control().markAsDirty();
    }
}
