import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EditButtonEvent } from '../../models/edit-button-event.model';

@Component({
    selector: 'shared-contract-marketing-flag',
    templateUrl: './marketing-flag.component.html',
})
export class MarketingFlagComponent implements OnInit {
    @Input() partnerId: string;
    @Input() isMarketingAllowed: boolean;
    @Output() isMarketingAllowedChanged = new EventEmitter<boolean>();
    isMarketingAllowedEditCopy: boolean;
    isEditing = false;

    ngOnInit(): void {
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
    }

    onEditEvent(event: EditButtonEvent) {
        switch (event) {
            case 'edit':
                this.#edit();
                return;
            case 'saveEdit':
                this.#saveEdit();
                return;
            case 'cancelEdit':
                this.#cancelEdit();
                return;
            default:
                throw new Error();
        }
    }

    #edit() {
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
    }

    #saveEdit() {
        this.isMarketingAllowed = this.isMarketingAllowedEditCopy;
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
        this.isMarketingAllowedChanged.emit(this.isMarketingAllowed);
    }

    #cancelEdit() {
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
    }

    updateMarketingFlag() {
        if (this.isEditing) this.isMarketingAllowedEditCopy = !this.isMarketingAllowedEditCopy;
    }
}
