/* eslint-disable @typescript-eslint/no-unused-vars */
import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Subject, takeUntil } from 'rxjs';
import { Permission } from '../../models/roles.model';
import { ProductTitle } from '../models/product-titles';
import { RolesService } from '../services/roles.service';

@Directive({
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit, OnChanges {
    @Input() hasPermission: Permission;
    @Input() hasPermissionPartner: string;
    @Input() hasPermissionProduct: ProductTitle;
    #destroy = new Subject<void>();
    #updateView = new BehaviorSubject<void>(null);

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private rolesService: RolesService,
    ) {}

    ngOnInit(): void {
        combineLatest({
            roles: this.rolesService.isGranted(
                this.hasPermission,
                this.hasPermissionProduct,
                this.hasPermissionPartner,
            ),
            update: this.#updateView.asObservable(),
        })
            .pipe(
                map(({ roles }) => roles),
                takeUntil(this.#destroy),
            )
            .subscribe((isGranted) => {
                if (this.viewContainer) this.viewContainer.clear();
                if (isGranted) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            });
    }

    ngOnChanges(): void {
        this.#updateView.next();
    }
}
