import { DatePipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonStateService, ProductCategory, ThemeService } from '@luis/common/shared';
import { LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { ReferenceDialogTableModel } from '../../models/reference-dialog-table';
import {
    ReferenceOpenReversalsTableRecord,
    SELECTIONSTATE,
    TransactionRelated,
} from '../../models/reference-open-reversal';
import { TravelRelatedData } from '../../pipes/transaction-reference-data.pipe';
import { ReferenceTransactionReversalApprovementButtonService } from '../../services/transaction-reversal-approvement-button.service';
import { TransactionReversalApprovementTableService } from '../../services/transaction-reversal-approvement-table.service';
import { TransactionReversalApprovementService } from '../../services/transaction-reversal-approvement.service';

@Component({
    selector: 'core-transaction-reversal-approvement-table',
    templateUrl: './transaction-reversal-approvement-table.component.html',
    styleUrls: ['./transaction-reversal-approvement-table.component.scss'],
    providers: [DatePipe],
})
export class TransactionReversalApprovementTableComponent {
    private readonly transactionReversalApprovementTableService = inject(TransactionReversalApprovementTableService);
    private readonly transactionReversalApprovementButtonService = inject(
        ReferenceTransactionReversalApprovementButtonService,
    );
    private readonly transactionReversalApprovementService = inject(TransactionReversalApprovementService);
    private readonly themeService = inject(ThemeService);
    private readonly commonStateService = inject(CommonStateService);
    private readonly datePipe = inject(DatePipe);
    private readonly destroyRef = inject(DestroyRef);

    referenceTransactions: ReferenceOpenReversalsTableRecord[];
    isLoading = true;
    totalRecords: number;
    event: LazyLoadEvent;
    selected: SELECTIONSTATE = 'PENDING';
    transactionButtonStateChange = new Subject<{
        buttonState: SELECTIONSTATE;
        rowContractId: string;
    }>();

    forceUpdatePending = new Subject<string>();
    forceUpdateApprove = new Subject<string>();
    forceUpdateDeny = new Subject<string>();
    showDialogLoading = false;

    showApproveDialog = false;
    hasUpdateableState = false;

    isDark: boolean;

    productCategory: ProductCategory = ProductCategory.TRAVEL_INSURANCE;

    dialogContractReversalButtonStates: ReferenceDialogTableModel[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lazyLoadRecords(event: any) {
        this.event = event;
        this.isLoading = true;

        this.themeService.isDark$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isDark) => {
            this.isDark = isDark;
        });

        this.transactionReversalApprovementTableService
            .loadRecords()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((reference) => {
                this.referenceTransactions = reference.records;
                this.isLoading = false;
                this.totalRecords = reference.totalRecords;

                this.transactionReversalApprovementButtonService.init(this.referenceTransactions);
            });

        this.commonStateService.productCategory$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((productCategory) => {
                this.productCategory = productCategory;
            });

        this.transactionReversalApprovementButtonService.hasUpdateableState$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((hasUpdateableState) => (this.hasUpdateableState = hasUpdateableState.hasUpdated));
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    triggerSetTransactionButtons(event: any) {
        this.selected = event.buttonState;
        if (event.buttonState === 'PENDING') {
            this.forceUpdatePending.next(event.rowContractId);
        } else if (event.buttonState === 'APPROVE') {
            this.forceUpdateApprove.next(event.rowContractId);
        } else if (event.buttonState === 'DENY') {
            this.forceUpdateDeny.next(event.rowContractId);
        }
    }

    //update referenceButtonGroupColor by clicking on transaction button
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setReferenceButtons(event: any) {
        this.transactionButtonStateChange.next(event);
    }

    showDialog() {
        this.transactionReversalApprovementButtonService.getContractButtonStates().forEach((contract) => {
            const contractReversalIndex = this.referenceTransactions.findIndex(
                (contractReversals) => contractReversals?.contractId === contract?.contractId,
            );
            const contractReversal = this.referenceTransactions[contractReversalIndex];
            contractReversal.relatedReversalTransactions.forEach((relatedReversals) => {
                relatedReversals.transactions.forEach((transaction) => {
                    const currentTransactionToCheckIndex = contract.transactionButtonStates.findIndex(
                        (q) => q.transactionId == transaction.id,
                    );
                    const currentTransactionToCheck = contract.transactionButtonStates[currentTransactionToCheckIndex];

                    if (
                        currentTransactionToCheck.transactionButtonState == 'APPROVE' ||
                        currentTransactionToCheck.transactionButtonState == 'DENY'
                    ) {
                        this.dialogContractReversalButtonStates.push({
                            contractId: contractReversal?.contractId,
                            policyNumber: contractReversal.policyNumber,
                            periodStartedOn: this.datePipe.transform(transaction.start, 'dd.MM.yyyy'),
                            periodEndsOn: this.datePipe.transform(transaction.end, 'dd.MM.yyyy'),
                            transactionId: transaction.id,
                            transactionType: transaction.transactionType,
                            transactionAdditionalInfo: transaction.addition,
                            approvedTransactionCount: contract.transactionButtonStates.filter(
                                (q) => q.transactionButtonState == 'APPROVE',
                            ).length,
                            deniedTransactionCount: contract.transactionButtonStates.filter(
                                (q) => q.transactionButtonState == 'DENY',
                            ).length,
                            allTransactionsCount: contract.transactionButtonStates.length,
                            buttonState: currentTransactionToCheck.transactionButtonState,
                        });
                    }
                });
            });
        });

        this.showApproveDialog = true;
    }

    closeDialog() {
        this.showApproveDialog = false;

        this.dialogContractReversalButtonStates = [];
    }

    openRelated(category: ProductCategory, referenceTransaction: TransactionRelated) {
        const refIdToOpen = this.extractRefRelatedId(category, referenceTransaction.relatedObject);
        window.open(this.getBaseUrl() + 'travel-insurance/travels/' + refIdToOpen + '/overview', '_blank');
    }

    extractRefRelatedId(category: ProductCategory, refRelatedData: TravelRelatedData) {
        switch (category) {
            case 'TravelInsurance': {
                return refRelatedData.id;
            }
            default:
                return '';
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openContract(event: any, contractId: string) {
        if (event[2] == true) {
            const url = window.location.href + '/' + contractId;
            window.open(url, '_blank');
        } else {
            window.open(this.getBaseUrl() + 'travel-insurance/contracts/' + contractId + '/overview', '_blank');
        }
    }

    getBaseUrl() {
        const url = window.location.href;
        return url.split('/')[0];
    }

    getCountByState(referenceId: string, state: SELECTIONSTATE) {
        return this.transactionReversalApprovementButtonService.getCountByState(referenceId, state);
    }

    sendReversals() {
        this.showDialogLoading = true;

        const requestBody = this.dialogContractReversalButtonStates
            .filter((q) => q.buttonState === 'APPROVE')
            .map((selectedTransaction) => {
                return {
                    id: selectedTransaction.transactionId,
                    type: selectedTransaction.transactionType,
                    addition: selectedTransaction.transactionAdditionalInfo,
                };
            });

        this.transactionReversalApprovementService.sendApprovals(this.productCategory, requestBody).subscribe();
        this.transactionReversalApprovementService.sendDenied().subscribe();

        //reload after sending reversals
        setTimeout(() => {
            this.transactionReversalApprovementTableService.loadRecords().subscribe((reference) => {
                this.referenceTransactions = reference.records;
                this.isLoading = false;
                this.totalRecords = reference.totalRecords;
                this.showDialogLoading = false;

                this.transactionReversalApprovementButtonService.init(this.referenceTransactions);

                this.closeDialog();
            });
        }, 2000);
    }
}
