export const megabyte = (size: number): number => size * 1024 * 1024;

export function chunkFile(file: File, chunkSizeInByte: number): Blob[] {
    const totalChunks = Math.ceil(file.size / chunkSizeInByte);

    const chunks: Blob[] = [];
    for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSizeInByte;
        const end = Math.min(start + chunkSizeInByte, file.size);
        chunks.push(file.slice(start, end));
    }

    return chunks;
}
