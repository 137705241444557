import { Component, effect, input } from '@angular/core';
import { ContractCancellationData } from '../../models/contract-cancellation-details';

@Component({
    selector: 'shared-contract-cancellation-details',
    templateUrl: './cancellation-details.component.html',
})
export class CancellationDetailsComponent {
    cancellationData = input.required<ContractCancellationData>();

    cancellationReceivedOn: Date;
    terminationOn: Date;
    cancellationProcessedOn: Date;
    constructor() {
        effect(() => {
            if (this.cancellationData() != null) {
                this.cancellationReceivedOn = new Date(this.cancellationData().cancellationReceivedOn);
                this.cancellationProcessedOn = new Date(this.cancellationData().cancellationProcessedOn);
                this.terminationOn = new Date(this.cancellationData().terminationOn);
            }
        });
    }
}
