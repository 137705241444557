import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@luis/common/shared';
import { StoreModule } from '@ngrx/store';
import * as fromTable from './+state/table/table.reducer';
import { FieldComponent } from './components/field/field.component';
import { LazyTableComponent } from './components/lazy-table/lazy-table.component';
import { ViewEditorComponent } from './components/view-editor/view-editor.component';
import { NoHeaderFilterDirective } from './directives/no-header-filter.directive';

@NgModule({
    declarations: [LazyTableComponent, FieldComponent, ViewEditorComponent, NoHeaderFilterDirective],
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        SharedModule,
        StoreModule.forFeature(fromTable.TABLE_FEATURE_KEY, fromTable.reducer),
    ],
    exports: [LazyTableComponent, FieldComponent, NoHeaderFilterDirective],
})
export class TableModule {}
