/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, LazyLoadResponse, Reference } from '@luis/common/shared';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, Subject, map, tap } from 'rxjs';
import { Transaction } from '../models/transaction.model';
import { TransactionListItem } from './table-store.service';

@Injectable({ providedIn: 'root' })
export class TransactionTableService {
    private baseUrl: string;

    private isLoading = new Subject<boolean>();
    isLoading$ = this.isLoading.asObservable();

    private reference = new Subject<Reference>();
    reference$ = this.reference.asObservable();

    constructor(
        private http: HttpClientAdapter,
        private config: Configuration,
    ) {
        this.baseUrl = `${this.config.baseUrl}Transactions`;
    }

    loadRecords(
        _: LazyLoadEvent,
        __: string[] = [],
        ___: string[] = [],
        ref: Reference,
    ): Observable<LazyLoadResponse<TransactionListItem>> {
        this.setLoading(true);
        return this.http
            .get<Transaction[]>(`${this.baseUrl}/GetAllByRef`, {
                params: {
                    ...ref,
                },
            })
            .pipe(
                tap(() => {
                    this.setLoading(false);
                }),
                map((transactions) => {
                    return {
                        records: transactions?.map((transaction) => {
                            return {
                                ...transaction,
                                isChecked: false,
                            };
                        }),
                        totalRecords: transactions.length,
                    };
                }),
            );
    }

    loadAllByContractId(contractId: string) {
        this.setLoading(true);
        return this.http.get<Transaction[]>(`${this.baseUrl}/ListByContractId?contractId=` + contractId).pipe(
            tap(() => {
                this.setLoading(false);
            }),
            map((transactions) => {
                return {
                    records: transactions?.map((transaction) => {
                        return {
                            ...transaction,
                            isChecked: false,
                        };
                    }),
                    totalRecords: transactions.length,
                };
            }),
        );
    }

    setLoading(loading: boolean) {
        this.isLoading.next(loading);
    }

    setReference(ref: Reference) {
        this.reference.next(ref);
    }
}
