import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { FormErrorComponent, showErrors } from './form-error.component';
import { FormLabelComponent } from './form-label.component';

@Component({
    selector: 'nuis-input-search',
    standalone: true,
    imports: [NgClass, ReactiveFormsModule, InputTextModule, FormLabelComponent, FormErrorComponent],
    styles: `
        // NOTE: Reset primeng invalid style because we want errors to show after touched
        .p-inputtext.ng-dirty.ng-invalid {
            border-color: #3b82f6;
        }

        .p-inputtext.invalid {
            border-color: #ff3d32 !important; // red-500
            box-shadow: 0 0 0 0.2rem #ff3d3240 !important;
        }
    `,
    template: `
        <div class="flex flex-column gap-2">
            @if (label(); as label) {
                <nuis-form-label [label]="label" [control]="control()" />
            }

            <div class="relative flex align-items-center">
                <i class="absolute left-0 px-3 text-500 pi pi-search"></i>

                <input
                    class="w-full px-6"
                    [ngClass]="{ invalid: showErrors(control()) }"
                    pInputText
                    type="text"
                    [formControl]="control()" />

                @if (control().value !== null && control().value !== '') {
                    <i class="absolute right-0 px-3 text-500 pi pi-times cursor-pointer" (click)="clear()"></i>
                }
            </div>
        </div>
    `,
})
export class InputSearchComponent {
    public label = input<string | null>(null);
    public control = input.required<FormControl<string | null>>();

    protected showErrors = showErrors;

    protected clear() {
        this.control().setValue(null);
    }
}
