<div class="grid text-900 m-0 p-0">
    <div class="col-12 mb-2">
        <ul class="list-none p-0 m-0">
            <li class="flex flex-row justify-content-between align-items-center">
                <span class="p-fluid">
                    <label for="viewName" class="font-medium">{{ 'viewEditor.label' | translate }}</label>
                    <input id="viewName" type="text" class="text-xl font-medium" pInputText [(ngModel)]="view.label" />
                </span>
                <span>
                    <button
                        pRipple
                        pButton
                        type="button"
                        icon="pi pi-save"
                        class="p-button-text text-xl"
                        pTooltip="{{ 'save' | tooltip | translate }}"
                        tooltipPosition="left"
                        (click)="save()"></button>
                </span>
            </li>
        </ul>
    </div>

    <div class="col-6">
        <div class="text-center text-2xl font-medium mb-2">
            {{ 'viewEditor.hiddenFields' | translate }}
        </div>
        <ul class="list-none p-0 m-0">
            <li class="p-4 shadow-2 border-round surface-card">
                <div>
                    <div
                        class="container"
                        cdkDropList
                        #hiddenList="cdkDropList"
                        [cdkDropListData]="availableColumns"
                        [cdkDropListConnectedTo]="[visibleList]"
                        (cdkDropListDropped)="drop($event)">
                        <div
                            *ngFor="let field of availableColumns; let i = index"
                            [ngSwitch]="field"
                            class="drag-element"
                            cdkDrag>
                            <table-view-editor-field [field]="field" [index]="i + 1" [isSortable]="false">
                            </table-view-editor-field>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-6">
        <div class="text-center text-2xl font-medium mb-2">
            {{ 'viewEditor.visibleFields' | translate }}
        </div>
        <ul class="list-none p-0 m-0">
            <li class="p-4 shadow-2 border-round surface-card">
                <div>
                    <div
                        class="container"
                        cdkDropList
                        #visibleList="cdkDropList"
                        [cdkDropListData]="view.fields"
                        [cdkDropListConnectedTo]="[hiddenList]"
                        (cdkDropListDropped)="drop($event)">
                        <div
                            *ngFor="let field of view.fields; let i = index"
                            [ngSwitch]="field"
                            class="drag-element"
                            cdkDrag>
                            <table-view-editor-field
                                [field]="field"
                                [index]="i + 1"
                                [isSortable]="field | in: allColumnFields"
                                (sortingChange)="sortingChanged($event)"
                                [resetSorting]="resetSorting.asObservable() | async">
                                <ng-template #fieldFilterTemplate let-table>
                                    <ng-container
                                        [ngTemplateOutlet]="filterTemplate"
                                        [ngTemplateOutletContext]="{ $implicit: field }">
                                    </ng-container>
                                </ng-template>
                            </table-view-editor-field>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
