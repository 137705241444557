import { MenuItem } from 'primeng/api';

export const TRAVEL_TABS: MenuItem[] = [
    { label: 'tab.overview', routerLink: 'overview' },
    { label: 'tab.documents', routerLink: 'documents' },
    { label: 'tab.notifications', routerLink: 'notifications' },
    { label: 'tab.transactions', routerLink: 'transactions' },
    { label: 'tab.additional', routerLink: 'additional' },
    { label: 'tab.audits', routerLink: 'audits' },
];
