import { inject, Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationDialogService {
    private readonly confirmationService = inject(ConfirmationService);

    public confirm(options: { header: string; message: string }): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.confirmationService.confirm({
                header: options.header,
                message: options.message,
                accept: () => resolve(true),
                reject: () => resolve(false),
            });
        });
    }
}
