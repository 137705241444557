import { Pipe, PipeTransform } from '@angular/core';
import { toLower } from 'lodash';

@Pipe({
    name: 'gender',
})
export class GenderPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;
        return `genders.${toLower(value)}`;
    }
}
